import type { FC, JSX, ReactNode } from "react";

import { Divider, Typography } from "antd";
import PapayaIf from "app/common/components/PapayaIf";
import Spin from "app/common/components/Spin";

import "./style.css";

type JsxChild = boolean | null | number | ReactNode | string | undefined;
type JsxChildren = JsxChild | JsxChild[];

const Content: FC<{ children: JsxChildren; className?: string; loading?: boolean; style?: React.CSSProperties; title?: JSX.Element | null | string; titleInDivider?: boolean }> = ({
  children,
  className,
  loading = false,
  style,
  title,
  titleInDivider = false,
}) => (
  <Spin className={className} spinning={loading}>
    <div className="contentContainer" style={style}>
      <PapayaIf condition={titleInDivider === true}>
        <Divider orientation="left" orientationMargin="0">
          <Typography.Title level={5}>{title}</Typography.Title>
        </Divider>
      </PapayaIf>
      <PapayaIf condition={title != null && titleInDivider === false}>
        <PapayaIf condition={typeof title === "string"}>
          <Typography.Title level={4} style={{ marginBottom: -15, marginTop: 0 }}>
            {title}
          </Typography.Title>
        </PapayaIf>
        <PapayaIf condition={typeof title !== "string"}>{title}</PapayaIf>
        <Divider />
      </PapayaIf>
      {children}
    </div>
  </Spin>
);

export default Content;
