import { PORTAL_PATH } from "app/portal/config/paths";
import { INSURER_COMPANY_IDS } from "config/constants";

export enum INSURER_CLAIM_TYPE {
  FWD = "FWD",
  GENERAL = "GENERAL",
  MBAL = "MBAL",
  PTI = "PTI",
  SLV_HS = "SLV_HS",
}

export const CLAIM_LIST_PATH = {
  [INSURER_CLAIM_TYPE.FWD]: PORTAL_PATH.FWD_MR_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.GENERAL]: PORTAL_PATH.HC_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.MBAL]: PORTAL_PATH.MBAL_CLAIM_LIST,
  [INSURER_CLAIM_TYPE.SLV_HS]: PORTAL_PATH.SLV_HS_CLAIM_LIST,
} as const;

export const CLAIM_DETAIL_PATH = {
  [INSURER_CLAIM_TYPE.FWD]: PORTAL_PATH.FWD_MR_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.GENERAL]: PORTAL_PATH.HC_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.MBAL]: PORTAL_PATH.MBAL_HS_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.PTI]: PORTAL_PATH.HC_CLAIM_CASE,
  [INSURER_CLAIM_TYPE.SLV_HS]: PORTAL_PATH.SLV_HS_CLAIM_CASE,
} as const;

export const getInsurerIdByClaimType = (claimType?: INSURER_CLAIM_TYPE) => {
  switch (claimType) {
    case INSURER_CLAIM_TYPE.FWD: {
      return INSURER_COMPANY_IDS.FWD;
    }
    case INSURER_CLAIM_TYPE.MBAL: {
      return INSURER_COMPANY_IDS.MBAges;
    }
    case INSURER_CLAIM_TYPE.PTI: {
      return INSURER_COMPANY_IDS.PTI;
    }
    case INSURER_CLAIM_TYPE.SLV_HS: {
      return INSURER_COMPANY_IDS.SLV;
    }
    default: {
      return null;
    }
  }
};

export const getInsurerClaimType = (
  claim?: {
    insured_certificate?: {
      policy: {
        insurer_company: {
          company_id: string;
        } | null;
      };
    };
  } | null,
) => {
  switch (claim?.insured_certificate?.policy.insurer_company?.company_id) {
    case INSURER_COMPANY_IDS.PTI: {
      return INSURER_CLAIM_TYPE.PTI;
    }
    case INSURER_COMPANY_IDS.FWD: {
      return INSURER_CLAIM_TYPE.FWD;
    }
    case INSURER_COMPANY_IDS.MBAges: {
      return INSURER_CLAIM_TYPE.MBAL;
    }
    case INSURER_COMPANY_IDS.SLV: {
      return INSURER_CLAIM_TYPE.SLV_HS;
    }
    default: {
      return INSURER_CLAIM_TYPE.GENERAL;
    }
  }
};

export const getClaimType = (
  claim?: {
    insured_certificate: {
      policy: {
        insurer_company: {
          company_id: string;
        } | null;
      };
    };
  } | null,
) =>
  claim == null
    ? {
        fwdMr: false,
        healthCare: false,
        mbalHs: false,
        PTI: false,
        slvHs: false,
      }
    : {
        fwdMr: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.FWD,
        healthCare: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.GENERAL,
        mbalHs: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.MBAL,
        PTI: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.PTI,
        slvHs: getInsurerClaimType(claim) === INSURER_CLAIM_TYPE.SLV_HS,
      };

const placeHolderTextsMapping = [
  {
    key_name: "effective_date",
    text: "<Ngày hiệu lực HĐ rider>",
  },
  {
    key_name: "event_date",
    text: "<Event date>",
  },
  {
    key_name: "diagnosis",
    text: "<Chẩn đoán bệnh>",
  },
  {
    key_name: "plan_name",
    text: "<Gói sản phẩm>",
  },
  {
    key_name: "out_patient_per_case_limit",
    text: "<Quyền lợi tối đa mỗi lần khám ngoại trú>",
  },
  {
    key_name: "requested_amount",
    text: "<Số tiền yêu cầu>",
  },
  {
    key_name: "actual_paid_amount",
    text: "<Tổng tiền thực trả>",
  },
  {
    key_name: "coverage_amount",
    text: "<Số tiền trong PVBH>",
  },
  {
    key_name: "co_payment_amount",
    text: "<Số tiền copay>",
  },
  {
    key_name: "dental_per_case_limit",
    text: "<Quyền lợi nha khoa>",
  },
  {
    key_name: "per_case_limit",
    text: "<Quyền lợi tối đa mỗi Đợt điều trị>",
  },
  {
    key_name: "annual_limit",
    text: "<Phạm vi bảo hiểm tối đa mỗi năm hợp đồng>",
  },
  {
    key_name: "out_patient_annual_limit",
    text: "<Quyền lợi ngoại trú tối đa mỗi năm hợp đồng>",
  },
  {
    key_name: "medical_provider_name",
    text: "<CSYT>",
  },
  {
    key_name: "",
    text: "<tên chứng từ>",
  },
  {
    key_name: "",
    text: "",
  },
  {
    key_name: "code",
    text: "<Mã YCBT>",
  },
  {
    key_name: "another_code",
    text: "<Mã YCBT Khác>",
  },
  {
    key_name: "",
    text: "<x-ray>",
  },
  {
    key_name: "",
    text: "<ultrasound>",
  },
  {
    key_name: "",
    text: "<x quang>",
  },
  {
    key_name: "",
    text: "<siêu âm>",
  },
  {
    key_name: "",
    text: "<Tên thông tin bị thiếu>",
  },
  {
    key_name: "",
    text: "<loại thông tin>",
  },
  {
    key_name: "",
    text: "<Giá trị cần điền>",
  },
] as const;

const parsePlaceholderTextsToValue = (text: string, obj: Partial<Record<(typeof placeHolderTextsMapping)[number]["key_name"], null | string | undefined>>) => {
  let newText = text;

  placeHolderTextsMapping.forEach(({ key_name, text: templateText }) => {
    const value = obj[key_name];
    if (value == null) return;
    newText = newText.replaceAll(templateText, value);
  });
  return newText;
};

export { parsePlaceholderTextsToValue, placeHolderTextsMapping };
