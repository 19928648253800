import type { DeepPartial } from "@apollo/client/utilities";
import type { INSURER_CLAIM_TYPE } from "app/portal/screens/ClaimPortal/ClaimContext/utils";

import { Space, Tooltip, Typography } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import { getInsurerIdByClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/utils";
import { papaya } from "config/colors";
import { VerifiedIcon } from "lucide-react";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";
import { If } from "vite-plugin-react-control-statements";

import ExternalLink from "../ExternalLink";
import PapayaIf from "../PapayaIf";

export const MedicalProviderSelectOptionFragment = graphql(`
  fragment MedicalProviderSelectOption on mp_medical_providers {
    id
    name
    address
    is_direct_billing
    verified
    medical_provider_group_medical_providers {
      medical_provider_group_history {
        medical_provider_group_applications(
          where: { medical_provider_group_version: { start_date: { _lte: "now()" }, _or: [{ end_date: { _gte: "now()" } }, { end_date: { _is_null: true } }] } }
        ) {
          medical_provider_group_version {
            end_date
            start_date
            medical_provider_group {
              id
              name
              company {
                name
                company_id
              }
              medical_provider_group_type {
                comment
                value
              }
            }
          }
          policy {
            policy_id
          }
        }
      }
    }
    medical_provider_mappings {
      company_id
    }
    medical_provider_tpas {
      company {
        name
      }
    }
    ward {
      name
      district {
        name
        city {
          name
        }
      }
    }
  }
`);

const MedicalProviderSelectOption = ({
  claimType,
  directBillingOnly,
  displayAddress: displayExtraInfo = true,
  mp: { address, id, is_direct_billing, medical_provider_group_medical_providers, medical_provider_mappings = [], medical_provider_tpas, name, verified, ward },
  policy_id,
}: {
  claimType?: INSURER_CLAIM_TYPE;
  directBillingOnly?: boolean;
  displayAddress?: boolean;
  mp: DeepPartial<FragmentOf<typeof MedicalProviderSelectOptionFragment>>;
  policy_id?: string;
}) => {
  const hasBm = medical_provider_tpas?.some((mpTpa) => mpTpa?.company?.name?.includes("BẢO MINH")) ?? false;
  const hasMic = medical_provider_tpas?.some((mpTpa) => mpTpa?.company?.name?.includes("MIC")) ?? false;
  const insurerIdByClaimType = getInsurerIdByClaimType(claimType);
  const isMapped = insurerIdByClaimType == null ? true : medical_provider_mappings.some((mpTpa) => mpTpa?.company_id === insurerIdByClaimType);

  let mpTpas =
    medical_provider_tpas
      ?.filter((mpTpa) => {
        if (hasBm) {
          return mpTpa?.company?.name?.includes("BẢO MINH") === false;
        }
        return true;
      })
      .filter((mpTpa) => {
        if (hasMic) {
          return mpTpa?.company?.name?.includes("MIC") === false;
        }
        return true;
      }) ?? [];

  if (hasBm) {
    mpTpas = [...mpTpas, { company: { name: "BẢO MINH" } }];
  }

  if (hasMic) {
    mpTpas = [...mpTpas, { company: { name: "MIC" } }];
  }

  const navigate = useNavigate();

  const dbTitle = (() => {
    if (directBillingOnly === true && is_direct_billing === false) {
      return "Không hỗ trợ BLVP";
    }

    if (
      is_direct_billing === true &&
      medical_provider_group_medical_providers?.some((i) =>
        i?.medical_provider_group_history?.medical_provider_group_applications?.some(
          (j) => j?.medical_provider_group_version?.medical_provider_group?.medical_provider_group_type?.value === "DB_REJECTION" && j.policy?.policy_id === policy_id,
        ),
      ) === true
    ) {
      const listName = medical_provider_group_medical_providers
        .find((i) =>
          i?.medical_provider_group_history?.medical_provider_group_applications?.some(
            (j) => j?.medical_provider_group_version?.medical_provider_group?.medical_provider_group_type?.value === "DB_REJECTION" && j.policy?.policy_id === policy_id,
          ),
        )
        ?.medical_provider_group_history?.medical_provider_group_applications?.find(
          (j) => j?.medical_provider_group_version?.medical_provider_group?.medical_provider_group_type?.value === "DB_REJECTION" && j.policy?.policy_id === policy_id,
        )?.medical_provider_group_version?.medical_provider_group?.name;
      return `Thuộc danh sách loại trừ ${listName}`;
    }
    return "";
  })();

  return (
    <Tooltip title={dbTitle}>
      <Space direction="vertical">
        <Typography.Text
          onClick={(e) => {
            if ((e.metaKey || e.ctrlKey) && id != null) {
              navigate(generatePath(PORTAL_PATH.MEDICAL_PROVIDER_DETAIL, { id }));
            }
          }}
          strong
        >
          <Space>
            {name}
            <If condition={verified === true}>
              <Tooltip title="Đã xác thực">
                <VerifiedIcon color={papaya} size={14} />
              </Tooltip>
            </If>
            {id != null && <ExternalLink to={generatePath(PORTAL_PATH.MEDICAL_PROVIDER_DETAIL, { id })} />}
          </Space>
        </Typography.Text>
        <If condition={displayExtraInfo === true}>
          <Typography.Text italic>
            {address}
            <PapayaIf condition={ward != null}>
              - Phường/xã: {ward?.name} - Quận/huyện: {ward?.district?.name} - Tỉnh/TP: {ward?.district?.city?.name}
            </PapayaIf>
          </Typography.Text>
        </If>
        <If condition={displayExtraInfo === true && is_direct_billing === true}>
          <Tooltip
            title={
              <ul>
                {mpTpas.map((mpTpa) => (
                  <li key={mpTpa?.company?.name}>{mpTpa?.company?.name}</li>
                ))}
              </ul>
            }
          >
            Thuộc danh sách BLVP: {mpTpas.map((mpTpa) => mpTpa?.company?.name).join("/")}
          </Tooltip>
        </If>
      </Space>
    </Tooltip>
  );
};

export default MedicalProviderSelectOption;
