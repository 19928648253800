import { Form, Select, Space } from "antd";
import PapayaIf from "app/common/components/PapayaIf";
import useCompanies from "hooks/useCompanies";
import { debounce, isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";

const InsurerSelection = () => {
  const [_, setSearchParams] = useSearchParams({ insurer: [] });
  const { data: companiesData } = useCompanies({
    limit: 200,
    types: ["Insurer"],
  });
  return (
    <Form.Item label="Công ty bảo hiểm" name="insurer">
      <Select
        allowClear
        mode="multiple"
        onChange={debounce((value) => {
          if (isEmpty(value)) {
            return;
          }
          setSearchParams((params) => {
            params.delete("insurer");
            value.forEach((id) => {
              params.append("insurer", id);
            });
            return params;
          });
        }, 1000)}
        onClear={() => {
          setSearchParams((newSearchParams) => {
            newSearchParams.delete("insurer");
            return newSearchParams;
          });
        }}
        optionFilterProp="name"
        options={companiesData?.companies.map((company) => ({
          label: (
            <Space>
              <span>
                {company.companyCode} - {company.name}
              </span>
              <PapayaIf condition={import.meta.env.DEV}>{company.id}</PapayaIf>
            </Space>
          ),
          name: company.name,
          value: company.id,
        }))}
        placeholder="Công ty BH"
        showSearch
        style={{ width: "100%" }}
      />
    </Form.Item>
  );
};

export default InsurerSelection;
