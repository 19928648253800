import type { GLOBAL_DATA_QUERY, MeDocument } from "contexts/graphql/queries";
import type createApolloClient from "services/apolloClientServices";

// eslint-disable-next-line import/prefer-default-export
export enum OAUTH_TYPES {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  MICROSOFT = "microsoft",
}

type LogOutCallbackParam = {
  callback?: VoidFunction;
};

type LogOutCallback = (param?: LogOutCallbackParam) => void;

export type IAuth = {
  accessPermissions: IdentityAccessPermissionsEnum[];
  allowedRoles?: IdentityRolesEnum[];
  apolloClient: ReturnType<typeof createApolloClient>;
  claimStatusValueToComment: (status: ClaimCaseStatusesEnum) => null | string | undefined;
  globalData?: {
    fwdMaritalStatuses?: Record<string, string>;
    maritalStatuses?: Record<string, string>;
  } & ResultOf<typeof GLOBAL_DATA_QUERY>;
  hasAccessPermissions: (permissions: IdentityAccessPermissionsEnum[] | undefined) => boolean;
  hasRole: (role: IdentityRolesEnum[] | undefined) => boolean;
  isLoggedIn: boolean;
  oauthSignIn: ({
    callback,
    extras,
    token,
    type,
  }: {
    callback: ({ isLoggedIn }) => void;
    extras: Record<string, number | string | undefined>;
    token: string;
    type: OAUTH_TYPES;
  }) => void;
  selectedRole?: IdentityRolesEnum | null;
  setRequestPermissions: (permissions: IdentityAccessPermissionsEnum[]) => void;
  setRole: (role: IdentityRolesEnum) => void;
  signIn: ({ callback, email, password }: { callback: ({ isLoggedIn }) => void; email: string; password: string }) => void;
  signInLoading: boolean;
  signOut: LogOutCallback;
  token?: null | string;
  user: NonNullable<ResultOf<typeof MeDocument>["identity_users_by_pk"]>;
  userId?: null | string;
};
