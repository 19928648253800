import type { FC } from "react";

import { Popover, Space, Tooltip } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { isEqualZero } from "libs/hidash";
import { Tags } from "lucide-react";
import { Link } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import type { LabelValue } from "../Label";

import PapayaIf from "../PapayaIf";
import Label from "../Label";

const LABEL_FRAGMENT = graphql(`
  fragment Label on labels {
    id
    name
    color
    description
    # icon
    created_at
  }
`);

// prettier-ignore
const LABEL_OF_AN_OBJECT_QUERY = graphql(`
    query LabelsOfAnObject($objectId: uuid!) {
      labels_objects(where: { object_id: { _eq: $objectId } }) {
        id
        label {
          id
          ...Label
        }
        created_at
      }
    }
  `, [LABEL_FRAGMENT]
);

// prettier-ignore
const ALL_LABELS_QUERY = graphql(`
    query AllLabels {
      labels {
        id
        ...Label
      }
    }
  `, [LABEL_FRAGMENT]
);

const LABEL_AN_OBJECT_MUTATION = graphql(`
  mutation LabelAnObject($input: labels_objects_insert_input!) {
    insert_labels_objects_one(object: $input, on_conflict: { constraint: labels_objects_pkey, update_columns: [created_at, deleted_at] }) {
      id
    }
  }
`);

type LabelsProps = {
  filter?: (label: LabelValue) => boolean;
  itemStyle?: React.CSSProperties;
  onItemClick?: (label: LabelValue) => void;
};

const Labels: FC<LabelsProps> = ({ filter, itemStyle, onItemClick }) => {
  const { data: labelsData } = usePQuery(ALL_LABELS_QUERY);

  let labels = labelsData?.labels;
  if (filter) {
    labels = labels?.filter(filter);
  }

  if (labels?.length === 0) {
    return <Link to={PORTAL_PATH.ADMIN.LABELS}>Thêm label</Link>;
  }

  return (
    <div style={{ maxWidth: 600 }}>
      {labels?.map((label) => (
        <Label
          key={`${label.id}${label.created_at}`}
          onClick={() => {
            onItemClick?.(label);
          }}
          style={itemStyle}
          value={label}
        />
      ))}
    </div>
  );
};

type Props = {
  data?: {
    label: { color: string; created_at?: string; description: null | string; icon?: null | string; id: string; name: string };
  }[];
  editable?: "add-only" | boolean;
  objectId?: null | string;
  onLabelClick?: (label: LabelValue) => void;
};

const LabelDisplay: FC<Props> = ({ data, editable = false, objectId, onLabelClick }) => {
  const { data: labelsOfAnObjectData } = usePQuery(LABEL_OF_AN_OBJECT_QUERY, data != null || objectId == null ? { skip: true } : { variables: { objectId } });
  const [labelAnObjectMutation] = usePMutation(LABEL_AN_OBJECT_MUTATION, { awaitRefetchQueries: true, refetchQueries: ["LabelsOfAnObject"] });

  const forMap = (tag: { label: LabelValue }) => {
    const tagElem = (
      <Label
        closable={editable === true}
        onClick={onLabelClick == null ? undefined : () => onLabelClick(tag.label)}
        onClose={() => {
          labelAnObjectMutation({
            variables: {
              input: {
                deleted_at: "now()",
                label_id: tag.label.id,
                object_id: objectId,
              },
            },
          });
        }}
        value={tag.label}
      />
    );
    return (
      <span key={`${tag.label.id}`} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = (data ?? labelsOfAnObjectData?.labels_objects)?.map(forMap);

  if (isEqualZero(tagChild)) {
    if (editable === true || editable === "add-only") {
      return (
        <Popover
          content={
            <Labels
              filter={(label) => labelsOfAnObjectData?.labels_objects.some((l) => l.label.id === label.id) === false}
              itemStyle={{ cursor: "pointer" }}
              onItemClick={(label) => {
                labelAnObjectMutation({
                  variables: {
                    input: {
                      label_id: label.id,
                      object_id: objectId,
                    },
                  },
                });
              }}
            />
          }
          placement="right"
          trigger="click"
        >
          <div>
            <Tooltip title="Thêm label">
              <Tags className="cursor-pointer" color="#1677ff" size={14} />
            </Tooltip>
          </div>
        </Popover>
      );
    }
    return null;
  }
  return (
    <Space style={{ width: "unset" }}>
      <PapayaIf condition={editable !== "add-only"}>{tagChild}</PapayaIf>
      <PapayaIf condition={editable === true || editable === "add-only"}>
        <Popover
          content={
            <Labels
              filter={(label) => labelsOfAnObjectData?.labels_objects.some((l) => l.label.id === label.id) === false}
              itemStyle={{ cursor: "pointer" }}
              onItemClick={(label) => {
                labelAnObjectMutation({
                  variables: {
                    input: {
                      label_id: label.id,
                      object_id: objectId,
                    },
                  },
                });
              }}
            />
          }
          placement="right"
          trigger="click"
        >
          <div id="label">
            <Tooltip title="Thêm label">
              <Tags className="cursor-pointer" color="#1677ff" size={14} />
            </Tooltip>
          </div>
        </Popover>
      </PapayaIf>
    </Space>
  );
};

export default LabelDisplay;
