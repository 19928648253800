/* eslint-disable deprecate/function */
import type { IAuth } from "contexts/types";
import type { FC } from "react";
import type createApolloClient from "services/apolloClientServices";

import { skipToken, useLazyQuery, useMutation, useSuspenseQuery } from "@apollo/client";
import { useMsal } from "@azure/msal-react";
import { googleLogout } from "@react-oauth/google";
import { useInterval, useLocalStorageState } from "ahooks";
import { message } from "antd";
import { SLACK } from "config";
import { LOCAL_STORAGE_KEYS } from "config/constants";
import { AuthContext } from "contexts/AuthContext";
import { GLOBAL_DATA_QUERY, MeDocument } from "contexts/graphql/queries";
import { OAUTH_TYPES } from "contexts/types";
import { jwtDecode } from "jwt-decode";
import { isEqualZero } from "libs/hidash";
import { useQuery } from "libs/useQuery";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isExpired } from "react-jwt";
import { graphql } from "sdk/v2/graphql";

type Props = { apolloClient: ReturnType<typeof createApolloClient>; children: React.ReactNode } & Record<string, unknown>;

const IdentityPermissionRolesDocument = graphql(`
  query IdentityPermissionRoles($combined_roles: [String!]) {
    identity_flattened_permission_roles(where: { role_name: { _in: $combined_roles } }, distinct_on: permission_name) {
      permission_name
    }
  }
`);

const SignInDocument = graphql(`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      message
      success
      token
    }
  }
`);

const UpdateUserAfterLoginDocument = graphql(`
  mutation UpdateUserAfterLogin($userId: uuid!, $input: identity_users_set_input!) {
    update_identity_users_by_pk(pk_columns: { user_id: $userId }, _set: $input) {
      id
      avatar_url
      email
    }
  }
`);

const VerifyGoogleAuthDocument = graphql(`
  query VerifyGoogleAuth($token: String!, $deviceInfo: String!) {
    verifyGoogleAuth(token: $token, deviceInfo: $deviceInfo)
  }
`);

const VerifyMicrosoftAuthDocument = graphql(`
  query VerifyMicrosoftAuth($token: String!, $deviceInfo: String!) {
    verifyMsAuth(token: $token, deviceInfo: $deviceInfo)
  }
`);

const useAccessPermissions = (role: IdentityRolesEnum[] | undefined) => {
  const { data, loading } = useQuery(
    IdentityPermissionRolesDocument,
    isEqualZero(role)
      ? { skip: true }
      : {
          context: { headers: { "x-hasura-role": "User" } },
          variables: { combined_roles: role ?? [] },
        },
  );
  return { data: data?.identity_flattened_permission_roles ?? [], loading };
};

const getDefaultRole = (roles: IdentityRolesEnum[]): IdentityRolesEnum | undefined => {
  const storedRole = localStorage.getItem("selectedRole");
  if (roles.includes("TenantAdmin")) return "TenantAdmin";
  if (roles.includes("MedicalProviderEmployee")) return "MedicalProviderEmployee";
  if (storedRole !== null) return storedRole as IdentityRolesEnum;
  if (roles.length === 2) return roles.find((role) => role !== "User");
  return undefined;
};

const AuthProvider: FC<Props> = ({ apolloClient, children }) => {
  const [token, setToken] = useLocalStorageState<string | undefined>(LOCAL_STORAGE_KEYS.TOKEN, {
    deserializer: (v) => (v === "undefined" ? undefined : v),
    listenStorageChange: true,
    serializer: (v) => v ?? "undefined",
  });
  const requestPermissions = useRef<IdentityAccessPermissionsEnum[]>(undefined);
  const [signInMutation, { client, loading: signInMutationLoading }] = useMutation(SignInDocument);
  const [verifyGoogleAuth, { loading: verifyGoogleAuthLoading }] = useLazyQuery(VerifyGoogleAuthDocument);
  const [verifyMicrosoftAuth, { loading: verifyMicrosoftAuthLoading }] = useLazyQuery(VerifyMicrosoftAuthDocument);

  const [isLoggedIn, setIsLoggedIn] = useState(token != null);

  const [selectedRole, setSelectedRole] = useState<IdentityRolesEnum>();
  const { data: globalData } = useQuery(GLOBAL_DATA_QUERY, { skip: token == null });
  const [updateUser] = useMutation(UpdateUserAfterLoginDocument);
  const { accounts, instance } = useMsal();

  const resetDataWhenLogout = useCallback(() => {
    client.clearStore().then(() => {
      setToken(() => {
        setSelectedRole(undefined);
        setIsLoggedIn(false);
        googleLogout();
        localStorage.clear();
        if (accounts.length > 0) {
          accounts.forEach((account) => instance.logoutRedirect({ account }));
        }
        return undefined;
      });
    });
  }, [accounts, client, instance, setToken]);

  const signOut: IAuth["signOut"] = useCallback(
    (params) => {
      resetDataWhenLogout();
      params?.callback?.();
    },
    [resetDataWhenLogout],
  );

  const allowedRoles: IdentityRolesEnum[] = useMemo(() => {
    if (token != null) {
      const jwtData = jwtDecode<IAuthenticatedUser>(token);
      if (jwtData.exp < Date.now() / 1000) {
        signOut();
      }
      return jwtData.hasura?.claims?.["x-hasura-allowed-roles"] as IdentityRolesEnum[];
    }
    return [];
  }, [token, signOut]);

  useEffect(() => {
    setSelectedRole(getDefaultRole(allowedRoles));
  }, [allowedRoles]);

  useEffect(() => {
    if (token != null && !isExpired(token)) {
      setIsLoggedIn(true);
    }
    if (token != null && isExpired(token)) {
      resetDataWhenLogout();
    }
  }, [resetDataWhenLogout, token]);

  useInterval(() => {
    if (token == null) return;
    const jwtData = jwtDecode<IAuthenticatedUser>(token);
    if (jwtData.exp < Date.now() / 1000) {
      signOut();
    }
  }, 60_000);

  const userId = (() => {
    if (token != null) {
      const jwtData = jwtDecode<IAuthenticatedUser>(token);
      if (jwtData.exp < Date.now() / 1000) {
        signOut();
      }

      return jwtData.hasura?.claims?.["x-hasura-user-id"];
    }
    return null;
  })();
  // eslint-disable-next-line deprecate/function
  const { data: userData, refetch } = useSuspenseQuery(
    MeDocument,
    userId == null
      ? skipToken
      : {
          variables: { id: userId },
        },
  );

  const onSignInComplete = useCallback(
    async ({
      callback,
      extras,
      token: _token,
    }: {
      callback: ({ isLoggedIn }: { isLoggedIn: boolean; message?: string }) => void;
      extras?: Record<string, any>;
      token?: null | string;
    }) => {
      if (_token == null) {
        callback({ isLoggedIn: false, message: "Sai tên đăng nhập hoặc mật khẩu" });
        return;
      }
      setIsLoggedIn(true);
      setToken(_token);
      localStorage.setItem("token", _token);
      const jwtData = jwtDecode(_token) as IAuthenticatedUser;
      const userIdFromJwt = jwtData.hasura?.claims?.["x-hasura-user-id"];
      let picture = extras?.picture;
      const { data: user } = await refetch({ id: userIdFromJwt });

      if (user?.identity_users_by_pk?.slack_member_id != null) {
        const { SlackAPIClient } = await import("slack-web-api-client");
        const slackWebClient = new SlackAPIClient();
        const res = await slackWebClient.users.profile.get({ token: SLACK.TOKEN, user: user.identity_users_by_pk.slack_member_id });
        picture = res.profile?.image_512;
      }
      if (userIdFromJwt == null || (picture == null && extras?.email == null)) return;
      updateUser({
        variables: {
          input: {
            avatar_url: picture,
            email: extras?.email,
          },
          userId: userIdFromJwt,
        },
      });
      callback({ isLoggedIn: true });
    },
    [refetch, setToken, updateUser],
  );

  const signIn: IAuth["signIn"] = useCallback(
    ({ callback, email, password }) => {
      signInMutation({
        context: {
          headers: {
            "x-hasura-role": "anonymous",
          },
        },
        onCompleted: (data) => onSignInComplete({ callback, token: data.login?.token }),
        variables: {
          email,
          password,
        },
      });
    },
    [onSignInComplete, signInMutation],
  );

  const oauthSignIn: IAuth["oauthSignIn"] = useCallback(
    async ({ callback, extras, token: _token, type }) => {
      fetch("https://checkip.amazonaws.com/", {
        method: "GET",
        mode: "no-cors",
      })
        .then((res) => res.text())
        .then((data) => {
          switch (type) {
            case OAUTH_TYPES.MICROSOFT: {
              verifyMicrosoftAuth({
                onCompleted: (verifyMicrosoftAuthData) => onSignInComplete({ callback, token: verifyMicrosoftAuthData.verifyMsAuth }),
                onError: (error) => {
                  message.error(error.message);
                },
                variables: {
                  deviceInfo: JSON.stringify({ ...extras, deviceType: "care-portal-web-microsoft", ip: data }),
                  token: _token,
                },
              });
              break;
            }
            case OAUTH_TYPES.GOOGLE: {
              verifyGoogleAuth({
                onCompleted: (_data) => onSignInComplete({ callback, extras, token: _data.verifyGoogleAuth }),
                onError: (error) => {
                  message.error(error.message);
                },
                variables: {
                  deviceInfo: JSON.stringify({ ...extras, deviceType: "care-portal-web-google", ip: data }),
                  token: _token,
                },
              });
              break;
            }
            default: {
              message.error("Unknown oauth type");
              break;
            }
          }
        });
    },
    [onSignInComplete, verifyGoogleAuth, verifyMicrosoftAuth],
  );

  const hasRole = useCallback((checkRoles: IdentityRolesEnum[] | undefined) => allowedRoles.some((v) => checkRoles?.includes(v)), [allowedRoles]);

  const setRole = useCallback(
    (_role: IdentityRolesEnum) => {
      if (hasRole([_role])) {
        localStorage.setItem("selectedRole", _role);
        setSelectedRole(_role);
      }
    },
    [hasRole],
  );

  const { data: accessPermissionData } = useAccessPermissions(allowedRoles);
  const accessPermissions = useMemo(() => accessPermissionData.map((ap) => ap.permission_name as IdentityAccessPermissionsEnum), [accessPermissionData]);

  const hasAccessPermissions = useCallback(
    (permissions: IdentityAccessPermissionsEnum[] | undefined) => (permissions != null && permissions.length > 0 ? accessPermissions.some((ap) => permissions.includes(ap)) : true),
    [accessPermissions],
  );

  const setRequestPermissions = useCallback((permissions: IdentityAccessPermissionsEnum[] | undefined) => {
    requestPermissions.current = permissions;
  }, []);

  const claimStatusValueToComment = useMemo(
    () => (status: ClaimCaseStatusesEnum) => globalData?.claim_case_statuses.find((s) => s.value === status)?.comment,
    [globalData?.claim_case_statuses],
  );

  const signInLoading = signInMutationLoading || verifyGoogleAuthLoading || verifyMicrosoftAuthLoading;

  useEffect(() => {
    if (userData?.identity_users_by_pk == null) {
      signOut();
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_INFO, JSON.stringify(userData.identity_users_by_pk));
    }
  }, [signOut, userData?.identity_users_by_pk]);

  const value = useMemo(() => {
    if (userData?.identity_users_by_pk == null) {
      return {
        accessPermissions,
        allowedRoles,
        apolloClient,
        claimStatusValueToComment,
        globalData,
        hasAccessPermissions,
        hasRole,
        isLoggedIn,
        oauthSignIn,
        selectedRole,
        setRequestPermissions,
        setRole,
        signIn,
        signInLoading,
        signOut,
      };
    }
    return {
      accessPermissions,
      allowedRoles,
      apolloClient,
      claimStatusValueToComment,
      globalData,
      hasAccessPermissions,
      hasRole,
      isLoggedIn,
      oauthSignIn,
      selectedRole,
      setRequestPermissions,
      setRole,
      signIn,
      signInLoading,
      signOut,
      token,
      user: userData.identity_users_by_pk,
      userId,
    };
  }, [
    apolloClient,
    userId,
    allowedRoles,
    selectedRole,
    signIn,
    oauthSignIn,
    signOut,
    isLoggedIn,
    userData,
    token,
    setRole,
    hasRole,
    accessPermissions,
    hasAccessPermissions,
    setRequestPermissions,
    globalData,
    claimStatusValueToComment,
    signInLoading,
  ]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider };
