import type { FC } from "react";

import { PageHeader } from "@ant-design/pro-layout";
import { Button, Flex, message, Modal, notification, Popover, Skeleton, Space, Tooltip, Typography } from "antd";
import Avatar from "app/common/components/Avatar";
import BenefitTag from "app/common/components/BenefitTagV3";
import ClaimIcons from "app/common/components/ClaimIcons";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import Copyable from "app/common/components/Copyable";
import LabelDisplay from "app/common/components/LabelDisplay";
import PapayaIf from "app/common/components/PapayaIf";
import TableList from "app/common/components/TableListV2";
import { PORTAL_PATH } from "app/portal/config/paths";
import useClaimShortcuts from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/useClaimShortcuts";
import CriticalAlerts from "app/portal/screens/ClaimPortal/ClaimContext/CriticalAlerts";
import useUpdateClaimTat from "app/portal/screens/ClaimPortal/ClaimContext/useUpdateClaimTat";
import { FORMATTER, MIMES } from "config/constants";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import { startOfDay } from "date-fns";
import utils from "libs/utils";
import { Boxes, RefreshCcw, RefreshCw } from "lucide-react";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import useClaim from "../ClaimContext/useClaim";
import styles from "./ClaimCaseScreen.module.less";

const { Paragraph } = Typography;

const UpdateClaimAgentDocument = graphql(`
  mutation UpdateClaimAgent($claimCaseId: uuid!, $agentId: uuid!) {
    update_claim_cases_by_pk(pk_columns: { claim_case_id: $claimCaseId }, _set: { agent_id: $agentId }) {
      id
    }
  }
`);

const MbalUpdatePremiumDebtAmountDocument = graphql(`
  mutation MbalUpdatePremiumDebtAmount($claimCaseId: UUID!) {
    mbal {
      updatePremiumDebtAmount(claimCaseId: $claimCaseId) {
        actualPaidAmount
        initialPremiumDebtAmount
      }
    }
  }
`);

const MrRefreshClaimInfoDocument = graphql(`
  mutation MrRefreshClaimInfo($insuredPersonNumber: String!, $policyNumber: String!, $planCode: String!) {
    mr {
      refreshClaimInfo(insuredPersonNumber: $insuredPersonNumber, policyNumber: $policyNumber, planCode: $planCode)
    }
  }
`);

const GetPolicyDetailDocument = graphql(`
  query MbalPolicyDetail($policyNumber: String!, $eventDate: DateTime!) {
    mbal {
      policyByPolicyNumber(policyNumber: $policyNumber, eventDate: $eventDate) {
        id: policyId
        policyId
        policyNumber
        certificates {
          certificateId
          endDate
          reverseEffectiveDate
          startDate
          insured_certificate {
            id
            insured_certificate_id
            claim_cases {
              id
              admission_date
              claim_case_id
              code
              discharge_date
              claim_case_status {
                comment
                value
              }
              medical_provider {
                id
                name
              }
            }
            insured_certificate_agents(where: { type: { _eq: CURRENT } }, order_by: { created_at: desc }) {
              id
              agent {
                id
                name
                code
              }
            }
          }
          lapseReinHistories {
            lapseDate
            reinstateDate
          }
          livesAssured {
            laId
            laName
            laNumber
            identityPapers {
              paperIssuedDate
              paperIssuedPlace
              paperNumber
              paperType
            }
          }
        }
      }
    }
  }
`);

const ClaimCaseHeader: FC<{ title: string }> = () => {
  const { claim, claimType, loading, refetch, tat } = useClaim();
  const updateClaimTat = useUpdateClaimTat();
  const [updateClaimAgent] = usePMutation(UpdateClaimAgentDocument);
  const [mbalUpdatePremiumDebtAmount, { loading: loadingMbalUpdatePremiumDebtAmount }] = usePMutation(MbalUpdatePremiumDebtAmountDocument);
  const [prevPolicyClaim, setPrevPolicyClaim] = useState(claim);
  const [showModalChangedPolicy, setShowModalChangedPolicy] = useState(false);
  const { icons } = useClaimShortcuts();

  const [mbalGetPolicyDetail, { loading: loadingMbalGetPolicyDetail }] = usePLazyQuery(GetPolicyDetailDocument, {
    onCompleted: async (data) => {
      if (data.mbal?.policyByPolicyNumber == null) {
        notification.warning({ message: "Cập nhật hợp đồng thất bại." });
        return;
      }
      await mbalUpdatePremiumDebtAmount({
        variables: {
          claimCaseId: claim?.claim_case_id ?? "",
        },
      });
      const currentCertificate = data.mbal.policyByPolicyNumber.certificates.find((cert) => cert.certificateId === claim?.insured_certificate_id)?.insured_certificate;
      if (claim?.agent?.code !== currentCertificate?.insured_certificate_agents[0]?.agent.code) {
        await updateClaimAgent({
          variables: {
            agentId: currentCertificate?.insured_certificate_agents[0]?.agent.id ?? "",
            claimCaseId: claim?.claim_case_id ?? "",
          },
        });
      }
      setPrevPolicyClaim(claim);
      await refetch();
      setShowModalChangedPolicy(true);
      notification.success({
        message: "Cập nhật hợp đồng thành công.",
      });
    },
  });

  const [mrRefreshClaimInfo, { loading: loadingMrRefreshClaimInfo }] = usePMutation(MrRefreshClaimInfoDocument, {
    onCompleted: async (data) => {
      if (data.mr?.refreshClaimInfo == null) {
        notification.warning({ message: "Cập nhật thất bại" });
      } else {
        await refetch();
        notification.success({ message: "Cập nhật thành công" });
      }
    },
  });

  useEffect(() => {
    if (showModalChangedPolicy === false || prevPolicyClaim == null || claim == null) {
      return;
    }

    const changedValues: {
      fieldName: string;
      newValue?: null | string;
      oldValue?: null | string;
    }[] = [
      {
        fieldName: "Tình trạng hợp đồng",
        newValue: claim.insured_certificate.policy.status,
        oldValue: prevPolicyClaim.insured_certificate.policy.status,
      },
      {
        fieldName: "Tư vấn tài chính hiện tại",
        newValue: claim.agent?.name,
        oldValue: prevPolicyClaim.agent?.name,
      },
      {
        fieldName: "Nợ phí ban đầu",
        newValue: utils.formatCurrency(claim.claim_case_payment?.initial_premium_debt_amount),
        oldValue: utils.formatCurrency(prevPolicyClaim.claim_case_payment?.initial_premium_debt_amount),
      },
      {
        fieldName: "Email NDBH",
        newValue: claim.insured_certificate.insured_person.email,
        oldValue: prevPolicyClaim.insured_certificate.insured_person.email,
      },
      {
        fieldName: "Số điện thoại NDBH",
        newValue: claim.insured_certificate.insured_person.phone,
        oldValue: prevPolicyClaim.insured_certificate.insured_person.phone,
      },
      claim.grace_period_start_date == null && prevPolicyClaim.grace_period_start_date == null
        ? null
        : {
            fieldName: "Ngày bắt đầu ân hạn",
            newValue: utils.formatDate(claim.grace_period_start_date, FORMATTER.DATE_FORMAT),
            oldValue: utils.formatDate(prevPolicyClaim.grace_period_start_date, FORMATTER.DATE_FORMAT),
          },
      {
        fieldName: "Tên ngân hàng",
        newValue: claim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.bank_name,
        oldValue: prevPolicyClaim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.bank_name,
      },
      {
        fieldName: "Số tài khoản",
        newValue: claim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.bank_account,
        oldValue: prevPolicyClaim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.bank_account,
      },
      {
        fieldName: "Người thụ hưởng",
        newValue: claim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.beneficiary_name,
        oldValue: prevPolicyClaim.insured_certificate.mbal_insured_certificate_beneficiaries[0]?.beneficiary_name,
      },
    ].filter(Boolean);

    Modal.info({
      title: "Cập nhật thông tin hợp đồng",
      width: 800,
      content: (
        <TableList
          data={changedValues}
          schema={[
            {
              dataIndex: "fieldName",
              key: "fieldName",
              title: "Thông tin",
            },
            {
              dataIndex: "oldValue",
              key: "oldValue",
              title: "Giá trị trước đó",
            },
            {
              dataIndex: "newValue",
              key: "newValue",
              render: (value, record) => <Typography.Text mark={record.newValue !== record.oldValue}>{value}</Typography.Text>,
              title: "Giá trị được cập nhật",
            },
          ]}
        />
      ),
    });

    setShowModalChangedPolicy(false);
  }, [claim, prevPolicyClaim, showModalChangedPolicy]);

  useHotkeys(["ctrl+shift+c", "meta+ctrl+c"], () => {
    if (claim == null) return;
    navigator.clipboard.write([
      new ClipboardItem({
        [MIMES.TEXT_PLAIN]: new Blob([claim.code], {
          type: MIMES.TEXT_PLAIN,
        }),
        "text/html": new Blob([`<a href="${window.location.href}">${claim.code}</a>`], {
          type: "text/html",
        }),
      }),
    ]);
    message.success("Claim code copied");
  });

  if (claim == null || loading) {
    return (
      <Space direction="vertical" size={16} style={{ width: "90%", margin: 20 }}>
        <Skeleton active />
      </Space>
    );
  }

  const dayHourMinuteTat = claim.tatDayHourMin;

  return (
    <PageHeader
      className="bg-transparent w-full"
      extra={
        <Flex align="center" className="mt-[10px]" gap="large" justify="flex-end">
          {icons.map(({ hotkey, icon, link, tooltip }) => {
            const finalLink = (() => {
              let l = window.location.href;
              icons.forEach((i) => {
                l = l.replaceAll(i.link, "");
              });
              return `${l}${link}`;
            })();

            return (
              <Tooltip key={link} title={`${tooltip} - ${hotkey}`}>
                <Link to={`${finalLink}`}>{icon}</Link>
              </Tooltip>
            );
          })}
        </Flex>
      }
      ghost={false}
      title={
        <Space direction="vertical">
          <Space>
            <div className={styles.claimNo}>
              <Paragraph
                copyable={{
                  format: "text/html",
                  onCopy: (e) => utils.saveToClipboard(e, claim.code, claim.claim_case_id),
                  tooltips: false,
                }}
              >
                {claim.code}
              </Paragraph>
            </div>
            <ClaimStatus bordered={false} value={claim.claim_case_status} />
            <BenefitTag value={claim.insured_benefit_type} />
            {claim.genesis_claim_case?.id != null && (
              <Paragraph>
                (Clone từ <Link to={generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: claim.genesis_claim_case.id })}>{claim.genesis_claim_case.code}</Link>)
              </Paragraph>
            )}
            <PapayaIf condition={claimType.fwdMr}>
              <div className={styles.claimNo}>
                <Copyable show>{claim.claim_number}</Copyable>
              </div>
            </PapayaIf>
            <Popover
              content={
                <Button
                  onClick={() => {
                    updateClaimTat({ claimId: claim.id, overwriteCache: true });
                    updateClaimTat({ claimId: claim.id, forFirstPending: true, overwriteCache: true, showNotification: true });
                  }}
                  type="primary"
                >
                  Tính lại TAT
                </Button>
              }
            >
              <div
                className={styles.indicator}
                style={{
                  color: Number(tat) > (claim.insured_certificate.policy.policy_setting?.tat_max ?? 24) - 4 ? "red" : "unset",
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
              >
                TAT: {dayHourMinuteTat}
              </div>
            </Popover>
            <div className={styles.indicator}>
              <ClaimIcons claim={claim} showCode={false} showLabels={false} />
            </div>
            <Space className={styles.indicator}>
              TĐV:
              <PapayaIf condition={claim.meta_v2?.assessor != null} else="Chưa được assign">
                <Space>
                  <Avatar
                    size={24}
                    src={claim.meta_v2?.assessor?.avatar_url}
                    style={{
                      backgroundColor: "var(--background-avatar-color)",
                    }}
                    text={claim.meta_v2?.assessor?.name}
                  />
                  <span>{claim.meta_v2?.assessor?.name}</span>
                </Space>
              </PapayaIf>
            </Space>
            <PapayaIf condition={claim.claim_case_group_claim_cases.length > 0}>
              <div className={styles.indicator}>
                <Tooltip title="Có claim cùng nhóm">
                  <Link to="#claim_group">
                    <Boxes size={16} />
                  </Link>
                </Tooltip>
              </div>
            </PapayaIf>
            <PapayaIf condition={claimType.fwdMr}>
              <Tooltip title="Truy vấn dữ liệu mới nhất từ CTBH và cập nhật vào hệ thống Papaya">
                <Button
                  disabled={loadingMrRefreshClaimInfo}
                  icon={<RefreshCw className={loadingMrRefreshClaimInfo ? "animate-spin" : ""} size={14} />}
                  onClick={() => {
                    mrRefreshClaimInfo({
                      variables: {
                        insuredPersonNumber: claim.insured_certificate.insured_person.insured_number,
                        planCode: (claim.insured_certificate.policy_plan?.plan_code ?? "").split("/")[0] ?? "",
                        policyNumber: claim.insured_certificate.policy.policy_number,
                      },
                    });
                  }}
                  type="primary"
                >
                  Cập nhật
                </Button>
              </Tooltip>
            </PapayaIf>
            <PapayaIf condition={claimType.mbalHs}>
              <Tooltip title="Truy vấn dữ liệu mới nhất từ CTBH và cập nhật vào hệ thống Papaya">
                <Button
                  disabled={loadingMbalGetPolicyDetail || loadingMbalUpdatePremiumDebtAmount}
                  icon={<RefreshCcw className={loadingMbalGetPolicyDetail || loadingMbalUpdatePremiumDebtAmount ? "animate-spin" : ""} size={14} />}
                  onClick={async () => {
                    mbalGetPolicyDetail({
                      variables: {
                        eventDate: startOfDay(new Date()).toISOString(),
                        policyNumber: claim.insured_certificate.policy.policy_number,
                      },
                    });
                  }}
                  type="primary"
                >
                  Cập nhật
                </Button>
              </Tooltip>
            </PapayaIf>
            <LabelDisplay editable objectId={claim.id} />
          </Space>
          <CriticalAlerts />
        </Space>
      }
    />
  );
};

export default ClaimCaseHeader;
