import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

const fragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimDocument on claim_documents {
    id
    display_order
    is_supplemented
    source
    type
    created_at
    assessed_claim_pending_codes {
      id
      code
      extended_text
    }
    claim_document_type {
      comment
      value
    }
    claim_pending_code {
      id
      code
      extended_text
      pending_code {
        id
        code
      }
    }
    file {
      id
      mime_type
      page_number
      url
      child_files_aggregate {
        aggregate {
          count
        }
        nodes {
          id
          claim_document_id
        }
      }
      original_file {
        id
        url
        claim_document {
          id
        }
      }
    }
    newest_ocr_paper {
      id
      prescription {
        id
        diagnoses
        icd_codes
      }
    }
    ocr_claim_document_type {
      id: value
      comment
      value
    }
    uploader {
      id
      name
    }
  }
`);

export type ClaimDocumentFragmentType = ResultOf<typeof fragment>;

export default fragment;
