import { Modal } from "antd";

const openPdfWithSearchModal = ({ content, errorMessage, url }: { content?: null | string; errorMessage?: null | React.ReactNode | string; url?: null | string }) => {
  if (url == null || content == null) {
    Modal.error({
      content: errorMessage ?? "Không tìm thấy hợp đồng hoặc nội dung",
      maskClosable: true,
      okText: "Đóng",
    });
  } else {
    Modal.info({
      closable: true,
      content: <iframe className="h-[85vh] w-[90vw]" src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${url}#search=${encodeURIComponent(content)}&phrase=true`} />,
      height: "100vh",
      maskClosable: true,
      okText: "Đóng",
      style: { top: 15 },
      title: "Hợp đồng",
      width: "100vw",
    });
  }
};

export default openPdfWithSearchModal;
