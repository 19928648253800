export const PORTAL_PATH = {
  403: "/403",
  ACTIVATE_INSURANCE_CERTIFICATE: "/portal/policies/:policyId/certificates/activation",
  ADMIN: {
    LABELS: "/portal/admin/labels",
  },
  BOOKING: {
    BOOKING_DETAIL: "/portal/booking-manager/:contractId/info",
    BOOKING_MANAGE: "/portal/booking-manager",
    EDIT_LIST_BOOKING: "/portal/edit-list-booking",
    EDIT_PROFILE_BOOKING: "/portal/edit-profile-booking",
    HEALTH_PROFILE_UPLOAD_TOOL: "/portal/health-profile-upload-tool",
    LIST_BOOKING: "/portal/list-booking",
  },
  CERTIFICATE_DETAIL: "/portal/policies/:policyId/certificates/:certificateId",
  CLAIM: {
    BATCH_UPLOAD: "/portal/claims/batch-docs-upload",
  },
  CLAIM_CASE: "/portal/claimCase/:claimCaseId",
  CLAIM_CASE_ASSESSOR: "/portal/claim-cases/assignments/assessors/:assessorId",
  CLAIM_CASE_ASSESSORS: "/portal/claim-cases/assignments/assessors",
  CLAIM_CASE_ASSIGNMENT: "/portal/claim-cases/assignments",
  CLAIM_CASE_FWD_MR_ASSIGNMENT: "/portal/claim-cases/fwd-mr-assignments",
  CLAIM_CASE_QA: "/portal/claim-cases/qa",

  CLAIM_HISTORY: "",
  CLAIM_LIST: "/portal/claims",
  CM: {
    ADMIN_USER_EDIT: "/portal/admin/users/:userId",
    ADMIN_USERS_LIST: "/portal/admin/users",
    PERMISSION: "/portal/cm/permission",
  },
  COMPANY_CREATE: "/portal/companies/create",
  COMPANY_DETAIL: "/portal/companies/:companyId",
  COMPANY_LIST: "/portal/companies",
  CORRESPONDENCE: {
    DETAIL: "/portal/correspondences/:id",
    LIST: "/portal/correspondences",
    NEW: "/portal/correspondences/new",
    OTHER_TEMPLATE_DETAIL: "/portal/correspondences/:id/template/:templateId/other",
    TEMPLATE_DETAIL: "/portal/correspondences/:id/template/:templateId",
  },

  CREATE_CLAIM_CASE: "/portal/createClaimCase/InsuredPersons",
  DASHBOARD: "/portal/dashboard",
  DECISION: "/portal/decision",
  DOCS: {
    GMAIL_ADDON: "/portal/docs/gmail-addon",
  },

  DYNAMIC_CARD_CREATE: "/portal/cardTemplates/create",
  DYNAMIC_CARD_DETAIL: "/portal/cardTemplates/:id",
  DYNAMIC_CARD_LIST: "/portal/cardTemplates",
  EXPERIMENT: {
    APOLLO_CLIENT_USE_FRAGMENT: "/portal/experiment/apollo-client-use-fragment",
    OCR_OVERLAY: "/portal/experiment/ocr-overlay",
    THE_GUILD_USE_FRAGMENT: "/portal/experiment/the-guild-use-fragment",
  },
  FWD_HS_CLAIM_CASE: "/portal/fwd-hs-claim-cases/:claimCaseId",
  FWD_HS_CLAIM_LIST: "/portal/fwd-hs-claim-cases",
  FWD_MR_CLAIM_CASE: "/portal/fwd-mr-claim-cases/:claimCaseId",
  FWD_MR_CLAIM_CASE_ASSIGNMENT: "/portal/claim-cases/fwd-mr-assignments",
  FWD_MR_CLAIM_LIST: "/portal/fwd-mr-claim-cases",

  FWD_MR_CREATE_CLAIM_CASE: "/portal/fwd-mr-create-claim-case",
  FWD_MR_OCR_QC_DETAIL: "/portal/fwd-mr-qc/:claimCaseId",

  // MR - QC OCR
  FWD_MR_OCR_QC_LIST: "/portal/fwd-mr-qc",
  GRACE_PERIOD: "/portal/grace-period",
  HC_CLAIM_CASE: "/portal/hc-claim-cases/:claimCaseId",
  HC_CLAIM_CASE_ASSIGNMENT: "/portal/claim-cases/assignments",
  HC_CLAIM_LIST: "/portal/hc-claim-cases",
  HC_CREATE_CLAIM_CASE: "/portal/hc-create-claim-case",
  HC_CREATE_CLAIM_CASE_LA: "/portal/hc-create-claim-case/insured-person/:id",
  HISTORY_CLAIM: "/portal/createClaimCase/InsuredPersons/:insuredPersonId",
  HOSPITAL_CERTIFICATES_BENEFIT: "/portal/hospital/certificates/:certificateId/benefits",
  HOSPITAL_CERTIFICATES_SEARCH: "/portal/hospital/certificates",
  HOSPITAL_CLAIM: "/portal/hospital/claims",
  HOSPITAL_CLAIM_CREATE: "/portal/hospital/certificates/claims/:certificateId/create",
  HOSPITAL_CLAIM_DETAIL: "/portal/hospital/claims/:claimType/:certificateId/:benefitType/:claimCaseId",
  HOSPITAL_CLAIM_PENDING: "/portal/hospital/claims/pending",
  HOSPITAL_CLAIM_RESULTED: "/portal/hospital/claims/resulted",
  HOSPITAL_CLAIM_SENT: "/portal/hospital/claims/sent",
  HOSPITAL_CLAIM_UNPROCESSED: "/portal/hospital/claims/unprocessed",
  HOSPITAL_CLAIM_UPDATE: "/portal/hospital/claims/:claimType/:certificateId/:benefitType/update/:claimCaseId",
  HOSPITAL_INSURER_SELECTION: "/portal/hospital/insurers",

  HOSPITAL_MANAGEMENT_REPORT: "/portal/hospital/management/report",
  INSURANCE_REQUESTS_CREATE: "/portal/insurance-requests/create",
  INSURANCE_REQUESTS_DETAIL: "/portal/insurance-requests/:id",

  INSURANCE_REQUESTS_LIST: "/portal/insurance-requests",
  INSURED_BENEFIT_LIST: "/portal/insuredBenefits",
  INSURED_CERTIFICATES_AND_PERSONS_IMPORT_RESULT: "/portal/insured-certificates-and-persons-import-result/:importedFileId",
  INSURED_PERSON_CREATE: "/portal/insuredPersons/create",
  INSURED_PERSON_DETAIL: "/portal/insuredPersons/:id",
  INSURED_PERSON_LIST: "/portal/insuredPersons",
  MAILBOX: {
    BASE_PATH: "/portal/mailboxes/*",
    CREATE_CLAIM_FROM_EMAIL_DETAIL: "/portal/mailboxes/:mailbox/email/:emailId/create-claim",
    CREATE_CLAIM_FROM_EMAIL_DETAIL_DEFAULT: "/portal/mailboxes/email/:emailId/create-claim",
    EMAIL_ASSESSMENT_OUTBOUND_LIST: "/portal/mailboxes/:mailbox/assessment-outbound",
    EMAIL_ASSESSMENT_OUTBOUND_LIST_DEFAULT: "/portal/mailboxes/assessment-outbound",
    EMAIL_DETAIL: "/portal/mailboxes/:mailbox/email/:emailId",
    EMAIL_DETAIL_DEFAULT: "/portal/mailboxes/email/:emailId",
    EMAIL_IGNORED_LIST: "/portal/mailboxes/:mailbox/ignored",
    EMAIL_IGNORED_LIST_DEFAULT: "/portal/mailboxes/ignored",
    EMAIL_LIST: "/portal/mailboxes/:mailbox",
    EMAIL_LIST_DEFAULT: "/portal/mailboxes",
    EMAIL_LIST_WITH_FILTER: "/portal/mailboxes/:mailbox/:primaryFilter",
    UPLOAD_CLAIM_DOCUMENTS_FROM_EMAIL_DETAIL: "/portal/mailboxes/:mailbox/email/:emailId/upload-claim-documents",
    UPLOAD_CLAIM_DOCUMENTS_FROM_EMAIL_DETAIL_DEFAULT: "/portal/mailboxes/email/:emailId/upload-claim-documents",
  },
  MBAL_CLAIM_LIST: "/portal/mbal-hs-claim-cases",
  MBAL_HS_CLAIM_CASE: "/portal/mbal-hs-claim-cases/:claimCaseId",
  MBAL_HS_CLAIM_CASE_ASSIGNMENT: "/portal/claim-cases/mbal-hs-assignments",
  MBAL_HS_CREATE_CLAIM_CASE: "/portal/mbal-hs-create-claim-case",
  MBAL_OCR_PAPER_INFO: "/portal/ocr-mbal-papers/:paperId",
  MBAL_OCR_QC_LIST: "/portal/ocr-mbal-papers",
  MEDICAL_PROVIDER_CREATE: "/portal/medical-providers/create",
  MEDICAL_PROVIDER_DETAIL: "/portal/medical-providers/:id",
  MEDICAL_PROVIDER_EDIT: "/portal/medical-providers/:id/edit",
  MEDICAL_PROVIDER_EMPLOYEE_DETAIL: "/portal/medical-providers/:id/:userId",
  MEDICAL_PROVIDER_LIST: "/portal/medical-providers",
  OCR_PAPER_INFO: "/portal/ocrPaper/:paperId/paperDetail",
  OCR_QC_LIST: "/portal/ocrPaper",
  PAYOUT: {
    CONFIRM_BENEFICIARY: "/portal/payout/confirm-beneficiary",
    DIRECT_BILLING: {
      BASE_PATH: "/portal/payout/db",
      BATCH_FAILED: "failed",
      BATCH_HISTORIES: "histories",
      BATCH_PAYMENT_CREATION: "payment-creation",
      DIRECT_BILLING_BATCH_DETAIL: "histories/:batchId",
    },
    REFUND: "/portal/payout/refund",
    REIMBURSEMENT: {
      BASE_PATH: "/portal/payout/re",
      PAYOUT_CREATION_TAB: "create",
      PAYOUT_ERROR_TAB: "error",
      PAYOUT_HISTORIES_DETAIL: "histories/:batchId",
      PAYOUT_HISTORIES_TAB: "histories",
    },
  },
  PLAN_BALANCES: "/portal/plans/:planId/insurer/:insurerId/balances",
  PLAN_BALANCES_DETAIL: "/portal/plans/:planId/balances/:planBalanceId",
  PLAN_BENEFIT: "/portal/plans/:planId/benefits",
  PLAN_BENEFIT_DETAIL: "/portal/plans/:planId/benefits/:planInsuredBenefitId",
  PLAN_COPAYMENT_CREATE: "/portal/plans/:planId/coPayment/create",
  PLAN_COPAYMENT_DETAIL: "/portal/plans/:planId/coPayment/:coPaymentId",
  PLAN_DETAIL: "/portal/plans/:planId",
  PLAN_DETAIL_INFO: "/portal/plans/:planId/details/:planDetailId",
  PLAN_DETAILS: "/portal/plans/:planId/details",
  PLAN_LIST: "/portal/plans",
  POLICY_CLONE: "/portal/policies/:policyId/clone",

  POLICY_COPAYMENT_CREATE: "/portal/policies/:policyId/coPayment/create",
  POLICY_COPAYMENT_DETAIL: "/portal/policies/:policyId/coPayment/:coPaymentId",
  POLICY_CREATE_TYPE: "/portal/policies/create/:type/:insuredPersonId?",
  POLICY_CREATE_TYPE_V2: "/portal/policies/create/v2",
  POLICY_DETAIL: "/portal/policies/info/:id",
  POLICY_INFO: "/portal/policies/info/:id",
  POLICY_LIST: "/portal/policies",
  REPORT: {
    AAA_REPORT: "/portal/report/aaa-report",
    ALL_PRODUCTIVITY: "/portal/report/all-productivity",
    ASSESSED_BENEFITS: "/portal/report/assessed-benefits",
    ASSESSMENT_ANALYTICS: "/portal/report/assessment-analytics",
    CLAIM: "/portal/report/claim",
    FWD_HS: "/portal/report/fwd-hs",
    FWD_MR_PRODUCTIVITY: "/portal/productivity/fwd_mr",
    HC_PRODUCTIVITY: "/portal/productivity/healthcare",
    INSURER_REFUND: "/portal/report/insurer-refund",
    MANUAL_PAYMENT_DB: "/portal/report/mr-mc-manual-payment-db",
    MANUAL_PAYMENT_RE: "/portal/report/mr-mc-manual-payment-re",
    MANUAL_PAYOUT: "/portal/report/manual-payout",
    MANUAL_REPORT_HEALTHCARE: "/portal/report/manual-report-for-healthcare",
    MBAL_CORRESPONDENCE: "/portal/report/mbal-correspondence",
    MBAL_HS_PRODUCTIVITY: "/portal/productivity/mbal-hs",
    MONTHLY_FWD_MR: "/portal/report/monthly-fwd-mr",
    MONTHLY_PRODUCTIVITY: "/portal/report/productivity/monthly",
    OTP: "/portal/report/otp_reconciliation",
    PAYOUT_REPORT: "/portal/report/payout-report",
    PENDING: "/portal/report/pending",
    POLICY_REPORT: "/portal/report/policy-report",
    PRODUCTIVITY: "/portal/report/productivity",
    SLV_PRODUCTIVITY: "/portal/productivity/slv-hs",
    SLV_TAT_REPORT: "/portal/report/slv-tat-report",
    SLV_TPA: "/portal/report/slv-tpa",
    STP_FWD_MR: "/portal/report/stp-fwd-mr",
    TAT_PAYOUT: "/portal/report/tat-payout",
    TAT_PAYOUT_FWD_HS: "/portal/report/tat-payout-fwd-hs",
    TOOLS: "/portal/report/tools",
    TOTAL: "/portal/report",
  },
  REPORT_MANAGEMENT: "/report-management",
  SALE: {
    INSURED_LEADS: "/portal/sale/insured-leads",
    PAYMENT_REQUESTS: "/portal/sale/payment-requests",
  },
  SAMPLE_SCREEN: "/portal/sample",
  SLV_HS_CLAIM_CASE: "/portal/slv-hs-claim-cases/:claimCaseId",
  SLV_HS_CLAIM_CASE_ASSIGNMENT: "/portal/claim-cases/slv-hs-assignments",
  SLV_HS_CLAIM_LIST: "/portal/slv-hs-claim-cases",
  TEST_CLAIM_CASE: "/portal/hc-claim-cases/test-claims-only/:claimCaseId",
  TEST_CLAIM_LIST: "/portal/hc-claim-cases/test-claims-only",
} as const;

export const COMPANY_PATH = {
  COMPANY_PLAN: "plans",
  COMPANY_POLICY: "policies",
} as const;

export const CLAIM_CASE_PATH = {
  ACTION_LOGS: "logs",
  ASSIGN: "assign",
  CERTIFICATE_CREATE_CLAIM_WITH_ID: ":type/:id/:claimId/info",
  CHECK_CASE: "presentCase",
  CLAIM_ASSESSMENT: "assessment",
  CLAIM_CASE_INFO: "info",
  CORRESPONDENCE: "correspondence/*",
  CORRESPONDENCE_CLAIM: "correspondence",
  CORRESPONDENCE3: "correspondence-v3",
  GALLERY: "gallery",
  HISTORY_ACTIVITY: "historyActivity",
  ORIGINAL: "original",
  PAYMENT_CLAIM: "payment",
  TOOLS: "tools",
} as const;

export const getClaimDetailPathByClaimCode = (claimCode?: string) => {
  if (claimCode == null) return PORTAL_PATH.CLAIM_CASE;
  if (claimCode.startsWith("RE") || claimCode.startsWith("DB")) return PORTAL_PATH.HC_CLAIM_CASE;
  if (claimCode.startsWith("MBAL")) return PORTAL_PATH.MBAL_HS_CLAIM_CASE;
  if (claimCode.startsWith("MDR")) return PORTAL_PATH.FWD_MR_CLAIM_CASE;
  if (claimCode.startsWith("CL")) return PORTAL_PATH.SLV_HS_CLAIM_CASE;
  return PORTAL_PATH.CLAIM_CASE;
};
