import { MedicalProviderSelectOptionFragment } from "app/common/components/MedicalProviderSelectOption";
import usePQuery from "contexts/usePQuery";
import { debounce } from "lodash";
import { graphql } from "sdk/v2/graphql";
import { validate as validateUUID } from "uuid";

export const GetMedicalProvidersForHookDocument = graphql(
  `
    query MedicalProvidersForHook($where: mp_medical_providers_bool_exp, $order: [mp_medical_providers_order_by!], $limit: Int = 10, $offset: Int = 0) {
      mp_medical_providers(where: $where, order_by: $order, limit: $limit, offset: $offset) {
        id
        name
        address
        email
        is_direct_billing
        latitude
        longitude
        medical_provider_id
        ...MedicalProviderSelectOption
        phone
        province
        type
        verified
        city {
          id
          name
        }
        medical_provider_mappings {
          id
          company_id
        }
        medical_provider_tpas {
          id
          company {
            id
            name
          }
        }
        medical_provider_type {
          comment
          value
        }
        ward {
          id
          name
          full_name
          district {
            id
            name
            full_name
            city {
              id
              name
              full_name
            }
          }
        }
      }
    }
  `,
  [MedicalProviderSelectOptionFragment],
);

export default function useMedicalProviders({
  keyword,
  limit = 10,
  offset = 0,
  skip = false,
  where: whereInput,
}: {
  keyword?: null | string;
  limit?: number;
  offset?: number;
  skip?: boolean;
  where?: VariablesOf<typeof GetMedicalProvidersForHookDocument>["where"];
} = {}) {
  const where: null | VariablesOf<typeof GetMedicalProvidersForHookDocument>["where"] =
    keyword == null && whereInput == null
      ? null
      : {
          ...(keyword == null
            ? {}
            : {
                _or: [
                  { name: { _ilike: `%${keyword}%` } },
                  {
                    address: {
                      _ilike: `%${keyword}%`,
                    },
                  },
                ],
              }),
          ...whereInput,
        };

  const { data, loading, refetch, ...rest } = usePQuery(GetMedicalProvidersForHookDocument, {
    skip,
    variables: {
      limit,
      offset,
      order: [
        {
          claim_cases_aggregate: {
            count: "desc",
          },
        },
      ],
      where,
    },
  });

  const searchMp = debounce((debouncedKeyword) => {
    refetch({
      where: {
        ...where,
        _or: [
          {
            name: {
              _ilike: `%${debouncedKeyword}%`,
            },
          },
          {
            address: {
              _ilike: `%${debouncedKeyword}%`,
            },
          },
          ...(validateUUID(debouncedKeyword) ? [{ medical_provider_id: { _eq: debouncedKeyword } }] : []),
        ],
      },
    });
  }, 300);

  return {
    data,
    loading,
    refetch,
    searchMp,
    ...rest,
  };
}
