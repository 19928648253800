import { ClaimDocumentsFragment } from "app/portal/screens/ClaimPortal/ClaimCaseScreen";
import { ClaimAlertFragment } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimAlert";
import { ClaimInsurerSignOffFragment } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/InsurerSignOff";
import { ClaimNotesFragment } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/SignOff";
import ClaimInfo2Fragment from "app/portal/screens/ClaimPortal/ClaimContext/graphql/ClaimInfo2Fragment";
import ClaimInfoFragment from "app/portal/screens/ClaimPortal/ClaimContext/graphql/ClaimInfoFragment";
import InsuredCertificateFragment from "app/portal/screens/ClaimPortal/ClaimContext/graphql/InsuredCertificateFragment";
import { ClaimStatusAvailabilityFragment } from "app/portal/screens/ClaimPortal/ClaimContext/useStatusAvailability";
import { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import { graphql as codegenThisGraphqlTag } from "sdk/gql/gql";
import { ClaimDetailDocument } from "sdk/gql/graphql";

const CLAIM_DETAIL_QUERY = ClaimDetailDocument;

const a = codegenThisGraphqlTag(
  /* GraphQL */ `
    query ClaimDetail($claimId: uuid!) {
      claim_cases_by_pk(claim_case_id: $claimId) {
        id
        admission_date
        assessed_plan_balance_id
        bank_id
        benefit_type
        challenge_abuse
        claim_case_id
        claim_number
        ...ClaimAlert
        ...ClaimDetailForTat
        ...ClaimDocuments
        ...ClaimInfo
        ...ClaimInfo2
        ...ClaimInsurerSignOff
        ...ClaimNotes
        ...ClaimStatusAvailability
        code
        diagnosis
        discharge_date
        diseases
        doctor_name
        end_date
        event_date
        first_pending_tat_in_seconds
        genesis_claim_id
        grace_period_end_date
        grace_period_start_date
        insurance_event
        insured_certificate_id
        insured_person_id
        ...InsuredCertificate
        is_accessible
        is_direct_billing
        is_history
        is_jet
        is_stp
        jsonb_copay_mechanism_result
        medical_provider_id
        medical_provider_name
        payment_account_name
        payment_account_number
        physical_examination_date
        quality_assessed_at
        quality_detail
        registered_at
        request_amount
        source
        start_date
        status
        stp_status
        tat_in_seconds
        tatDayHourMin @client
        tatInHourUpToNow @client
        treatment_method
        waiting_remarks
        will_suspend
        updated_at
        created_at
        claim_quality_codes {
          comment
          value
        }
        quality_assessed_by_user {
          id
          name
        }
      }
    }
  `,
  [
    ClaimStatusAvailabilityFragment,
    ClaimInsurerSignOffFragment,
    InsuredCertificateFragment,
    ClaimInfoFragment,
    ClaimInfo2Fragment,
    CLAIM_DETAIL_FOR_TAT_FRAGMENT,
    ClaimNotesFragment,
    ClaimAlertFragment,
    ClaimDocumentsFragment,
  ],
);

export { CLAIM_DETAIL_QUERY };
