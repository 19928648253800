import type { MutationFunction } from "@apollo/client";

import { message, Modal, notification } from "antd";
import { FWD_CLAIM_CASE_SYNC_LOGS } from "app/portal/screens/ClaimPortal/ClaimContext/ClaimContext.gql";
import { SLACK } from "config";
import { COMMON_POLL_INTERVAL } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { add, isAfter } from "date-fns";
import dedent from "dedent";
import usePollInterval from "hooks/usePollInterval";
import utils from "libs/utils";
import { isEqual, sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { graphql } from "sdk/v2/graphql";

type fwdClaimCase = NonNullable<ResultOf<typeof FWD_CLAIM_CASE_SYNC_LOGS>["fwd_claim_cases_by_pk"]>;

const ReRegisterClaimDocument = graphql(`
  mutation ReRegisterClaim($claimId: UUID!, $dataForRegister: RegisterClaimDataInput) {
    mr {
      registerClaimCase(claimId: $claimId, dataForRegister: $dataForRegister)
    }
  }
`);

const DecisionFwdClaimDocument = graphql(`
  mutation DecisionFwdClaim($claimCaseId: UUID!, $body: DecisionCaseInput!) {
    mr {
      decisionCase(claimCaseId: $claimCaseId, decisionCaseInput: $body)
    }
  }
`);

const ConfirmDecisionSuccessDocument = graphql(`
  mutation ConfirmDecisionSuccess($claimCode: String!) {
    mr {
      decisionCallback(decisionInfo: { claim_reference: $claimCode, is_decision: true })
    }
  }
`);

const AdjustFwdClaimDocument = graphql(`
  mutation AdjustFwdClaim($claimCaseId: UUID!, $body: AdjustCaseBody!, $willAutoDecision: Boolean = false) {
    mr {
      adjustCase(claimCaseId: $claimCaseId, adjustCaseBody: $body, willAutoDecision: $willAutoDecision)
    }
  }
`);

type UseFwdReturnType = {
  adjustClaim: (willAutoDecision?: boolean) => void;
  autoAdjustStatus: {
    isAdjustable: boolean;
    reasons: null | string;
  };
  autoDecisionStatus: {
    isDecidable: boolean;
    reasons: string[];
  };
  confirmDecisionSuccess: MutationFunction<ResultOf<typeof ConfirmDecisionSuccessDocument>, VariablesOf<typeof ConfirmDecisionSuccessDocument>>;
  decisionClaim: VoidFunction;
  decisionStatus: {
    isDecidable: boolean;
    reasons: string[];
  };
  getAdjustDisabledText: () => null | string;
  getDecisionDisabledText: () => null | string;
  isAdjustable: boolean;
  isAnyDecisionCallbackSuccess: boolean;
  isAnyDecisionSuccess: boolean;
  latestAdjustCallbackLog?: fwdClaimCase["adjustCallbackLogs"][0];
  latestAdjustLog?: fwdClaimCase["adjustLogs"][0];
  latestDecisionLog?: fwdClaimCase["decisionLogs"][0];
  latestRegisterLog?: fwdClaimCase["registerLogs"][0];
  // medicalProvidersData?: GetFwdMedicalProviderQuery;
  loading: boolean;
  registerClaim: (values: any) => void;
  syncLogs?: ResultOf<typeof FWD_CLAIM_CASE_SYNC_LOGS>;
};

const FwdClaimCaseForUseFwd = graphql(`
  query FwdClaimCaseForUseFwd($claimCaseId: uuid!) {
    claim_cases_by_pk(claim_case_id: $claimCaseId) {
      id
      code
      is_direct_billing
      is_history
      status
      updated_at
      claim_case_beneficiary {
        id
        updated_at
      }
      claim_case_details {
        id
        updated_at
      }
      claim_case_payment {
        id
        actual_paid_amount
      }
      claim_pending_codes {
        id
        status
        updated_at
        fwd_pending_code {
          code
        }
      }
    }
  }
`);

const useFwd = ({ claimCaseId, isDetailFormFilled, isFwdMrClaim }: { claimCaseId?: string; isDetailFormFilled?: boolean; isFwdMrClaim: boolean }): UseFwdReturnType => {
  const { user } = useAuth();
  const { data: fwdClaimCaseForUseFwd } = usePQuery(
    FwdClaimCaseForUseFwd,
    claimCaseId == null || isFwdMrClaim === false
      ? { skip: true }
      : {
          variables: { claimCaseId },
        },
  );
  const claim = fwdClaimCaseForUseFwd?.claim_cases_by_pk;
  const isClaimHistory = claim?.is_history === true;

  const { globalData } = useAuth();
  const claimCaseStatuses = globalData?.claim_case_statuses;

  const pollInterval = usePollInterval(COMMON_POLL_INTERVAL);
  const { data: syncLogs, stopPolling } = usePQuery(
    FWD_CLAIM_CASE_SYNC_LOGS,
    claimCaseId == null || isFwdMrClaim === false
      ? { skip: true }
      : {
          pollInterval,
          skipPollAttempt: () => document.hidden,
          variables: { claimId: claimCaseId },
        },
  );

  const [registerClaimReq, { loading: registering }] = usePMutation(ReRegisterClaimDocument, {
    onCompleted: (data) => {
      if (data.mr?.registerClaimCase == null) return;
      message.success("Đăng ký thành công");
    },
    refetchQueries: [{ query: FWD_CLAIM_CASE_SYNC_LOGS, variables: { claimId: claimCaseId } }],
  });
  const [adjustClaimReq, { loading: adjusting }] = usePMutation(AdjustFwdClaimDocument, {
    onCompleted: (data) => {
      if (data.mr?.adjustCase == null) return;
      message.success("Adjust thành công");
    },
    onError: (error) => {
      switch (error.message) {
        case "not_existed_claim_case": {
          message.error("Yêu cầu bồi thường chưa tồn tại trong hệ thống của công ty bảo hiểm. Vui lòng thử lại sau");
          break;
        }
        case "invalid_medical_provider_id": {
          message.error("Cơ sở y tế không hợp lệ");
          break;
        }
        default: {
          break;
        }
      }
    },
    refetchQueries: [{ query: FWD_CLAIM_CASE_SYNC_LOGS, variables: { claimId: claimCaseId } }],
  });
  const [decisionClaimReq, { loading: deciding }] = usePMutation(DecisionFwdClaimDocument, {
    onCompleted: (data) => {
      if (data.mr?.decisionCase == null) return;
      notification.success({ message: "Đã decision thành công" });
    },
    refetchQueries: [{ query: FWD_CLAIM_CASE_SYNC_LOGS, variables: { claimId: claimCaseId } }],
  });

  const registerClaim = async (values) => {
    if (claimCaseId == null) return;
    await registerClaimReq({
      onCompleted: (data) => {
        if (data.mr?.registerClaimCase == null) return;
        message.success("Đã gửi yêu cầu đăng ký");
      },
      variables: {
        claimId: claimCaseId,
        dataForRegister: {
          eventDate: values.eventDate?.toISOString(),
          isGenNewClaimNumber: values.isGenNewClaimNumber,
          medicalProviderId: values.medicalProviderId,
        },
      },
    });
  };
  const adjustClaim = (willAutoDecision = false) => {
    if (claimCaseId == null) return;
    adjustClaimReq({ variables: { body: {}, claimCaseId, willAutoDecision } });
  };
  const decisionClaim = () => {
    if (claim == null) {
      return;
    }
    if (adjustLogs?.some((item) => item.type === "ADJUST") !== true) {
      Modal.error({ content: "Yêu cầu bồi thường này chưa được đồng bộ với phía công ty bảo hiểm. Vui lòng nhấn nút Adjust để đồng bộ." });
      utils.sendMessageToSlack({
        channel: SLACK.CHANNEL_IDS.LOC,
        message: `Yêu cầu bồi thường ${claim.code} chưa được adjust, không thể tự động decision. Actor: ${user.name}, DecisionDisabledText: ${getDecisionDisabledText()}`,
      });
      return;
    }
    if (claimCaseId == null) return;
    decisionClaimReq({ variables: { body: {}, claimCaseId } });
  };

  const [confirmDecisionSuccess] = usePMutation(ConfirmDecisionSuccessDocument, {
    variables: { claimCode: claim?.code ?? "" },
  });

  const registerLogs = syncLogs?.fwd_claim_cases_by_pk?.registerLogs;
  const registerCallbackLogs = syncLogs?.fwd_claim_cases_by_pk?.registerCallbackLogs;
  const adjustLogs = syncLogs?.fwd_claim_cases_by_pk?.adjustLogs;
  const adjustCallbackLogs = syncLogs?.fwd_claim_cases_by_pk?.adjustCallbackLogs;
  const decisionLogs = syncLogs?.fwd_claim_cases_by_pk?.decisionLogs;
  const decisionCallbackLogs = syncLogs?.fwd_claim_cases_by_pk?.decisionCallbackLogs;

  const latestRegisterLog = registerLogs?.find((log) => log.is_success === true);
  const latestRegisterCallbackLog = registerCallbackLogs?.find((log) => registerLogs?.map((m) => m.request_id).includes(log.request_id) === true);
  const latestAdjustLog = adjustLogs?.find((log) => log.is_success === true);
  const latestAdjustCallbackLog = adjustCallbackLogs?.find((log) => log.request_id === latestAdjustLog?.request_id);
  const willAutoDecision = latestAdjustLog?.will_auto_decision === true;

  const latestDecisionLog = decisionLogs?.[0];

  const isClaimDataAdjusted = useMemo(() => {
    if (claim == null || latestAdjustLog?.is_success !== true || latestAdjustCallbackLog?.is_success !== true) {
      return {
        reasons: [],
        result: false,
      };
    }

    const reasons: string[] = [];
    const latestAdjustedAt = new Date(latestAdjustLog.created_at);
    const isClaimDetailsAdjusted = claim.claim_case_details.every((claimDetail) => isAfter(latestAdjustedAt, new Date(claimDetail.updated_at)));
    const isClaimPendingCodeAdjusted = claim.claim_pending_codes.every((pendingCode) => isAfter(latestAdjustedAt, new Date(pendingCode.updated_at)));
    const isClaimBeneficiaryAdjusted = claim.claim_case_beneficiary?.updated_at == null ? true : isAfter(latestAdjustedAt, new Date(claim.claim_case_beneficiary.updated_at));
    const isClaimAdjusted = isAfter(add(latestAdjustedAt, { seconds: 10 }), new Date(claim.updated_at));

    if (isClaimAdjusted === false) {
      reasons.push(`Lần gần nhất adjust (${utils.formatDate(latestAdjustedAt)}) không sau lần cập nhật gần nhất của YCBT (${utils.formatDate(claim.updated_at)})`);
    }

    return {
      reasons,
      result: isClaimAdjusted && isClaimDetailsAdjusted && isClaimPendingCodeAdjusted && isClaimBeneficiaryAdjusted,
    };
  }, [claim, latestAdjustCallbackLog?.is_success, latestAdjustLog?.created_at, latestAdjustLog?.is_success]);

  const isRegistered = latestRegisterCallbackLog?.is_success === true;
  const isAnyDecisionSuccess = useMemo(() => decisionLogs?.some((log) => log.is_success === true) === true, [decisionLogs]);
  const isAnyDecisionCallbackSuccess = useMemo(() => decisionCallbackLogs?.some((log) => log.is_success === true) === true, [decisionCallbackLogs]);
  const isAtLeastOneAdjustCallbackSuccess = useMemo(() => adjustCallbackLogs?.some((log) => log.is_success === true) === true, [adjustCallbackLogs]);
  const isStatusValidToDecision =
    ["Approved", "Declined", "Suspension"].includes(claim?.status ?? "") || (claim?.is_direct_billing === true && ["ApprovedReceivedDoc"].includes(claim.status));
  const isAutoAdjustInProgress = latestAdjustLog != null && latestAdjustCallbackLog == null;

  if (isAnyDecisionCallbackSuccess) {
    stopPolling();
  }

  const isAdjustable = useMemo(() => {
    if (claim == null || adjusting || isClaimHistory) {
      return false;
    }

    return isRegistered && !isClaimDataAdjusted.result && isDetailFormFilled && !isAnyDecisionSuccess && !isAutoAdjustInProgress;
  }, [adjusting, claim, isAnyDecisionSuccess, isAutoAdjustInProgress, isClaimDataAdjusted.result, isClaimHistory, isDetailFormFilled, isRegistered]);

  const getAdjustDisabledText = useCallback(() => {
    if (!isRegistered) {
      return "Claim chưa đăng ký thành công";
    }
    if (isClaimDataAdjusted.result) {
      return "Claim data mới nhất đã được adjust";
    }
    if (!isDetailFormFilled) {
      return "Claim chưa điền đủ thông tin";
    }
    if (isAnyDecisionSuccess) {
      return "Claim đã được decision";
    }
    if (isAutoAdjustInProgress) {
      return "Đang chờ kết quả adjust";
    }

    return null;
  }, [isAnyDecisionSuccess, isAutoAdjustInProgress, isClaimDataAdjusted.result, isDetailFormFilled, isRegistered]);

  const decisionStatus = useMemo(() => {
    if (claim == null || deciding || isClaimHistory) {
      return { isDecidable: false, reasons: [] };
    }

    if (claim.status === "Suspension" && claim.claim_pending_codes.some((i) => i.status === "O")) {
      return { isDecidable: false, reasons: ["Không decision được do claim đang ở trạng thái Suspension và có Pending code chưa gỡ."] };
    }

    if (willAutoDecision && !isAnyDecisionSuccess) {
      return { isDecidable: false, reasons: ["Đang chờ auto decision"] };
    }

    const reasons: string[] = [];

    if (latestAdjustCallbackLog != null) {
      if (latestAdjustCallbackLog.data.amount !== claim.claim_case_payment?.actual_paid_amount) {
        // temp fix: disable this check until save field booking_amount
        // reasons.push(
        // eslint-disable-next-line max-len
        //   `Tổng tiền yêu cầu giữa hệ thống Papaya (${claim.claim_case_payment?.requested_amount}) và FWD ghi nhận (${latestAdjustCallbackLog.data.amount}) đang không trùng khớp`,
        // );
      }

      const ppyPendingCodes = claim.claim_pending_codes.map((i) => ({ followUpCode: i.fwd_pending_code?.code, followUpStatus: i.status }));

      if (
        isEqual(
          sortBy(
            ppyPendingCodes.map((i) => ({ ...i, order: `${i.followUpCode}-${i.followUpStatus}` })),
            "order",
          ),
          sortBy(
            latestAdjustCallbackLog.data.claim_pendings.map((i) => ({ ...i, order: `${i.followUpCode}-${i.followUpStatus}` })),
            ["order"],
          ),
        ) === false
      ) {
        const pendingTexts = latestAdjustCallbackLog.data.claim_pendings?.map((i) => `${i.followUpCode} - ${i.followUpStatus}`);
        reasons.push(
          `Pending code/status giữa hệ thống Papaya (${ppyPendingCodes.map(
            (i) => `${i.followUpCode} - ${i.followUpStatus}`,
          )}) và FWD ghi nhận ${pendingTexts} đang không trùng khớp`,
        );
      }
    }
    return { isDecidable: isAtLeastOneAdjustCallbackSuccess && isStatusValidToDecision && !isAnyDecisionSuccess && isClaimDataAdjusted.result && reasons.length === 0, reasons };
  }, [
    claim,
    deciding,
    isAnyDecisionSuccess,
    isAtLeastOneAdjustCallbackSuccess,
    isClaimDataAdjusted.result,
    isClaimHistory,
    isStatusValidToDecision,
    latestAdjustCallbackLog,
    willAutoDecision,
  ]);

  const autoDecisionStatus = useMemo(() => {
    if (claim == null || deciding || isClaimHistory) {
      return { isDecidable: false, reasons: [] };
    }

    if (isAtLeastOneAdjustCallbackSuccess === false) {
      // return { isDecidable: false, reasons: ["Chưa có adjust call back nào thành công"] };
    }

    if (isAnyDecisionSuccess === true) {
      return { isDecidable: false, reasons: ["Đã decision qua FWD thành công"] };
    }

    if (isClaimDataAdjusted.result === false) {
      // return { isDecidable: false, reasons: ["Có cập nhật mới cần adjust qua FWD. Vui lòng Adjust trước khi gửi yêu cầu ra quyết định"] };
    }
    if (claim.claim_pending_codes.some((i) => i.status === "O")) {
      return { isDecidable: false, reasons: ["Không decision được do claim đang ở trạng thái Suspension và có Pending code chưa gỡ."] };
    }

    const reasons: string[] = [];

    if (latestAdjustCallbackLog != null) {
      if (latestAdjustCallbackLog.data.amount !== claim.claim_case_payment?.actual_paid_amount) {
        // temp fix: disable this check until save field booking_amount
        // reasons.push(
        // eslint-disable-next-line max-len
        //   `Tổng tiền yêu cầu giữa hệ thống Papaya (${claim.claim_case_payment?.requested_amount}) và FWD ghi nhận (${latestAdjustCallbackLog.data.amount}) đang không trùng khớp`,
        // );
      }

      const ppyPendingCodes = claim.claim_pending_codes.map((i) => ({ followUpCode: i.fwd_pending_code?.code, followUpStatus: i.status }));

      if (
        isEqual(
          sortBy(
            ppyPendingCodes.map((i) => ({ ...i, order: `${i.followUpCode}-${i.followUpStatus}` })),
            "order",
          ),
          sortBy(
            latestAdjustCallbackLog.data.claim_pendings.map((i) => ({ ...i, order: `${i.followUpCode}-${i.followUpStatus}` })),
            ["order"],
          ),
        ) === false
      ) {
        const pendingTexts = latestAdjustCallbackLog.data.claim_pendings?.map((i) => `${i.followUpCode} - ${i.followUpStatus}`);
        // reasons.push(
        //   `Pending code/status giữa hệ thống Papaya (${ppyPendingCodes.map(
        //     (i) => `${i.followUpCode} - ${i.followUpStatus}`,
        //   )}) và FWD ghi nhận ${pendingTexts} đang không trùng khớp`,
        // );
      }
    }

    return { isDecidable: reasons.length === 0, reasons };
  }, [claim, deciding, isAnyDecisionSuccess, isAtLeastOneAdjustCallbackSuccess, isClaimDataAdjusted, isClaimHistory, latestAdjustCallbackLog]);

  const getDecisionDisabledText = useCallback(() => {
    if (!isAtLeastOneAdjustCallbackSuccess) return "Chưa có adjust call back nào thành công.";
    if (!isStatusValidToDecision) {
      return `Để gửi yêu cầu ra quyết định thì trạng thái của YCBT phải thuộc một trong các trạng thái sau: ${(claimCaseStatuses ?? [])
        .filter((item) => ["Approved", "ApprovedReceivedDoc", "Declined", "Suspension"].includes(item.value))
        .map((item) => item.comment)
        .join(", ")}.`;
    }
    if (claim?.status === "Suspension" && claim.claim_pending_codes.some((i) => i.status === "O")) {
      return "Không decision được do claim đang ở trạng thái Suspension và có Pending code chưa gỡ.";
    }
    if (isAnyDecisionSuccess) return "Đã có một lần decision thành công, không thể decision lại.";
    if (!isClaimDataAdjusted.result) {
      return dedent`
        Có data mới chưa được sync, vui lòng Adjust trước khi gửi yêu cầu ra quyết định.

            ${isClaimDataAdjusted.reasons.length > 0 ? `Lý do: ${isClaimDataAdjusted.reasons.join(", ")}` : ""}
      `;
    }
    if (willAutoDecision) return "Đang chờ auto decision";

    return null;
  }, [
    willAutoDecision,
    isAtLeastOneAdjustCallbackSuccess,
    isStatusValidToDecision,
    claim?.status,
    claim?.claim_pending_codes,
    isAnyDecisionSuccess,
    isClaimDataAdjusted.result,
    isClaimDataAdjusted.reasons,
    claimCaseStatuses,
  ]);

  return {
    adjustClaim,
    autoAdjustStatus: {
      isAdjustable,
      reasons: getAdjustDisabledText(),
    },
    autoDecisionStatus,
    confirmDecisionSuccess,
    decisionClaim,
    decisionStatus,
    getAdjustDisabledText,
    getDecisionDisabledText,
    isAdjustable,
    isAnyDecisionCallbackSuccess,
    isAnyDecisionSuccess,
    latestAdjustCallbackLog,
    latestAdjustLog,
    latestDecisionLog,
    latestRegisterLog,
    // medicalProvidersData,
    loading: registering || adjusting || deciding,
    registerClaim,
    syncLogs,
  };
};

export default useFwd;
