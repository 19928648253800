import type { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import type { JSX } from "react";

import { ApolloProvider } from "@apollo/client";
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useInterval } from "ahooks";
import { App as AntApp, ConfigProvider, message, Skeleton, Spin } from "antd";
import viVN from "antd/locale/vi_VN";
import NoPermissionScreen from "app/common/screens/403NoPermissionScreen";
import { GOOGLE_CLIENT_ID } from "config";
import { msalConfig } from "config/auth/azureAuthConfig";
import { FORMATTER, LOCAL_STORAGE_KEYS, THEMES } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { AuthProvider } from "contexts/AuthProvider";
import useIP from "hooks/useIP";
import { saveTraceLog, sendTraceLog } from "libs/track";
import utils from "libs/utils";
import { Suspense, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Rive from "rive-react";
import createApolloClient from "services/apolloClientServices";

import "./App.css";
import ErrorBoundary from "./common/components/ErrorBoundary";
import NotFoundScreen from "./common/screens/404NotFoundScreen";
import LoginScreen from "./common/screens/LoginScreen";
import MaintenanceScreen from "./common/screens/MaintenanceScreen";
import "./globals.css";
import routes, { ROUTES_PATH } from "./routes";

const msalInstance = new PublicClientApplication(msalConfig);

if (import.meta.env.DEV) {
  loadDevMessages();
  loadErrorMessages();
}
if (import.meta.env.VITE_DISABLE_FIREBASE !== "true") {
  import("firebase");
}

message.config({
  duration: 2,
  maxCount: 3,
  top: 10,
});

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { hasRole, token, user } = useAuth();
  const { ip } = useIP();
  if (!hasRole(["MedicalProviderEmployee"])) {
    setInterval(() => {
      Spin.setDefaultIndicator(
        <div className="globalLoading">
          <Rive src="/logo-animate.riv" />
          <div>{utils.getRandomText()}</div>
        </div>,
      );
    }, 10_000);
  }
  const location = useLocation();

  useInterval(
    () => {
      sendTraceLog();
    },
    60_000,
    { immediate: true },
  );

  useEffect(() => {
    if (ip == null) return;
    saveTraceLog({
      data: {
        data: JSON.stringify(user),
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        email: user?.email,
        ip,
        time: utils.formatDate(new Date(), FORMATTER.API_DATE_FORMAT),
        url: `${window.location.origin}${location.pathname}/${location.search}${location.hash}`,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        user_id: user?.id,
      },
      topic: "access-log-topic",
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  }, [ip, location, user, user?.id, user?.name]);

  if (import.meta.hot) {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN) == null) {
      return <Navigate replace state={{ from: location }} to="/login" />;
    }
    // unless not logged in, returning this prevents going back to /login on HMR
    // this causes logging out in dev mode impossible
    return children;
  }
  if (token == null) {
    return <Navigate replace state={{ from: location }} to="/login" />;
  }
  if (!hasRole(["MedicalProviderEmployee", "TenantAdmin"])) {
    return <NoPermissionScreen />;
  }

  return children;
};

const InvalidRouteGuard = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) return <Navigate replace to="/" />;
  return children;
};

Spin.setDefaultIndicator(
  <div className="globalLoading">
    <Rive src="/logo-animate.riv" />
    <div>{utils.getRandomText()}</div>
  </div>,
);

const AppRoutes = () => {
  const allRoutes = routes.map((route) => (
    <Route
      element={
        route.requireAuth === true ? (
          <RequireAuth>
            <route.comp />
          </RequireAuth>
        ) : (
          <route.comp />
        )
      }
      errorElement={<ErrorBoundary />}
      key={route.path}
      path={route.path}
    />
  ));

  allRoutes.push(
    <Route
      element={
        <InvalidRouteGuard>
          <LoginScreen />
        </InvalidRouteGuard>
      }
      errorElement={<ErrorBoundary />}
      key="login"
      path={ROUTES_PATH.LOGIN_SCREEN}
    />,
  );

  return allRoutes;
};

const App = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [isTranslate, setIsTranslate] = useState(false);

  useEffect(() => {
    const init = async () => {
      setClient(await createApolloClient());
    };
    init();
  }, []);

  useEffect(() => {
    document.documentElement.dataset.theme = localStorage.getItem("theme") ?? THEMES.LIGHT_THEME;
  }, []);

  if (!client) {
    return null;
  }

  const observer = new MutationObserver(() => {
    if (document.documentElement.className.match("translated")) {
      setIsTranslate(true);
    }
  });

  observer.observe(document.documentElement, {
    attributeFilter: ["class"],
    attributes: true,
    characterData: false,
    childList: false,
  });

  return (
    <HelmetProvider>
      <MsalProvider instance={msalInstance}>
        <ApolloProvider client={client}>
          {import.meta.env.DEV && (
            <Helmet>
              <script crossOrigin="anonymous" src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.5/lodash.js" type="application/javascript" />
              <script src="/initDateFns.js" />
            </Helmet>
          )}
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <ConfigProvider locale={viVN}>
              <Suspense fallback={<Skeleton />}>
                <AuthProvider apolloClient={client}>
                  <AntApp>
                    {isTranslate && (
                      <div
                        style={{
                          background: "red",
                          color: "yellow",
                          fontSize: 30,
                          margin: "auto",
                          textAlign: "center",
                        }}
                      >
                        Bạn đang dùng Google Translate để dịch trang. Việc này có thể gây lỗi khi tạo yêu cầu bồi thường, vui lòng Dịch hoặc dùng trình duyệt khác
                      </div>
                    )}
                    <Routes>
                      {AppRoutes()}
                      <Route element={<MaintenanceScreen />} path="/maintenance" />
                      <Route element={<NotFoundScreen />} path="*" />
                    </Routes>
                  </AntApp>
                </AuthProvider>
              </Suspense>
            </ConfigProvider>
          </GoogleOAuthProvider>
        </ApolloProvider>
      </MsalProvider>
    </HelmetProvider>
  );
};

export default App;
