import usePQuery from "contexts/usePQuery";
import { debounce } from "lodash";
import { graphql } from "sdk/v2/graphql";

export const CompaniesForUseCompaniesHookDocument = graphql(`
  query CompaniesForUseCompaniesHook($where: companies_bool_exp, $limit: Int = 10) {
    companies(where: $where, order_by: { name: asc }, limit: $limit) {
      id
      name
      company_id
      companyCode
      logo
      updated_at
      created_at
      company_roles {
        id
        role
      }
      corporate_policies {
        id
        dued_at
        issued_at
        policy_number
      }
    }
  }
`);

type VariableType = VariablesOf<typeof CompaniesForUseCompaniesHookDocument>;
export default function useCompanies({ limit = 10, types = [], where: whereInput }: { limit?: number; types?: CompanyRoleTypesEnum[]; where?: VariableType["where"] } = {}) {
  let where: null | VariableType["where"] = null;
  if (types.length > 0) {
    where = {
      company_roles: { role: { _in: types } },
    };
  }

  const ors: VariableType["where"][] = [];

  if (where != null) {
    // ors.push(where);
  }
  if (whereInput != null) {
    ors.push(whereInput);
  }

  const { data, loading, refetch, ...rest } = usePQuery(CompaniesForUseCompaniesHookDocument, {
    variables: {
      limit,
      where: {
        ...where,
        _or: ors.length > 0 ? ors.filter(Boolean) : {},
      },
    },
  });

  const search = debounce(
    (keyword) =>
      refetch({
        where: {
          ...where,
          _or: [
            {
              name: { _ilike: `%${keyword.trim()}%` },
            },
          ],
        },
      }),
    200,
  );

  return {
    data,
    loading,
    refetch,
    search,
    ...rest,
  };
}
