import type { CSSProperties, ReactNode } from "react";

import { Badge, Space, Tooltip } from "antd";
import { isGreaterThanZero } from "libs/hidash";

const RibbonedClaimDocument = ({ children, claimDocument, style }: { children: ReactNode; claimDocument: any; style?: CSSProperties }) => {
  let ribbonedImage = children;
  if (claimDocument?.claim_pending_code != null) {
    ribbonedImage = (
      <Badge.Ribbon color="magenta" text={<Tooltip title={claimDocument.claim_pending_code.extended_text}>{claimDocument.claim_pending_code.code}</Tooltip>}>
        {ribbonedImage}
      </Badge.Ribbon>
    );
  } else if (claimDocument?.is_supplemented === false) {
    ribbonedImage = (
      <Badge.Ribbon color="cyan" text="Nộp lần đầu">
        {ribbonedImage}
      </Badge.Ribbon>
    );
  } else if (claimDocument?.is_supplemented === true) {
    ribbonedImage = (
      <Badge.Ribbon color="gold" text="Nộp bổ sung">
        {ribbonedImage}
      </Badge.Ribbon>
    );
  }
  if (isGreaterThanZero(claimDocument?.assessed_claim_pending_codes)) {
    ribbonedImage = (
      <Badge.Ribbon
        color="orange"
        placement="start"
        style={style}
        text={
          <Space>
            {claimDocument?.assessed_claim_pending_codes?.map((pending) => (
              <Tooltip key={pending.id} title={pending.extended_text}>
                {pending.code}
              </Tooltip>
            ))}
          </Space>
        }
      >
        {ribbonedImage}
      </Badge.Ribbon>
    );
  }

  return ribbonedImage;
};

export default RibbonedClaimDocument;
