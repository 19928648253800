import type { ClaimStatus } from "app/portal/screens/ClaimPortal/ClaimContext/types";

import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import useFwd from "app/portal/screens/ClaimPortal/ClaimContext/useFwd";
import { CLAIM_DECISION_STATUSES } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { differenceInCalendarDays, differenceInDays } from "date-fns";
import { getZeroOrNumber, isGreaterThanZero } from "libs/hidash";
import utils from "libs/utils";
import { includes, isEmpty, maxBy } from "lodash";
import { useCallback, useMemo } from "react";
import { graphql as codegenThisGraphqlTag, readFragment } from "sdk/v2/graphql";

export const ClaimStatusAvailabilityFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimStatusAvailability on claim_cases {
    grace_period_start_date
    is_direct_billing
    status
    created_at
    assessment_explanation: claim_notes(where: { type: { _eq: ASSESSMENT_EXPLANATION } }, order_by: { created_at: desc }, limit: 1) {
      id
      content
    }
    claim_case_beneficiary {
      bank_account_number
      bank_id
      payment_method
      premium_policy_number
    }
    claim_case_payment {
      actual_paid_amount
      custom_amount
      initial_premium_debt_amount
      is_partial_paid
      requested_amount
    }
    claim_status_availability_action_logs: action_logs {
      new_value
      old_value
      created_at
    }
    claim_status_availability_assessed_diagnoses: claim_case_assessed_diagnoses {
      id
    }
    claim_status_availability_pending_codes: claim_pending_codes {
      status
      fwd_pending_code {
        code
      }
      pending_code {
        code
        internal
      }
    }
    fwd_claim_case {
      id
    }
    insured_certificate {
      status
      policy {
        policy_setting {
          original_docs_provision_max_tat
        }
      }
    }
    medical_provider {
      medical_provider_id
    }
    share_to_insurer_claim_notes: claim_notes(where: { share_to_insurer: { _eq: true }, type: { _eq: SignOff } }, order_by: { created_at: asc }) {
      id
      share_to_insurer
    }
  }
`);

const valueToComment = (value: ClaimStatus, statusTypeOption?: { comment: null | string; value: string }[]) => {
  const found = statusTypeOption?.find((op) => op.value === value);
  return found?.comment ?? value;
};

const REQUIRE_SHARE_TO_INSURER_CLAIM_NOTE_STATUSES: ClaimStatus[] = ["Approved", "Declined", "Pending"];

const useStatusAvailability = () => {
  const { globalData, hasRole, user } = useAuth();
  const { claim, claimType, isDetailFormFilled, singleShareToInsurerSignOffFeature } = useClaim();
  const { isAnyDecisionSuccess } = useFwd({ claimCaseId: claim?.id, isDetailFormFilled, isFwdMrClaim: claimType.fwdMr });
  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimStatusAvailabilityFragment, claim) as ResultOf<typeof ClaimStatusAvailabilityFragment>;
  })();

  const statusTypeOption = globalData?.claim_case_statuses;
  const canMakeDecision = useMemo(() => {
    const mrDecisionLimit = user.user_setting?.fwd_mr_decision_limit ?? 0;
    const healthcareDecisionLimit = user.user_setting?.healthcare_decision_limit ?? 0;
    const mbalHsDecisionLimit = user.user_setting?.mbal_hs_decision_limit ?? 0;
    const slvHsDecisionLimit = user.user_setting?.slv_hs_decision_limit ?? 0;

    const actualPaidAmount = claimFragmentData?.claim_case_payment?.actual_paid_amount ?? 0;
    let result = false;
    let limit = 0;
    if (claimType.fwdMr) {
      limit = mrDecisionLimit;
      if (actualPaidAmount <= mrDecisionLimit) {
        result = true;
      }
    }
    if (claimType.healthCare) {
      limit = healthcareDecisionLimit;
      if (actualPaidAmount <= healthcareDecisionLimit) {
        result = true;
      }
    }
    if (claimType.mbalHs) {
      limit = mbalHsDecisionLimit;
      if (actualPaidAmount <= mbalHsDecisionLimit) {
        result = true;
      }
    }
    if (claimType.slvHs) {
      limit = slvHsDecisionLimit;
      if (actualPaidAmount <= slvHsDecisionLimit) {
        result = true;
      }
    }
    return { limit, result };
  }, [
    claimFragmentData?.claim_case_payment?.actual_paid_amount,
    claimType.fwdMr,
    claimType.healthCare,
    claimType.mbalHs,
    claimType.slvHs,
    user.user_setting?.fwd_mr_decision_limit,
    user.user_setting?.healthcare_decision_limit,
    user.user_setting?.mbal_hs_decision_limit,
    user.user_setting?.slv_hs_decision_limit,
  ]);

  const assessedAmount = getZeroOrNumber(claimFragmentData?.claim_case_payment?.actual_paid_amount);
  const requestAmount = getZeroOrNumber(claimFragmentData?.claim_case_payment?.requested_amount);
  const isIcdAssessed = isGreaterThanZero(claimFragmentData?.claim_status_availability_assessed_diagnoses.length);
  const isMedicalProviderAssessed = claimFragmentData?.medical_provider?.medical_provider_id != null;
  const hasOutstandingPendingCode = claimFragmentData?.claim_status_availability_pending_codes.some(({ status: pendingStatus }) => pendingStatus === "O") ?? false;
  const canApprovedWaitingForGracePeriodEndMbalClaim =
    claimFragmentData?.status === "APPROVED_WAITING_FOR_GRADE_PERIOD_END" &&
    claimType.mbalHs === true &&
    (claimFragmentData.claim_case_beneficiary?.payment_method === "DPCHD" ||
      (claimFragmentData.claim_case_payment?.initial_premium_debt_amount ?? 0) === 0 ||
      claimFragmentData.insured_certificate.status === "LAPSED" ||
      claimFragmentData.insured_certificate.status === "REVERSED" ||
      claimFragmentData.grace_period_start_date == null);
  const canApprovedAppliedPremiumIncrementAmountMbalClaim = claimType.mbalHs === true && isGreaterThanZero(claimFragmentData?.claim_case_payment?.custom_amount) === true;

  const daysSinceApprovedAwaitingDocs = useMemo(() => {
    if (claimFragmentData == null) return 0;
    const approvedAwaitingDocsLog = claimFragmentData.claim_status_availability_action_logs.find((log) => log.new_value === "ApprovedWaitingDoc");
    if (approvedAwaitingDocsLog == null) return 0;
    const approvedAwaitingDocsDate = new Date(approvedAwaitingDocsLog.created_at);
    return differenceInDays(new Date(), approvedAwaitingDocsDate);
  }, [claimFragmentData]);

  const buildDbStatus = useCallback(
    (nextStatus: ClaimStatus) => {
      if (claimFragmentData == null) {
        return { disabled: true };
      }
      const currentStatus = claimFragmentData.status;
      const hasApprovedWaitingDoc = claimFragmentData.claim_status_availability_action_logs.some((n) => n.new_value === "ApprovedWaitingDoc");
      if (claimFragmentData.status === "Paid") {
        return { disabled: true, toolTip: "Không được thay đổi trạng thái vì đã thanh toán" };
      }
      if (isAnyDecisionSuccess) {
        return { disabled: true, toolTip: "Không thể thay đổi trạng thái vì đã có quyết định thành công qua FWD" };
      }
      switch (nextStatus) {
        case "Paid": {
          if (hasRole(["ClaimAssessor", "ClaimAssessorManager"]) && !hasRole(["DEPARTMENT_HEAD"])) {
            return { disabled: true, toolTip: "Chỉ Ops có thể cập nhật thanh toán" };
          }
          break;
        }

        case "Suspension":
        case "AWAITING_CONTRACT_COMPLETION":
        case "RECEIVED_DOCS":
        case "NEW_DOCUMENT_ON_SUSPENDED":
        case "Initialize":
        case "PRESENT_CASE_COMPLETED":
        case "InsurerAssessed":
        case "Updated": {
          if (currentStatus === "InProgress") {
            return { disabled: true };
          }
          break;
        }

        case "Declined": {
          if (claimType.fwdMr && isEmpty(claimFragmentData.assessment_explanation)) {
            return { disabled: true, toolTip: "Chưa có trích dẫn điều khoản" };
          }
          if (assessedAmount > 0) return { disabled: true, toolTip: "Không thể từ chối: Đã có số tiền bồi thường" };
          if (!isIcdAssessed) return { disabled: true, toolTip: "Không thể từ chối: Phải xác định ICD" };
          if (hasOutstandingPendingCode) {
            return { disabled: true, toolTip: "Không thể từ chối: có Pending chưa giải quyết" };
          }
          break;
        }

        case "Approved":
        case "ApprovedReceivedDoc":
        case "ApprovedWaitingDoc":
        case "APPROVED_WAITING_FOR_GRADE_PERIOD_END": {
          if (claimType.fwdMr && isEmpty(claimFragmentData.assessment_explanation) && claimFragmentData.claim_case_payment?.is_partial_paid === true) {
            return { disabled: true, toolTip: "Chưa có trích dẫn điều khoản cho ca chi trả một phần" };
          }
          if (!hasApprovedWaitingDoc && nextStatus === "ApprovedReceivedDoc") {
            return { disabled: true, toolTip: "Phải cập nhật trạng thái chấp thuận - chờ hồ sơ gốc trước" };
          }
          const hasAssessedAmount = assessedAmount > 0;
          if (!hasAssessedAmount) return { disabled: true, toolTip: "Không thể chấp thuận: Số tiền chi trả bằng 0" };
          if (hasOutstandingPendingCode) {
            return { disabled: true, toolTip: "Không thể chấp thuận: có Pending chưa giải quyết" };
          }
          if (!isIcdAssessed) return { disabled: true, toolTip: "Không thể chấp thuận: chưa nhập ICD" };
          if (!isMedicalProviderAssessed) {
            return { disabled: true, toolTip: "Không thể chấp thuận: chưa nhập cơ sở y tế" };
          }
          break;
        }

        case "Pending": {
          if (!hasOutstandingPendingCode) {
            return {
              disabled: true,
              toolTip: "Không thể yêu cầu bổ sung hồ sơ: Cần ít nhất 1 Pending Code chưa giải quyết",
            };
          }

          return { disabled: false };
        }

        case "Waiting":
        default: {
          return {
            disabled: false,
          };
        }
      }
      return { disabled: false };
    },
    [assessedAmount, claimFragmentData, claimType.fwdMr, hasOutstandingPendingCode, hasRole, isAnyDecisionSuccess, isIcdAssessed, isMedicalProviderAssessed],
  );

  const previousStatusOfWaitingSlvClaim = useMemo(
    () =>
      maxBy(
        claimFragmentData?.claim_status_availability_action_logs.filter((log) => log.new_value === "Waiting"),
        (log) => new Date(log.created_at),
      )?.old_value,
    [claimFragmentData?.claim_status_availability_action_logs],
  );

  return useCallback(
    (nextStatus: ClaimStatus) => {
      if (claimFragmentData == null) {
        return { disabled: true };
      }

      const currentStatus = claimFragmentData.status;
      const pendingHistoryCount = claimFragmentData.claim_status_availability_action_logs.filter((log) => log.new_value === "Pending").length;
      if (currentStatus === "Paid" && !hasRole(["DEPARTMENT_HEAD"])) {
        return { disabled: true, toolTip: "Không được thay đổi trạng thái vì đã thanh toán" };
      }

      if (nextStatus === currentStatus) return { disabled: true, toolTip: "Trạng thái hiện tại" };

      if (
        singleShareToInsurerSignOffFeature === true &&
        REQUIRE_SHARE_TO_INSURER_CLAIM_NOTE_STATUSES.includes(nextStatus) === true &&
        isGreaterThanZero(claimFragmentData.share_to_insurer_claim_notes) === false
      ) {
        return {
          disabled: true,
          toolTip: `Chỉ có thể chuyển trạng thái ${REQUIRE_SHARE_TO_INSURER_CLAIM_NOTE_STATUSES.map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
            .filter(Boolean)
            .join("/")} khi đã có sign off cho công ty bảo hiểm`,
        };
      }

      if (claimType.slvHs === true && currentStatus === "Waiting" && nextStatus === previousStatusOfWaitingSlvClaim) {
        return { disabled: false };
      }

      if (nextStatus === "Halted") {
        return {
          disabled: false,
        };
      }
      if (!isDetailFormFilled && !["Cancelled", "InProgress"].includes(nextStatus)) {
        if (currentStatus === "InProgress" && nextStatus === "AWAITING_CONTRACT_COMPLETION") {
          return { disabled: false };
        }
        return { disabled: true, toolTip: "Thông tin điều trị chưa có" };
      }
      if (nextStatus === "INVESTIGATING") {
        if (claimType.fwdMr) {
          const FWD_INVESTIGATING_PENDING_CODES = new Set(["P01", "P02", "P03", "P04", "P05", "P30"]);
          const openPendingCodes = claimFragmentData.claim_status_availability_pending_codes.filter((pendingCode) => pendingCode.status === "O");
          const onlyHaveInvestigatingPendingCodes =
            openPendingCodes.length > 0 && openPendingCodes.every((pendingCode) => FWD_INVESTIGATING_PENDING_CODES.has(pendingCode.fwd_pending_code?.code ?? "")) === true;

          if (onlyHaveInvestigatingPendingCodes === false) {
            return {
              disabled: true,
              toolTip: "Không thể sang Chờ kết quả xác minh thông tin vì không thoã điều kiện chỉ có pending code từ P01 tới P05 đang mở",
            };
          }
        }

        if (claimType.slvHs) {
          const SLV_INVESTIGATING_PENDING_CODES = new Set(["P11", "P17", "P19"]);
          const hasSlvInvestigationPendingCodes = claimFragmentData.claim_status_availability_pending_codes.some(
            (pendingCode) => SLV_INVESTIGATING_PENDING_CODES.has(pendingCode.pending_code.code) && pendingCode.status === "O",
          );

          if (hasSlvInvestigationPendingCodes === false) {
            return {
              disabled: true,
              toolTip: `Không thể chuyển sang Chờ kết quả xác minh thông tin vì không thoã điều kiện có 1 trong các pending code ${[...SLV_INVESTIGATING_PENDING_CODES].join(", ")} đang mở`,
            };
          }
        }

        return {
          disabled: false,
        };
      }

      if (nextStatus === "AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING") {
        return {
          disabled: true,
          toolTip: "Chỉ hệ thống mới chuyển trạng thái này được",
        };
      }
      if (claim?.is_direct_billing === true) return buildDbStatus(nextStatus);
      if (currentStatus === "Halted") {
        return {
          disabled: true,
          toolTip: "Không thể thay đổi trạng thái vì đã tạm dừng",
        };
      }
      const allowedNextStatusesForInitialize: ClaimStatus[] = ["Cancelled", "CANCELLED_BY_LA_REQUEST", "InProgress"];
      if (currentStatus === "Initialize" && allowedNextStatusesForInitialize.includes(nextStatus) === false) {
        return { disabled: true, toolTip: "Chỉ có thể đổi sang trạng thái Đang thẩm định/Hủy" };
      }
      const allowedNextStatusesForWaiting: ClaimStatus[] = ["InsurerAssessed", "INVESTIGATING", "PRESENT_CASE_COMPLETED"];
      if (currentStatus === "Waiting" && allowedNextStatusesForWaiting.includes(nextStatus) === false) {
        return {
          disabled: true,
          toolTip: `Chỉ có thể đổi sang trạng thái ${allowedNextStatusesForWaiting
            .map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
            .filter(Boolean)
            .join("/")}
          `,
        };
      }
      const allowedNextStatusesForPending: ClaimStatus[] = [
        "Cancelled",
        "CANCELLED_BY_LA_REQUEST",
        "Declined",
        "INVESTIGATING",
        "SUPPLEMENTED_VERIFIED",
        "Suspension",
        "Updated",
        "Waiting",
      ];
      if (currentStatus === "Pending") {
        if (allowedNextStatusesForPending.includes(nextStatus) === false) {
          return {
            disabled: true,
            toolTip: `Chỉ có thể đổi sang trạng thái ${allowedNextStatusesForPending
              .map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
              .filter(Boolean)
              .join("/")}
          `,
          };
        }
        if (claimType.slvHs && nextStatus === "SUPPLEMENTED_VERIFIED" && claimFragmentData.claim_status_availability_pending_codes.some((i) => i.status === "O")) {
          return {
            disabled: true,
            toolTip: "Không thể chuyển sang trạng thái Xác nhận hoàn tất bổ sung khi còn pending chưa giải quyết",
          };
        }
      }
      const allowedNextStatusesForSuspension = (() => {
        if (claimType.mbalHs) {
          return new Set<ClaimStatus>(["INVESTIGATING", "Updated", "Waiting"]);
        }
        if (claimType.healthCare === true || claimType.fwdMr === true) {
          return new Set<ClaimStatus>(["NEW_DOCUMENT_ON_SUSPENDED"]);
        }
        return new Set<ClaimStatus>(["Approved", "Declined", "INVESTIGATING", "NEW_DOCUMENT_ON_SUSPENDED", "Waiting"]);
      })();
      if (currentStatus === "NEW_DOCUMENT_ON_SUSPENDED" && nextStatus !== "Suspension") {
        return {
          disabled: true,
          toolTip: `Chỉ có thể đổi sang trạng thái ${statusTypeOption?.find((op) => op.value === "Suspension")?.comment}
          `,
        };
      }
      if (currentStatus === "Suspension" && allowedNextStatusesForSuspension.has(nextStatus) === false) {
        return {
          disabled: true,
          toolTip: `Chỉ có thể đổi sang trạng thái ${[...allowedNextStatusesForSuspension]
            .map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
            .filter(Boolean)
            .join("/")}
          `,
        };
      }
      if (!hasRole(["DEPARTMENT_HEAD"])) {
        switch (currentStatus) {
          case "InProgress": {
            if (
              new Set<ClaimStatus>([
                "ApprovedReceivedDoc",
                "Initialize",
                "InsurerAssessed",
                "Paid",
                "PRESENT_CASE_COMPLETED",
                "RECEIVED_DOCS",
                "SUPPLEMENTED_VERIFIED",
                "Suspension",
                "Updated",
              ]).has(nextStatus)
            ) {
              return { disabled: true, toolTip: "NA" };
            }
            break;
          }
          case "Approved":
          case "ApprovedReceivedDoc":
          case "Declined": {
            if (nextStatus === "Paid") break;
            return { disabled: true, toolTip: "Không được thay đổi trạng thái vì đã quyết định" };
          }
          case "ApprovedWaitingDoc": {
            if (nextStatus === "ApprovedReceivedDoc" || nextStatus === "RECEIVED_DOCS" || nextStatus === "RECEIVED_DOCS_ON_SUSPENDED") {
              break;
            }
            return { disabled: true, toolTip: "Không được thay đổi trạng thái vì đã quyết định" };
          }
          case "APPROVED_WAITING_FOR_GRADE_PERIOD_END": {
            if (canApprovedWaitingForGracePeriodEndMbalClaim === true) {
              break;
            }
            return { disabled: true, toolTip: "Không được thay đổi trạng thái vì đang chờ ân hạn" };
          }
          default:
        }
      }
      const hasBankInfo = (() => {
        const { claim_case_beneficiary: claimCaseBeneficiary } = claimFragmentData;

        if (claimCaseBeneficiary == null) return false;

        const hasBankInfoForDphdbk = claimCaseBeneficiary.payment_method === "DPHDBK" && claimCaseBeneficiary.premium_policy_number != null;
        const hasBankInfoForChecks = claimCaseBeneficiary.bank_id != null && claimCaseBeneficiary.payment_method === "CHECKS";
        const hasBankInfoForBankTransfers =
          claimCaseBeneficiary.payment_method === "BANK_TRANSFERS" && claimCaseBeneficiary.bank_id != null && claimCaseBeneficiary.bank_account_number != null;
        const hasBankInfoForDpchd = claimCaseBeneficiary.payment_method === "DPCHD" && claimCaseBeneficiary.bank_id != null && claimCaseBeneficiary.bank_account_number != null;

        return hasBankInfoForDphdbk || hasBankInfoForChecks || hasBankInfoForBankTransfers || hasBankInfoForDpchd;
      })();
      const hasApprovedWaitingDoc = claimFragmentData.claim_status_availability_action_logs.some((n) => n.new_value === "ApprovedWaitingDoc");

      if (
        includes<ClaimStatus>(["ApprovedReceivedDoc", "ApprovedWaitingDoc", "RECEIVED_DOCS"], nextStatus) === false &&
        CLAIM_DECISION_STATUSES.includes(nextStatus) &&
        canMakeDecision.result === false
      ) {
        return {
          disabled: true,
          toolTip: `Số tiền chi trả vượt quyền hạn thẩm định ${utils.formatCurrency(canMakeDecision.limit)}`,
        };
      }

      const allowedStatusesToSuspension = new Set<ClaimStatus>([
        "AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING",
        "InsurerAssessed",
        "NEW_DOCUMENT_ON_SUSPENDED",
        "Pending",
        "PRESENT_CASE_COMPLETED",
        "Waiting",
      ]);

      switch (nextStatus) {
        case "Initialize": {
          return { disabled: true, toolTip: "??" };
        }
        case "Paid": {
          if (hasRole(["ClaimAssessor", "ClaimAssessorManager"]) && !hasRole(["DEPARTMENT_HEAD"])) {
            return { disabled: true, toolTip: "Chỉ Ops có thể cập nhật thanh toán" };
          }
          break;
        }
        case "Cancelled":
        case "CANCELLED_BY_LA_REQUEST": {
          return hasRole(["ClaimAssessorManager", "DEPARTMENT_HEAD"]) ? { disabled: false } : { disabled: true, toolTip: "Chỉ quản lý có thể hủy" };
        }

        case "RECEIVED_DOCS_ON_SUSPENDED": {
          if (currentStatus !== "ApprovedWaitingDoc" && daysSinceApprovedAwaitingDocs <= 30) {
            return { disabled: true, toolTip: `Chỉ có thể chuyển từ trạng thái ${valueToComment("ApprovedWaitingDoc", statusTypeOption)} và quá 30 ngày` };
          }
          break;
        }

        case "AWAITING_CONTRACT_COMPLETION": {
          if (currentStatus !== "InProgress" && currentStatus !== "Initialize") {
            return {
              disabled: true,
              toolTip: `Chỉ có thể chuyển từ trạng thái ${valueToComment("InProgress", statusTypeOption)} hoặc ${valueToComment("Initialize", statusTypeOption)}`,
            };
          }
          break;
        }

        case "RECEIVED_DOCS": {
          if (daysSinceApprovedAwaitingDocs > 30) {
            return {
              disabled: false,
              toolTip: `Đã ${daysSinceApprovedAwaitingDocs - 30} ngày quá hạn quy định (quy định: 30 ngày)`,
            };
          }
          break;
        }

        case "Updated": {
          if (currentStatus === "ApprovedWaitingDoc") {
            return { disabled: true };
          }
          break;
        }

        case "Declined": {
          if (claimType.fwdMr && isEmpty(claimFragmentData.assessment_explanation)) {
            return { disabled: true, toolTip: "Chưa có trích dẫn điều khoản" };
          }
          if (assessedAmount > 0) return { disabled: true, toolTip: "Không thể từ chối: Đã có số tiền bồi thường" };
          if (!isIcdAssessed) return { disabled: true, toolTip: "Không thể từ chối: Phải xác định ICD" };
          if (hasOutstandingPendingCode) {
            return { disabled: true, toolTip: "Không thể từ chối: có Pending chưa giải quyết" };
          }
          break;
        }
        case "Suspension": {
          if (claimType.slvHs === true && differenceInCalendarDays(new Date(), new Date(claimFragmentData.created_at)) >= 30) {
            return {
              disabled: false,
            };
          }

          if (allowedStatusesToSuspension.has(currentStatus) === false) {
            return {
              disabled: true,
              toolTip: `Chỉ có thể chuyển từ trạng thái ${[...allowedStatusesToSuspension]
                .map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
                .filter(Boolean)
                .join("/")}`,
            };
          }
          if (claimFragmentData.claim_status_availability_pending_codes.some((i) => i.status === "O") === true) {
            return {
              disabled: true,
              toolTip: "Có pending chưa giải quyết",
            };
          }
          break;
        }
        case "NEW_DOCUMENT_ON_SUSPENDED": {
          if (currentStatus !== "Suspension") {
            return { disabled: true, toolTip: `Chỉ có thể chuyển từ trạng thái ${statusTypeOption?.find((op) => op.value === "Suspension")?.comment}` };
          }
          break;
        }

        case "Approved":
        case "ApprovedReceivedDoc":
        case "ApprovedWaitingDoc":
        case "APPROVED_WAITING_FOR_GRADE_PERIOD_END": {
          if (claimType.fwdMr && isEmpty(claimFragmentData.assessment_explanation) && claimFragmentData.claim_case_payment?.is_partial_paid === true) {
            return { disabled: true, toolTip: "Chưa có trích dẫn điều khoản" };
          }
          if (hasApprovedWaitingDoc === false && nextStatus === "ApprovedReceivedDoc") {
            return { disabled: true, toolTip: "Phải cập nhật trạng thái chấp thuận - chờ hồ sơ gốc trước" };
          }
          const hasAssessedAmount = assessedAmount > 0;

          if (hasAssessedAmount === false && canApprovedWaitingForGracePeriodEndMbalClaim === false && canApprovedAppliedPremiumIncrementAmountMbalClaim === false) {
            return { disabled: true, toolTip: "Không thể chấp thuận: Số tiền chi trả bằng 0" };
          }
          if (isEmpty(claimFragmentData.assessment_explanation) && claimFragmentData.fwd_claim_case != null && requestAmount < assessedAmount) {
            return { disabled: true, toolTip: "Chưa có trích dẫn điều khoản cho ca chi trả một phần" };
          }
          if (!hasBankInfo) {
            return { disabled: true, toolTip: "Không thể chấp thuận: Chưa có thông tin nhận tiền bồi thường" };
          }
          if (hasOutstandingPendingCode) {
            return { disabled: true, toolTip: "Không thể chấp thuận: có Pending chưa giải quyết" };
          }
          if (!isIcdAssessed) return { disabled: true, toolTip: "Không thể chấp thuận: chưa nhập ICD" };
          if (!isMedicalProviderAssessed) {
            return { disabled: true, toolTip: "Không thể chấp thuận: chưa nhập cơ sở y tế" };
          }
          if (claimType.slvHs === true && nextStatus === "Approved" && claimFragmentData.claim_case_beneficiary?.payment_method === "DPCHD") {
            return { disabled: true, toolTip: "Không thể chấp thuận: Phương thức thanh toán phí định kỳ cần Chấp thuận - chờ hết thời gian ân hạn." };
          }
          const allowApprovedStatusesForSlvClaim: ClaimStatus[] = ["InProgress", "PRESENT_CASE_COMPLETED", "SUPPLEMENTED_VERIFIED", "Suspension"];
          if (claimType.slvHs === true && allowApprovedStatusesForSlvClaim.includes(currentStatus) === false) {
            return {
              disabled: true,
              toolTip: `Chỉ có thể chấp thuận từ trạng thái ${allowApprovedStatusesForSlvClaim
                .map((s) => statusTypeOption?.find((op) => op.value === s)?.comment)
                .filter(Boolean)
                .join("/")}`,
            };
          }
          if (currentStatus === "ApprovedWaitingDoc") {
            return { disabled: true };
          }
          break;
        }

        case "Pending": {
          if (hasOutstandingPendingCode === false) {
            return {
              disabled: true,
              toolTip: "Không thể yêu cầu bổ sung hồ sơ: Cần ít nhất 1 Pending Code chưa giải quyết",
            };
          }
          const hasOpenExternalPendingCode = claimFragmentData.claim_status_availability_pending_codes.some((i) => i.status === "O" && i.pending_code.internal === false);
          if (claimType.slvHs === true && hasOpenExternalPendingCode === false) {
            return {
              disabled: true,
              toolTip: "Không thể yêu cầu bổ sung hồ sơ: Cần ít nhất 1 external Pending Code chưa giải quyết",
            };
          }
          if (!hasBankInfo) return { disabled: false, toolTip: "Chú ý: Chưa có thông tin nhận tiền bồi thường" };
          if (claimType.healthCare && pendingHistoryCount >= 3 && !hasRole(["DEPARTMENT_HEAD"])) {
            return {
              disabled: true,
              toolTip: "Không thể yêu cầu bổ sung hồ sơ: Đã yêu cầu bổ sung hồ sơ 3 lần",
            };
          }
          return { disabled: false };
        }
        case "InProgress": {
          if (new Set(["Updated"]).has(currentStatus)) {
            return { disabled: true };
          }
          break;
        }
        case "Waiting":
        default: {
          return {
            disabled: false,
          };
        }
      }

      return { disabled: false };
    },
    [
      claimFragmentData,
      singleShareToInsurerSignOffFeature,
      claimType.slvHs,
      claimType.fwdMr,
      claimType.mbalHs,
      claimType.healthCare,
      previousStatusOfWaitingSlvClaim,
      isDetailFormFilled,
      claim?.is_direct_billing,
      buildDbStatus,
      hasRole,
      canMakeDecision.result,
      canMakeDecision.limit,
      statusTypeOption,
      canApprovedWaitingForGracePeriodEndMbalClaim,
      daysSinceApprovedAwaitingDocs,
      assessedAmount,
      isIcdAssessed,
      hasOutstandingPendingCode,
      canApprovedAppliedPremiumIncrementAmountMbalClaim,
      requestAmount,
      isMedicalProviderAssessed,
    ],
  );
};

export default useStatusAvailability;
