import type { TableColumnsType } from "antd";
import type { ClaimCaseHistoryFragment } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/InsuredCertificateFragment";

import BenefitTag from "app/common/components/BenefitTagV3";
import ClaimStatus from "app/common/components/ClaimStatusV2";
import TableList from "app/common/components/TableListV2";
import { CLAIM_CASE_PATH, PORTAL_PATH } from "app/portal/config/paths";
import utils from "libs/utils";
import { Link } from "react-router-dom";

import treatmentDays from "../../ClaimHistory/treatmentDays";

const ClaimCasesByDateRange = ({ claimCases }: { claimCases: FragmentOf<typeof ClaimCaseHistoryFragment>[] | undefined }) => {
  const tableSchema: TableColumnsType<FragmentOf<typeof ClaimCaseHistoryFragment>> = [
    {
      dataIndex: "code",
      render: (_, record) => (
        <Link target="_blank" to={`${PORTAL_PATH.CLAIM_CASE.replace(":claimCaseId", record.id)}/${CLAIM_CASE_PATH.CLAIM_CASE_INFO}`}>
          {record.code}
        </Link>
      ),
      title: "#1",
    },
    {
      key: "event_date",
      render: (_, record) => treatmentDays(record),
      title: "Ngày xảy ra sự kiện",
      width: 180,
    },
    {
      align: "right",
      dataIndex: "request_amount",
      render: (request_amount) => utils.formatCurrency(request_amount),
      title: "Số tiền yêu cầu",
    },
    {
      dataIndex: "insured_benefit_type",
      render: (insuredBenefitType) => <BenefitTag value={insuredBenefitType} />,
      title: "Quyền lợi",
      width: 30,
    },
    {
      dataIndex: "claim_case_status",
      fixed: "right",
      render: (claimCaseStatus) => <ClaimStatus value={claimCaseStatus} />,
      title: "Trạng thái",
      width: 28,
    },
  ];
  return <TableList data={claimCases} pagination={{ hideOnSinglePage: true, pageSize: 5 }} schema={tableSchema} title="Claim cases trùng ngày" />;
};
export default ClaimCasesByDateRange;
