import { initGraphQLTada } from "gql.tada";
import type { introspection } from "./graphql-env.d.ts";

export const graphql = initGraphQLTada<{
  introspection: introspection;
  disableMasking: true;
  scalars: {
    uuid: string;
    UUID: string;
    Datetime: string;
    DateTime: string;
    datetime: string;
    Date: string;
    date: string;
    timestamptz: string;
    timestamp: string;
    numeric: number;
    bpchar: string;
    JSON: any;
    jsonb: any;
    JSONObject: Record<string, any>,
    EmailAddress: string;
  }
}>();

export { readFragment } from "gql.tada";