import { graphql } from "sdk/v2/graphql";

const GLOBAL_DATA_QUERY = graphql(`
  query GlobalData {
    banks {
      id
      en_name
      napas_supported
      short_name
      vn_name
    }
    claim_case_group_claim_case_types {
      id: value
      comment
      value
    }
    claim_case_group_types {
      id: value
      comment
      value
    }
    claim_case_statuses {
      id: value
      comment
      value
    }
    claim_document_types(order_by: { comment: asc }) {
      id: value
      comment
      value
    }
    claim_quality_codes {
      id: value
      comment
      value
    }
    claim_sources {
      id: value
      comment
      value
    }
    correspondence_send_when {
      id: value
      comment
      value
    }
    correspondence_template_media {
      id: value
      comment
      value
    }
    correspondence_template_receivers {
      id: value
      comment
      value
    }
    correspondence_template_types {
      id: value
      comment
      value
    }
    disease_group_types {
      id: value
      comment
      value
    }
    fig_to_dos {
      id: value
      comment
      value
    }
    identity_roles {
      id: value
      comment
      value
    }
    insured_benefit_types {
      id: value
      comment
      value
    }
    insured_certificate_endorsement_reasons {
      id: value
      comment
      value
    }
    insured_certificate_statuses {
      id: value
      comment
      value
    }
    insured_person_statuses {
      id: value
      comment
      value
    }
    insured_person_vip_level {
      id: value
      comment
      value
    }
    insured_relationship_types {
      id: value
      comment
      value
    }
    languages {
      id: value
      comment
      value
    }
    marital_statuses {
      id: value
      comment
      value
    }
    payout_fund_codes {
      id: value
      comment
      value
    }
    payout_payment_methods {
      id: value
      value
    }
    payout_payment_providers {
      id: value
      value
    }
    payout_payment_request_item_statuses {
      id: value
      value
    }
    payout_payout_types {
      id: value
      value
    }
    plan_balance_types {
      id: value
      comment
      value
    }
    plan_copay_mechanisms {
      id: value
      comment
      value
    }
    policy_document_types {
      id: value
      comment
      value
    }
    policy_kinds {
      id: value
      comment
      value
    }
  }
`);

const GET_FAILED_CORRESPONDENCE_QUERY = graphql(`
  query FailedCorrespondenceHistories($userId: uuid!, $limit: Int!, $offset: Int!) {
    notification_notifications(where: { receiver_id: { _eq: $userId } }, limit: $limit, offset: $offset, order_by: { created_at: desc }) {
      id
      first_seen_at
      is_read
      reason
      created_at
      correspondence_history {
        id
        status
        claim_case {
          id
          claim_case_id
          code
        }
        company_correspondence {
          id
          template {
            id
            name
          }
        }
        policy_correspondence {
          id
          template {
            id
            name
          }
        }
      }
      origin_claim_case {
        id
        claim_case_id
        code
      }
      reason_type {
        comment
        value
      }
    }
    notification_notifications_aggregate(
      where: { receiver_id: { _eq: $userId }, correspondence_history: { status: { _eq: ERROR } }, first_seen_at: { _is_null: true } }
      limit: $limit
      offset: $offset
    ) {
      aggregate {
        count
      }
      nodes {
        id
        is_read
        created_at
        correspondence_history {
          id
          status
          claim_case {
            id
            claim_case_id
            code
          }
          company_correspondence {
            id
            template {
              id
              name
            }
          }
          policy_correspondence {
            id
            template {
              id
              name
            }
          }
        }
        origin_claim_case {
          id
          claim_case_id
          code
        }
      }
    }
  }
`);

const UPDATE_NOTIFICATION_AS_SEEN_MUTATION = graphql(`
  mutation UpdateNotificationAsSeen($notificationId: uuid!) {
    update_notification_notifications_by_pk(pk_columns: { id: $notificationId }, _set: { first_seen_at: "now()" }) {
      id
      first_seen_at
    }
  }
`);

const MeDocument = graphql(`
  query Me($id: uuid!) {
    identity_users_by_pk(user_id: $id) {
      id
      name
      avatar_url
      can_login_to_any_user
      email
      slack_member_id
      tenant_id
      insurer_roles {
        id
        insurer_role {
          id
          name
          access_controls {
            id
            rule
          }
        }
      }
      roles {
        id
        role
        company_role {
          id
          company_id
          medical_providers {
            id
            name
          }
        }
      }
      user_setting {
        id
        debug_enabled
        fwd_mr_decision_limit
        healthcare_decision_limit
        mbal_hs_decision_limit
        slv_hs_decision_limit
        use_gpt_in_claim
        use_pending_form_v2
      }
    }
  }
`);

export { GET_FAILED_CORRESPONDENCE_QUERY, GLOBAL_DATA_QUERY, MeDocument, UPDATE_NOTIFICATION_AS_SEEN_MUTATION };
