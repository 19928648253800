import type { DocumentNode } from "graphql";

import { getOperationName } from "@apollo/client/utilities";

const getRefetchOperationNames = (documents: (DocumentNode | string)[]) =>
  documents
    .map((doc) => {
      // console.log(doc);

      if (typeof doc === "string") {
        return doc;
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (doc.definitions == null) {
        return null;
      }

      return getOperationName(doc);
    })
    .filter(Boolean);

export default getRefetchOperationNames;
