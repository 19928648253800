/* eslint-disable react-refresh/only-export-components */
import type { TablePaginationConfig } from "antd";

import tableStyles from "app/common/components/TableList/TableList.module.css";
import { InsuredBenefitTypesEnum, MrBenefitType, PlanBalanceTypesEnum } from "sdk/gql/types";

export const SO_DUA_USER_ID = "9a34cadb-3c87-46db-b124-5d856205f18f";
export const PAPAYA_COMPANY_ID = "3ec1b743-6d2c-46ac-bfeb-1e4ac0db1332";

// for non-life insurers, id is parent company id as they have multiple child companies
export const NON_LIFE_INSURER_IDS = {
  AAA: "db757b5b-e950-4697-8518-f0513bedef0e",
  BL: "20f43b44-9639-4f50-a8d9-61ac2fd49c73",
  BM: "6ca915d5-d6b8-4721-9390-4e82d2b2a5d1",
  GIC: "ef72ca32-e288-4394-9309-df92c8ec2c2b",
  MIC: "1a001e99-a70f-44f8-addb-9eb60c4e3f74",
  PJICO: "135b281d-3325-4a42-857a-83ced8ae372e",
  PTI: "8aef5c9d-1a34-4235-a4bf-928854dc9eda",
  VNI: "522a6a16-c6e7-491d-ac7f-b2d17ed31d14",
};

export const LIFE_INSURER_IDS = {
  FWD: "8aada641-7700-49db-9e36-5c9bd6beb98c",
  MBAges: "704c0593-c812-47e9-9bf5-653a9b105923",
  SLV: "feb7bf23-fac7-4c99-a487-c5d4c9ffcb2e",
};

export const INSURER_COMPANY_IDS = {
  ...NON_LIFE_INSURER_IDS,
  ...LIFE_INSURER_IDS,
} as const;

export const NON_HEALTHCARE_COMPANY_IDS = [INSURER_COMPANY_IDS.FWD, INSURER_COMPANY_IDS.MBAges, INSURER_COMPANY_IDS.SLV];

export const MOBIFONE_POLICY_IDS = ["be9e3a9a-3b86-4c0e-9cb5-04db9d883ac7", "f2e64f48-4ad4-48ac-9f79-f74984c66bbc"];

export const ASSIGNED_CLAIM_CASE_STATUSES_FOR_FWD: ClaimCaseStatusesEnum[] = [
  "InProgress",
  "Updated",
  "Initialize",
  "SUPPLEMENTED_VERIFIED",
  "RECEIVED_DOCS",
  "InsurerAssessed",
  "PRESENT_CASE_COMPLETED",
  "NEW_DOCUMENT_ON_SUSPENDED",
  "RECEIVED_DOCS_ON_SUSPENDED",
  "AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING",
] as const;

export const ASSIGNED_CLAIM_CASE_STATUSES: ClaimCaseStatusesEnum[] = [...ASSIGNED_CLAIM_CASE_STATUSES_FOR_FWD, "INVESTIGATING"] as const;

export const CLAIM_DECISION_STATUSES = ["Paid", "Declined", "Approved", "ApprovedReceivedDoc", "RECEIVED_DOCS", "ApprovedWaitingDoc"] as const;

export const CLAIM_READY_FOR_PAYMENT_STATUSES: ClaimCaseStatusesEnum[] = ["Approved", "ApprovedReceivedDoc"];

export const PRODUCTIVITY_CLAIM_DECISION_STATUSES: ClaimCaseStatusesEnum[] = [
  "Declined",
  "Approved",
  "ApprovedWaitingDoc",
  "APPROVED_WAITING_FOR_GRADE_PERIOD_END",
  "Pending",
  // "ApprovedReceivedDoc",
  // "Estimated",
  // "Waiting",
];

export const PLAN_BALANCE_TIME_UNITS = [
  PlanBalanceTypesEnum.CasesPerYear,
  PlanBalanceTypesEnum.DaysPerTime,
  PlanBalanceTypesEnum.DaysPerYear,
  PlanBalanceTypesEnum.TimesPerYear,
  "TIMES_PER_CERTIFICATE",
  "DAYS_PER_CERTIFICATE",
];

export const PLAN_BALANCE_CURRENCY_UNITS = [
  PlanBalanceTypesEnum.CurrencyPerCase,
  PlanBalanceTypesEnum.CurrencyPerDay,
  PlanBalanceTypesEnum.CurrencyPerTime,
  PlanBalanceTypesEnum.CurrencyPerYear,
];

export const FWD_MR_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS_V2 = {
  [`${InsuredBenefitTypesEnum.Dental}_ĐIỀU TRỊ NGOẠI TRÚ/NHA KHOA`]: "FWD_DE_DentalBasic",
  [`${InsuredBenefitTypesEnum.InPatient}_CẤP CỨU KHI ĐANG Ở NƯỚC NGOÀI`]: "FWD_Emergency",
  [`${InsuredBenefitTypesEnum.InPatient}_ĐIỀU TRỊ NỘI TRÚ CÓ THỰC HIỆN PHẪU THUẬT`]: "FWD_Surgery_Inpatient",
  [`${InsuredBenefitTypesEnum.InPatient}_ĐIỀU TRỊ NỘI TRÚ KHÔNG THỰC HIỆN PHẪU THUẬT`]: "FWD_nonS_Inpatient",
  [`${InsuredBenefitTypesEnum.InPatient}_ĐIỀU TRỊ TRONG NGÀY CÓ PHẪU THUẬT`]: "FWD_IntradaySurgery",
  [`${InsuredBenefitTypesEnum.InPatient}_ĐIỀU TRỊ UNG THƯ HOẶC CẤY GHÉP NỘI TẠNG`]: "FWD_Transplant",
  [`${InsuredBenefitTypesEnum.OutPatient}_ĐIỀU TRỊ NGOẠI TRÚ/NHA KHOA`]: "FWD_OUTPATIENT",
  // MR01
  [`${InsuredBenefitTypesEnum.OutPatient}_ĐIỀU TRỊ NGOẠI TRÚ`]: "FWD_OUTPATIENT",
} as const;

export const HC_CLAIM_BENEFIT_TYPE_TO_ASSESSMENT_BENEFIT_MAPPINGS = {
  [InsuredBenefitTypesEnum.Dental]: ["DE_DentalBasic", "DE_Fillings", "DE_Cleaning", "DE_Infection", "DE_Extraction", "DE_Xray"],
  [InsuredBenefitTypesEnum.Maternity]: ["MA_ICC"],
  [InsuredBenefitTypesEnum.OutPatient]: ["OP_MedicalExamination", "OVT-001"],
} as const;

export const MR_PLAN = {
  HS01: "HS01",
  HS02: "HS02",
  HS03: "HS03",
  HS04: "HS04",
  M507: "M507",
  M510: "M510",
  MC01: "MC01",
  MR01: "MR01",
  MR02: "MR02",
} as const;

export const VIETNAMESE_FWD_DECLINE_CODES = {
  D31: "Không thỏa quy tắc/điều khoản",
  D32: "Điều khoản loại trừ của sản phẩm",
  D33: "Thời gian chờ loại trừ",
  D34: "Bệnh tồn tại trước/không khai báo đúng thông tin",
  D35: "Quá hạn bổ sung hồ sơ",
  D36: "Claim trong thời gian hợp đồng mất hiệu lực",
  D99: "Lý do khác",
};

const SETTINGS = {
  SEARCH_DEBOUNCE_TIME: 600,
} as const;

const QUERY_LIMIT = 10_000;

const COMMON_POLL_INTERVAL = 5000;

const PAPAYA_IP_WHITELIST = ["113.161.75.232"];

const LOCAL_STORAGE_KEYS = {
  CLAIM_BENEFIT_VERSION: "claim_benefit_version",
  CLAIM_TOOLS_OPEN: "claim_tools_open",
  DEBUG: "debug",
  DEBUG_CLAIM_ASSESSMENT: "DEBUG_CLAIM_ASSESSMENT",
  EXPANDED_MAILBOX_KEYS: "expanded_mailbox_keys",
  IP: "ip",
  SHOW_ALL_PRESENT_CASE: "show_all_present_case",
  SHOW_RELEVANT_EMAILS_CLAIM_DETAIL: "show_relevant_emails_claim_detail",
  SUPER_ADMIN_TOKEN: "super_token",
  TOKEN: "token",
  USE_CHAT_GPT: "use_chat_gpt",
  USE_CLAIM_BENEFIT_V2: "use_claim_benefit_v2",
  USER_FULL_NAME: "user_full_name",
  USER_INFO: "user_info",
} as const;

const DEFAULT_LOCAL_STORAGE_VALUES = {
  CLAIM_BENEFIT_VERSION: "v2",
  SHOW_ALL_PRESENT_CASE: false,
};

const THEMES = {
  BLUE_THEME: "blue",
  DARK_THEME: "dark",
  LIGHT_THEME: "light",
} as const;

const FIELDS = {
  AGE_RANGES: "ageRanges",
  INSURED_PERSON_ID_LIST: "insuredCertificateIds",
  IS_AGE_RANGE_TARGET: "applyTargetAgeRange",
  IS_BLACKLIST: "applyToBlackList",
  IS_TARGET: "applyTargetCondition",
  IS_WILLING_TARGET: "applyToVoluntaryTarget",
  TARGET: "target",
} as const;

const KEYS = {
  CREATE_CLAIM: {
    BENEFITS: "benefits",
    CLAIM_INFO: "claimInfo",
    CLAIM_INFO_TYPE: {
      BENEFIT_TYPE: "benefitType",
      DIAGNOSIS: "diagnosis",
      DISEASES: "diseases",
      EMAIL: "email",
      EVENT_DATE: "eventDates",
      EVENT_DATE_RANGE: "eventDateRanges",
      ICD_CODE: "icdCode",
      INSURED_CERTIFICATE_ID: "insuredCertificateId",
      MEDICAL_PROVIDER_ITEM: "medicalProviderItem",
      REQUEST_AMOUNT: "requestAmount",
      SOURCE: "source",
      STATUS: "status",
      TREATMENT_METHOD: "treatmentMethod",
    },
  },
  DOC_SOURCE: "source",
  DOC_SOURCE_NAME: {
    ASSESSMENTS: "2",
  },
  DOC_TYPE: "type",
  FILE: "file",
} as const;

const METADATA = {
  BANK: "bank_list",
  CITY: "city",
  DISEASES: "diseases",
} as const;

const TYPE = {
  BENEFIT_TYPE: {
    0: "Life",
    1: "Inpatient",
    2: "Outpatient",
    3: "Allowance",
  },
  BENEFIT_TYPE_ENUMS: {
    IN_PATIENT: "InPatient",
  },
  BENEFIT_TYPE_NAME: {
    Accident: 4,
    Allowance: 3,
    Dental: 7,
    InPatient: 1,
    InPatient_Maternity: 1,
    Life: 0,
    Maternity: 6,
    Others: 5,
    OutPatient: 2,
    OutPatient_Dental: 2,
  },
  CLAIM_FILE_TYPE: {
    DISCHARGE_PAPER: "DischargePaper",
    INVOICE_PAPER: "InvoicePaper",
    OTHER_PAPER: "OtherPaper",
    PRESCRIPTION_PAPER: "PrescriptionPaper",
  },
  CLAIM_FILE_TYPE_VALUE: {
    DISCHARGE_PAPER: "Giấy ra viện",
    INVOICE_PAPER: "Hóa đơn",
    OTHER_PAPER: "Giấy tờ khác",
    PRESCRIPTION_PAPER: "Toa thuốc",
  },
  CLAIM_STATUS_NAME: {
    Created: "Khởi tạo thành công",
    EstimateGuarantee: "Chấp nhận bảo lãnh",
    Initialize: "",
    InProgress: "Đang thẩm định",
    Received: "",
  },
  CLAIM_STATUS_TO_COLOR: {
    Approved: "lime",
    ApprovedReceivedDoc: "geekblue",
    ApprovedWaitingDoc: "yellow",
    AWAITING_CONTRACT_COMPLETION: "#e8c085",
    Cancelled: "black",
    Declined: "red",
    DOCS_OVERDUE: "salmon",
    Estimated: "coral",
    InProgress: "orange",
    InsurerAssessed: "gold",
    INVESTIGATING: "teal",
    NEW_DOCUMENT_ON_SUSPENDED: "#990F4B",
    Paid: "green",
    Pending: "brown",
    PRESENT_CASE_COMPLETED: "geekblue",
    RECEIVED_DOCS: "geekblue",
    RECEIVED_DOCS_ON_SUSPENDED: "#990F4B",
    SUPPLEMENTED_VERIFIED: "magenta",
    Suspension: "salmon",
    Updated: "blue",
    Waiting: "purple",
  },
  CLAIM_TYPE_NAME: {
    DIRECT_BILLING: "directBilling",
    REIMBURSEMENT: "reimbursement",
  },
  COMPANY_TYPE: {
    0: "Corporate",
    1: "Insurer",
    2: "Broker",
  },
  COMPANY_TYPE_NAME: {
    Broker: 2,
    Corporate: 0,
    Insurer: 1,
  },
  FILE_TYPE: {
    CLAIM_DOCUMENT: "ClaimDocument",
    PLAN_DOCUMENT: "PlanDocument",
    POLICY_DOCUMENT: "PolicyDocument",
  },
  GENDER_TYPE: {
    0: "Nam",
    1: "Nữ",
  },
  GENDER_TYPE_NAME: {
    "": "--",
    Female: 1,
    Male: 0,
    undefined: "--",
  },
  IMPORT_TYPE: {
    InsuredPerson: "0",
    LA: "1",
    MedicalProvider: "2",
  },
  METADATA_INPATIENT_NAME: {
    DIAGNOSIS_PRICE: "Xét nghiệm, chẩn đoán",
    MEDICINCE_PRICE: "Thuốc, các dược phẩm",
    NUMBER_IN_BED: "Số ngày nằm viện",
    OTHER_PRICE: "Chi phí khác",
    ROOM_PRICE: "Tiền phòng/giường",
    SURGERY_PRICE: "Chi phí phẩu thuật",
    TOTAL_PRICE_ROOM_DAY: "Tổng tiền phòng, giường",
  },
  METADATA_INPATIENT_TYPE: {
    "Chi phí khác": "otherFee",
    "Chi phí phẩu thuật": "surgeryFee",
    "Số ngày nằm viện": "numOfInHospitalDays",
    "Thuốc, các dược phẩm": "medicineFee",
    "Tiền phòng/giường": "roomFee",
    "Xét nghiệm, chẩn đoán": "testFee",
  },
  METADATA_TYPE: {
    ALL: 0,
    CLAIM_DOCUMENT_TYPE: 1,
    CLAIM_NOTE_TYPE: 2,
    CLAIM_PENDING_CODE_STATUS: 3,
    CLAIM_SOURCE: 4,
    CLAIM_STATUS: 5,
    COMPANY_ROLE_TYPE: 6,
    INSURED_BENEFIT_TYPE: 7,
    INSURED_RELATIONSHIP_TYPE: 8,
    MEDICAL_PROVIDER_STATUS: 13,
    MEDICAL_PROVIDER_TYPE: 12,
    PLAN_BALANCE_TYPE: 9,
    POLICY_CO_PAYMENT_TARGET: 10,
    POLICY_TYPE: 11,
  },
  METADATA_TYPE_NAME: {
    // CLAIM_DOCUMENT_TYPE: "ClaimDocumentType",
    // CLAIM_NOTE_TYPE: "ClaimNoteType",
    // CLAIM_PENDING_CODE_STATUS: "ClaimPendingCodeStatus",
    // CLAIM_SOURCE: "ClaimSource",
    // CLAIM_STATUS: "ClaimRequestStatus",
    // CLAIM_REQUEST_STATUS: "ClaimRequestStatus",
    // COMPANY_ROLE_TYPE: "CompanyRoleType",
    // INSURED_BENEFIT_TYPE: "InsuredBenefitType",
    // INSURED_RELATIONSHIP_TYPE: "InsuredRelationshipType",
    // PLAN_BALANCE_TYPE: "PlanBalanceTypes",
    // POLICY_CO_PAYMENT_TARGET: "PolicyCoPaymentTarget",
    // POLICY_TYPE: "PolicyType",
    // MEDICAL_PROVIDER_TYPE: "MedicalProviderType",
    // MEDICAL_PROVIDER_STATUS: "MedicalProviderStatus",
  },
  NOTE_TYPE: {
    REMARK: "Remark",
    SIGN_OFF: "SignOff",
  },
  OWNER_TYPE: {
    CORPORATE: "corporate",
    INDIVIDUAL: "individual",
  },
  PENDING_STATUS: {
    OutstandingRequirement: "O",
    RequirementReceived: "R",
    RequirementWaived: "W",
    Suspended: "S",
  },
  PENDING_STATUS_NAME: {
    O: "OutstandingRequirement",
    R: "RequirementReceived",
    S: "Suspended",
    W: "RequirementWaived",
  },
  POPUP: {
    CREATE: 0,
    EDIT: 1,
  },
  SOURCE_TYPE: {
    ASSESSOR: "Assessments",
    CLIENT: "InsuredPersons",
    MEDICAL_PROVIDERS: "MedicalProviders",
  },
  STATUS_CLAIM: {
    Approved: 8,
    ApprovedWaitingDoc: 8,
    Cancelled: 10,
    Declined: 9,
    Estimated: 4,
    InProgress: 4,
    Paid: 8,
    Pending: 4,
    Suspension: 9,
    Updated: 3,
    Waiting: 4,
  },
  STATUS_COLOR: {
    0: "yellow",
    10: "black",
    2: "default",
    3: "blue",
    4: "orange",
    7: "yellow",
    8: "green",
    9: "red",
  },
  VN_PENDING_STATUS_NAME: {
    MISSING_DOCUMENT: "Thiếu chứng từ",
    O: "Đang bổ sung",
    R: "Đã nộp",
    S: "Hết hạn",
    W: "Miễn trừ",
  },
} as const;

const VALIDATE = {
  EMAIL: "email",
  NONE_SPACE: "none-space",
  NONE_SPECIAL_CHAR: "none-special",
  PASSWORD: "password",
  PHONE: "phone",
} as const;

const RESPONSE_CODE = {
  ERROR_004: "Error_004", // wrong credential,
  ERROR_018: "Error_018",
  ERROR_022: "Error_022", // Wrong paid day over limit
  ERROR_023: "Error_023", // Wrong paid unit over limit
  ERROR_024: "Error_024", // Wrong paid total over limit
  ERROR_VALIDATION: 400,
  SUCCESS_002: "Success_002",
  SUCCESS_004: "Success_004", // auth token success
  SUCCESS_200: "Success_200",
} as const;

const FORMATTER = {
  API_DATE_FORMAT: "yyyy-MM-dd HH:mm:ss",
  DATE_FORMAT: "dd/MM/yyyy",
  DATE_FORMAT_WITH_DATE_OF_WEEKS: "dd/MM/yyyy (E)",
  DATE_FORMAT_WITHOUT_SEPARATOR: "ddMMyyyy",
  DATE_TIME_FORMAT: "dd/MM/yyyy HH:mm:ss",
  DATE_TIME_FORMAT_WITHOUT_SEC: "dd/MM/yyyy HH:mm",
  DATE_TIME_FORMAT_WITHOUT_SEC_YEAR: "dd/MM HH:mm",
  DATE_TIME_WITHOUT_SEC: "HH:mm dd/MM/yyyy",
  DATE_TIME_WITHOUT_SEC_AND_YEAR: "HH:mm dd/MM",
  ISO_BASIC_DATE_FORMAT: "yyyyMMdd",
  ISO_BASIC_DATE_TIME_FORMAT: "yyyyMMdd'T'HHmmss",
  ISO_ONLY_DATE_FORMAT: "yyyy-MM-dd",
  TIME_WITHOUT_SECOND: "HH:mm",
} as const;

const TABLE_CONFIG: { PAGINATION: TablePaginationConfig } = {
  PAGINATION: {
    className: tableStyles.paginateContainer,
    defaultPageSize: 10,
    hideOnSinglePage: true,
    pageSizeOptions: ["10", "20", "30", "50", "100", "1000", "10000"],
    showSizeChanger: true,
    showTotal: (total: number, row: number[]) => (
      <div>
        Tổng cộng: {row[1]}/{total}
      </div>
    ),
  },
};

const LIMIT_MEDICAL_PROVIDER_LIST = 2000;

const RANDOM_WAITING_TEXT = [
  "Có liền",
  "Chờ em xí",
  "Đến ngay nè",
  "Chút nữa thôi",
  "Sắp có rồi",
  "Chờ 1 tí",
  "Nhanh lắm",
  "Đang chạy 🏃‍♀️",
  "Một con vịt xòe ra hai cái cánh...🦆",
  "Cháu lên 3, cháu vô mẫu giáo 👶",
  "Cái cây xanh xanh, thì lá cũng xanh...☘️",
  "Đi học về, là đi học về...📚",
  "Chờ đợi là hạnh phúc",
  "Chờ, chờ mãi",
  "Be Candid!",
  "Wear the customer's shoe 🩴",
  "Be Bold! 💪",
  "Be An Owner!",
  "Focus on impact",
  "Be kind to others ❤️",
  "Embrace challenges ✊",
  "Together we can 🤝",
  "Do right things right",
  "Customer, customer, customer",
  "Loading...please wait 💿",
];

const HOSPITAL_CLAIM_CASE_STATUS = {
  Approved: "Chấp thuận - Đã nhận hồ sơ gốc",
  ApprovedWaitingDoc: "Chấp thuận - Chờ hồ sơ gốc",
  Cancelled: "Hủy",
  Declined: "Từ chối bồi thường",
  Estimated: "Chấp thuận chi phí dự kiến",
  InProgress: "Đang xử lý",
  Paid: "Chấp thuận - Đã chi trả",
  Pending: "Yêu cầu bổ sung hồ sơ",
  Suspension: "Tạm dừng xử lý",
  Updated: "Đã bổ sung hồ sơ",
  Waiting: "Đang xử lý",
} as const;

const DATE_MAPPING_TYPE = {
  friday: "Thứ 6",
  monday: "Thứ 2",
  saturday: "Thứ 7",
  sunday: "Chủ nhật",
  thursday: "Thứ 5",
  tuesday: "Thứ 3",
  wednesday: "Thứ 4",
} as const;

const DATE_MAPPING_TYPE_KEY = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"] as const;

const BENEFIT_TYPE_MP = ["OutPatient", "InPatient", "Dental", "Maternity"] as const;

const INSURED_RELATIONSHIP_MAPPING: Record<InsuredRelationshipTypesEnum, string> = {
  Children: "Con",
  HusbandWife: "Vợ Chồng",
  Other: "Khác",
  Parents: "Cha mẹ",
} as const;

const PRODUCT_CODE_BENEFIT_MAPPING = {
  [MR_PLAN.MC01]: {
    [MrBenefitType.InPatient]: "Nội trú",
    [MrBenefitType.OutPatient]: "Ngoại trú",
  },
  [MR_PLAN.MR01]: {
    [MrBenefitType.InPatient]: "Nội trú",
    [MrBenefitType.OutPatient]: "Ngoại trú",
  },
  [MR_PLAN.MR02]: {
    [MrBenefitType.Dental]: "Nha khoa",
    [MrBenefitType.InPatient]: "Nội trú",
    [MrBenefitType.OutPatient]: "Ngoại trú",
  },
};
const SPECIAL_DECLINE_CODE = "PPY_DC_99";
const INVESTIGATING_PENDING_CODE = "P11";

const ERROR_CODE = {
  BAD_USER_INPUT: "BAD_USER_INPUT",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
} as const;
const CLAIM_CASE_STATUS = {
  Approved: "Chấp thuận",
  ApprovedReceivedDoc: "Chấp thuận - đã nhận hồ sơ gốc",
  ApprovedWaitingDoc: "Chấp thuận - chờ hồ sơ gốc",
  ApprovedWaitingForGradePeriodEnd: "Chấp thuận - chờ hết thời gian ân hạn",
  Cancelled: "Hủy",
  Declined: "Từ chối",
  Estimated: "Dự kiến bảo lãnh",
  Initialize: "Đang khởi tạo",
  InProgress: "Đang thẩm định",
  InsurerAssessed: "Trình case - đã thẩm định",
  Paid: "Đã thực hiện thanh toán",
  Pending: "Yêu cầu bổ sung hồ sơ",
  SupplementedVerified: "Xác nhận hoàn tất bổ sung",
  Suspension: "Quá hạn bổ sung hồ sơ",
  Updated: "Đã bổ sung hồ sơ",
  Waiting: "Chờ kết quả trình case",
} as const;

const SUPPORTED_FUND_CODES = {
  BL: "BL",
  BMBD: "BMBD",
  BMTCT: "BMTCT",
  FWD_HS: "FWD_HS",
  FWD_MR: "FWD_MR",
  MBAL: "MBAL",
  MIC: "MIC",
};

const HISTORY_CLAIM_SOURCES = ["BAOMINH_HISTORY", "FWD_HISTORY", "MBAL_HISTORY", "SLV_HISTORY"] as const;

const MIMES = {
  TEXT_PLAIN: "text/plain",
};

const LABEL_SLV_PILOT_PHASE_ID = "02164964-25a4-43e2-be9c-364601136384";
const LABEL_ID_BLOCK_LIST = [LABEL_SLV_PILOT_PHASE_ID];

export {
  BENEFIT_TYPE_MP,
  CLAIM_CASE_STATUS,
  COMMON_POLL_INTERVAL,
  DATE_MAPPING_TYPE,
  DATE_MAPPING_TYPE_KEY,
  DEFAULT_LOCAL_STORAGE_VALUES,
  ERROR_CODE,
  FIELDS,
  FORMATTER,
  HISTORY_CLAIM_SOURCES,
  HOSPITAL_CLAIM_CASE_STATUS,
  INSURED_RELATIONSHIP_MAPPING,
  INVESTIGATING_PENDING_CODE,
  KEYS,
  LABEL_ID_BLOCK_LIST,
  LIMIT_MEDICAL_PROVIDER_LIST,
  LOCAL_STORAGE_KEYS,
  METADATA,
  MIMES,
  PAPAYA_IP_WHITELIST,
  PRODUCT_CODE_BENEFIT_MAPPING,
  QUERY_LIMIT,
  RANDOM_WAITING_TEXT,
  RESPONSE_CODE,
  SETTINGS,
  SPECIAL_DECLINE_CODE,
  SUPPORTED_FUND_CODES,
  TABLE_CONFIG,
  THEMES,
  TYPE,
  VALIDATE,
};

export const PPY_CDN_PATH = "https://care.cdn.papaya.services";
