import { Modal, Space } from "antd";
import Copyable from "app/common/components/Copyable";
import { useAuth } from "contexts/AuthContext";
import { useLayout } from "layouts/LayoutContext";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import styles from "./Debugger.module.less";

const DebuggerScreen = () => {
  const { accessPermissions, allowedRoles, token, user, userId } = useAuth();
  const [showDebugger, setShowDebugger] = useState(false);
  const { showMenu } = useLayout();
  useHotkeys("`", () => {
    const devHost = `https://prod.cassava.care:${import.meta.env.VITE_PORT ?? 3000}`;
    navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Blob([`${window.location.href.replace(window.location.origin, devHost)}`], {
          type: "text/plain",
        }),
      }),
    ]);
  });

  return (
    <div>
      <div className={styles.debugger} id="debugger">
        <Space align="center" direction={showMenu ? "horizontal" : "vertical"} size={0}>
          {import.meta.env.PROD && user.user_setting?.debug_enabled === false ? null : (
            <button accessKey="`" onClick={() => setShowDebugger(!showDebugger)} style={{ height: 33, width: showMenu ? "unset" : 80 }} type="button">
              🐛
            </button>
          )}
        </Space>
      </div>
      <Modal centered footer={[]} onCancel={() => setShowDebugger(false)} open={showDebugger} title="Debugger">
        <div style={{ display: "flex" }}>
          User ID: <Copyable>{userId}</Copyable>
        </div>
        <div style={{ display: "flex" }}>
          Token: <Copyable childClassName="w-[200px] overflow-hidden text-ellipsis whitespace-nowrap block">{token}</Copyable>
        </div>
        <div>Roles</div>
        <ul>{allowedRoles?.map((role) => <li key={role}>{role}</li>)}</ul>
        <div>Access Permissions</div>
        <ul>
          {accessPermissions.map((ac) => (
            <li key={ac}>{ac}</li>
          ))}
        </ul>
      </Modal>
    </div>
  );
};

export default DebuggerScreen;
