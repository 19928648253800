import type { TableColumnsType } from "antd";
import type { CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY } from "app/portal/screens/TotalReport/SlvTpaReport/graphql/queries";
import type { ClaimCaseStatusesEnum } from "sdk/gql/types";

import { FORMATTER } from "config/constants";
import utils from "libs/utils";
import { useMemo } from "react";
import { PayoutPaymentMethodsEnum } from "sdk/gql/types";

const CaseStatusMapping = {
  ACCEPT: "Accept by admin",
  ADMIT_BY_SENIOR: "Admit by senior",
  APPROVE_SPEEDY_CLAIM: "Approve Speedy Claim",
  CLOSED_BY_SA: "Closed by SA",
  COMPLETE_BY_INVESTIGATOR: "Complete by Investigator",
  COMPLETED_BY_RE_UW: "Completed by Re-UW",
  DECLINE_BY_SENIOR: "Decline by senior",
  PENDING_BY_INVESTIGATOR: "Pending by Investigator",
  PENDING_BY_RE_UW: "Pending by Re-UW",
  SUPPLEMENTARY_BY_CA: "Supplementary by CA",
  SUPPLEMENTARY_BY_CA_2: "Supplementary by CA",
  SUPPLEMENTARY_FEEDBACK: "Supplementary feedback",
  SUPPLEMENTARY_FEEDBACK_2: "Supplementary feedback",
  SUPPLEMENTARY_REMIND: "Supplementary by CA",
};

const SlvClaimCaseStatusMapping = {
  ACCEPT: "CLM_STAT_5",
  ADMIT_BY_SENIOR: "CLM_STAT_11",
  APPROVE_SPEEDY_CLAIM: "CLM_STAT_19",
  CLOSED_BY_SA: "CLM_STAT_CLOS_SA",
  COMPLETE_BY_INVESTIGATOR: "CLM_STAT_16",
  COMPLETED_BY_RE_UW: "CLM_STAT_RE_COM",
  DECLINE_BY_SENIOR: "CLM_STAT_13",
  PENDING_BY_INVESTIGATOR: "CLM_STAT_15",
  PENDING_BY_RE_UW: "CLM_STAT_RE_PEN",
  SUPPLEMENTARY_BY_CA: "CLM_STAT_SUP_CA",
  SUPPLEMENTARY_BY_CA_2: "CLM_STAT_SUP_CA",
  SUPPLEMENTARY_FEEDBACK: "CLM_STAT_SUP_AD",
  SUPPLEMENTARY_FEEDBACK_2: "CLM_STAT_SUP_AD",
};

type BenefitUsed = {
  amountBenefitUsedInOrder?: null | number;
  hospitalBenefitUsedInOrder?: null | number;
  icuBenefitUsedInOrder?: null | number;
  surgeryBenefitUsedInOrder?: null | number;
};

export type ClaimCaseForExport = BenefitUsed & ResultOf<typeof CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY>["claim_cases"][number];

const GENDER_MAP: Record<0 | 1, "Nam" | "Nữ"> = {
  0: "Nam",
  1: "Nữ",
};

export const useColumns = (page?: number, pageSize?: number): TableColumnsType<ClaimCaseForExport> =>
  useMemo(
    () => [
      {
        key: "idx",
        render: (_, _r, idx) => (page == null || pageSize == null ? idx + 1 : (page - 1) * pageSize + idx + 1),
        title: "Seq No",
        width: 10,
      },
      {
        dataIndex: ["claim_case_same_event_groups_claim_case", "claim_case_same_event_group", "code"],
        key: "code",
        title: "CLM no",
        width: 10,
      },
      {
        dataIndex: ["insured_certificate", "policy"],
        key: "policy_number",
        render: (policy, claim): string => policy.policy_number,
        title: "POL no",
        width: 30,
      },
      {
        key: "po_name",
        render: (_, record) =>
          record.insured_certificate.policy.types === "Individual"
            ? record.insured_certificate.policy.insured_person?.name
            : record.insured_certificate.policy.corporate_company?.name,
        title: "POLICY OWNER",
        width: 40,
      },
      {
        dataIndex: "insured_certificate",
        key: "effective_date",
        render: (cert) => [cert.issued_at, cert.dued_at].map((item) => utils.formatDate(item, FORMATTER.DATE_FORMAT)).join(" - "),
        title: "EFFECTIVE DATE",
        width: 70,
      },
      {
        dataIndex: ["claim_case_same_event_groups_claim_case", "claim_case_same_event_group", "slv_claim_case_same_event_group", "claimant_name"],
        key: "claimant_name",
        title: "CLAIMANT NAME",
        width: 40,
      },
      {
        dataIndex: ["claim_case_same_event_groups_claim_case", "claim_case_same_event_group", "slv_claim_case_same_event_group", "claimant_phone"],
        key: "claimant_phone",
        title: "PHONE NO",
        width: 40,
      },
      {
        dataIndex: ["insured_certificate", "insured_person", "name"],
        ellipsis: true,
        key: "la_name",
        title: "INSURED NAME",
        width: 60,
      },
      {
        dataIndex: ["insured_certificate", "insured_person", "paper_id"],
        ellipsis: true,
        key: "la_paper_id",
        title: "INSURED ID",
        width: 30,
      },
      {
        dataIndex: ["insured_certificate", "insured_person", "insured_number"],
        ellipsis: true,
        key: "la_insured_number",
        title: "INSURED CLIENT ID",
        width: 30,
      },
      {
        dataIndex: ["insured_certificate", "insured_person", "dob"],
        ellipsis: true,
        key: "la_dob",
        render: (dob) => utils.formatDate(dob, FORMATTER.DATE_FORMAT),
        title: "INSURED BIRTHDAY",
        width: 30,
      },
      {
        dataIndex: ["insured_certificate", "insured_person", "gender"],
        ellipsis: true,
        key: "la_gender",
        render: (gender) => GENDER_MAP[gender],
        title: "INSURED GENDER",
        width: 20,
      },
      {
        dataIndex: ["insured_certificate", "certificate_code"],
        ellipsis: true,
        key: "certificate_code",
        render: (certificate_code) => certificate_code.split("-").at(-1),
        title: "COVERAGE NO",
        width: 10,
      },
      {
        dataIndex: "created_at",
        key: "created_at",
        render: (_val) => utils.formatDate(_val, FORMATTER.DATE_FORMAT),
        title: "EVENT DATE",
        width: 30,
      },
      {
        key: "file_received_date",
        render: (_, record) => (record.claim_documents[0]?.created_at == null ? null : utils.formatDate(record.claim_documents[0]?.created_at, FORMATTER.DATE_FORMAT)),
        title: "FILE RECEIVED DATE",
        width: 30,
      },
      {
        dataIndex: "admission_date",
        key: "admission_date",
        render: (_val) => utils.formatDate(_val, FORMATTER.DATE_FORMAT),
        title: "ADMISSION DATE",
        width: 30,
      },
      {
        dataIndex: "discharge_date",
        key: "discharge_date",
        render: (_val) => utils.formatDate(_val, FORMATTER.DATE_FORMAT),
        title: "DISCHARGE DATE",
        width: 30,
      },
      {
        dataIndex: "claim_case_details",
        key: "sl_ip_1_request",
        render: (_, record) => record.claim_case_details.find((ccd) => ccd.plan_insured_benefit.insured_benefit.code === "SL_IP_1")?.request_time ?? 0,
        title: "NO. OF HOSPITALIZATION DAYS THAT CLIENTS REQUESTED",
        width: 30,
      },
      {
        dataIndex: "claim_case_details",
        key: "sl_ip_1_paid",
        render: (_, record) => record.claim_case_details.find((ccd) => ccd.plan_insured_benefit.insured_benefit.code === "SL_IP_1")?.paid_time ?? 0,
        title: "NO. OF HOSPITALIZATION DAYS APPROVED",
        width: 30,
      },
      {
        dataIndex: "claim_case_details",
        key: "sl_ip_2_paid",
        render: (_, record) => record.claim_case_details.find((ccd) => ccd.plan_insured_benefit.insured_benefit.code === "SL_IP_2")?.paid_time ?? 0,
        title: "NO. OF ICU DAYS APPROVED",
        width: 30,
      },
      {
        dataIndex: "claim_case_details",
        key: "sl_sg_paid",
        render: (_, record) => record.claim_case_details.find((ccd) => ccd.plan_insured_benefit.insured_benefit.code === "SL_SG_1")?.paid_time ?? 0,
        title: "NO. OF SURGERY APPROVED",
        width: 30,
      },
      {
        dataIndex: "actions_logs",
        key: "approved_date",
        render: (_, record) =>
          record.action_logs.some((log) => log.new_value === "Approved") === true
            ? utils.formatDate(record.action_logs.find((log) => log.new_value === "Approved")?.created_at, FORMATTER.DATE_FORMAT)
            : "",
        title: "APPROVED DATE",
        width: 30,
      },
      {
        dataIndex: ["claim_case_payment", "requested_amount"],
        key: "requested_amount",
        render: (amount) => (amount == null ? "" : utils.formatNumber(amount)),
        title: "CLAIM REQUESTED AMOUNT",
        width: 30,
      },
      {
        dataIndex: ["claim_case_payment", "actual_paid_amount"],
        key: "actual_paid_amount",
        render: (amount) => (amount == null ? "" : utils.formatNumber(amount)),
        title: "CLAIM ACTUAL AMOUNT",
        width: 30,
      },
      {
        dataIndex: "actions_logs",
        key: "payment_date",
        render: (_, record) =>
          record.action_logs.some((log) => log.new_value === "Paid") === true
            ? utils.formatDate(record.action_logs.find((log) => log.new_value === "Paid")?.created_at, FORMATTER.DATE_FORMAT)
            : "",
        title: "PAYMENT DATE",
        width: 30,
      },
      {
        dataIndex: ["claim_case_beneficiary", "payment_method"],
        key: "payment_method",
        render: (paymentMethod) => {
          if (paymentMethod === PayoutPaymentMethodsEnum.BankTransfers) {
            return "Chuyển khoản";
          }
          if (paymentMethod === PayoutPaymentMethodsEnum.Checks) {
            return "Tại ngân hàng";
          }
          return "Chuyển phí về Sunlife";
        },
        title: "PAYMENT METHOD",
        width: 30,
      },
      {
        dataIndex: ["claim_case_beneficiary", "beneficiary_name"],
        key: "beneficiary_name",
        title: "PAYEE NAME",
        width: 30,
      },
      {
        dataIndex: "actions_logs",
        key: "investigation",
        render: (_, record) => (record.action_logs.some((log) => log.new_value === "INVESTIGATING") === true ? "Y" : "N"),
        title: "INVESTIGATION",
        width: 30,
      },
      {
        dataIndex: "hospitalBenefitUsedInOrder",
        key: "benefit_1",
        title: "ACCUMULATED HOSPITALIZED DAY IN POLICY YEAR",
        width: 30,
      },
      {
        dataIndex: "icuBenefitUsedInOrder",
        key: "benefit_2",
        title: "ACCUMULATED ICU DAY IN POLICY YEAR",
        width: 30,
      },
      {
        dataIndex: "surgeryBenefitUsedInOrder",
        key: "benefit_3",
        title: "ACCUMULATED SURGERY TIME IN POLICY YEAR",
        width: 30,
      },
      {
        dataIndex: "amountBenefitUsedInOrder",
        key: "benefit_4",
        render: (amount) => (amount == null ? "" : utils.formatNumber(amount)),
        title: "ACCUMULATED CLAIM AMOUNT PAID",
        width: 30,
      },
      {
        dataIndex: "status",
        key: "status",
        render: (_, claim) => {
          const MappingClaimStatusToSlvRecordStatus: Partial<Record<ClaimCaseStatusesEnum, string>> = {
            Approved: "Admit",
            APPROVED_WAITING_FOR_GRADE_PERIOD_END: "Admit",
            Declined: "Decline",
            Initialize: "Accept",
            InProgress: "Accept",
            InsurerAssessed: "Accept",
            INVESTIGATING: "Accept",
            Paid: "Admit",
            Pending: "Accept",
            PRESENT_CASE_COMPLETED: "Accept",
            SUPPLEMENTED_VERIFIED: "Accept",
            Suspension: "Close",
            Updated: "Accept",
            Waiting: "Accept",
          };
          const haltedLog = claim.action_logs.find((log) => log.new_value === "Halted");
          const status = claim.status === "Halted" && haltedLog != null ? haltedLog.old_value : claim.status;
          return MappingClaimStatusToSlvRecordStatus[status] ?? claim.claim_case_status.comment;
        },
        title: "RECORD STATUS",
        width: 30,
      },
      {
        key: "decline",
        render: (_, record) => record.claim_case_decline_histories[0]?.decline_code_record?.insurer_decline_code?.[0]?.insurer_decline_code ?? "",
        title: "DECLINE RECORD REASON",
        width: 30,
      },
      {
        dataIndex: ["claim_case_same_event_groups_claim_case", "claim_case_same_event_group", "slv_claim_case_same_event_group", "status"],
        key: "group_status",
        render: (status) => CaseStatusMapping[status],
        title: "CASE STATUS",
        width: 30,
      },
      {
        key: "decline",
        render: (record) => {
          const slvClaimGroup = record.claim_case_same_event_groups_claim_case?.claim_case_same_event_group?.slv_claim_case_same_event_group;
          const groupStatus = SlvClaimCaseStatusMapping[slvClaimGroup?.status];
          return (() => {
            switch (groupStatus) {
              case "CLM_STAT_13": {
                return record.claim_case_decline_histories[0]?.decline_code_record.insurer_decline_code[0]?.insurer_decline_code;
              }
              case "CLM_STAT_CLOS_SA": {
                return "CLM_CLOS_CA_REASN_OVR";
              }
              case "CLM_STAT_15": {
                return slvClaimGroup.case_reason_investigator;
              }
              case "CLM_STAT_RE_PEN": {
                return slvClaimGroup.case_reason_re_rw;
              }
              default: {
                return null;
              }
            }
          })();
        },
        title: "DECLINE CASE REASON",
        width: 30,
      },
      {
        dataIndex: "diagnosis",
        key: "diagnosis",
        title: "DIAGNOSIS",
        width: 80,
      },
      {
        key: "icd",
        render: (_, record) => record.claim_case_assessed_diagnoses.map((diagnosis) => diagnosis.icd.value).join(", "),
        title: "ICD CODE",
        width: 30,
      },
      {
        dataIndex: ["medical_provider", "name"],
        key: "medical_provider_name",
        title: "HOSPITAL NAME",
        width: 60,
      },
      {
        dataIndex: ["insured_certificate", "insured_certificate_underwriting_notes"],
        key: "exclusion",
        render: (notes) => notes.map((note) => note.content).join(". "),
        title: "EXCLUTION",
        width: 60,
      },
      {
        dataIndex: ["claim_notes"],
        key: "record_comment",
        render: (notes) => notes.map((note) => note.content).join(". "),
        title: "RECORD_COMMENT",
        width: 60,
      },
      {
        dataIndex: ["meta_v2", "assessor", "name"],
        key: "accessor_name",
        title: "Thẩm định viên",
        width: 60,
      },
      {
        key: "manager",
        render: (_, record) =>
          record.action_logs.find((log) => ["Approved", "APPROVED_WAITING_FOR_GRADE_PERIOD_END", "Declined", "Suspension"].includes(log.new_value ?? ""))?.user?.name,
        title: "Người quản lý",
        width: 60,
      },
    ],
    [page, pageSize],
  );
