import { useMount, useToggle } from "ahooks";
import { Button, Divider, Drawer, Layout, List, notification, Space, Tabs, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import PapayaIf from "app/common/components/PapayaIf";
import RibbonedClaimDocument from "app/common/components/RibbonedClaimDocument";
import ClaimDocumentFragment from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseDocumentScreen/component/graphql/ClaimDocumentFragment";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { useAuth } from "contexts/AuthContext";
import buildFileThumbnail from "libs/buildFileThumbnail";
import { getZeroOrNumber } from "libs/hidash";
import openPdfWithSearchModal from "libs/openPdfWithSearchModal";
import { BadgeDollarSign, Ban, Blocks, Boxes, Gem, Hourglass, Images, ReceiptText } from "lucide-react";
import { useMemo } from "react";
import Confetti from "react-confetti";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { graphql as codegenThisGraphqlTag, readFragment } from "sdk/v2/graphql";
import { If } from "vite-plugin-react-control-statements";

import styles from "./ClaimCaseScreen.module.less";
import TabConfig from "./topbar";

export const ClaimDocumentsFragment = codegenThisGraphqlTag(
  /* GraphQL */ `
    fragment ClaimDocuments on claim_cases {
      claim_documents(where: { type: { _nin: [CLAIM_PENDING_SAMPLE_FILE] }, _not: { file: { child_files: {} } } }) {
        ...ClaimDocument
      }
    }
  `,
  [ClaimDocumentFragment],
);

const ClaimCaseScreen = ({ setPageTitle }: { setPageTitle: (title) => void }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { claimCaseId } = useParams();
  const { user } = useAuth();
  const [open, { toggle }] = useToggle();
  const { claim, claimCaseRoutePath, claimType, registerSaveStatusCallback } = useClaim();
  const claimCaseRoute = claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "");
  const activeKey = useMemo(() => {
    const keyInPath = location.pathname.replace(claimCaseRoute ?? "", "");
    return TabConfig.find((top) => keyInPath.startsWith(top.subPath))?.key;
  }, [claimCaseRoute, location.pathname]);
  const [showConfetti, { setLeft, setRight }] = useToggle(false);

  useMount(() => {
    if (claimType.healthCare === false) {
      return;
    }
    registerSaveStatusCallback((newStatus, claimCase) => {
      if (claimCase == null) return;
      if (newStatus === "Approved" && claimCase.is_direct_billing === false && getZeroOrNumber(claimCase.tatInHourUpToNow) < 4 && claimCase.genesis_claim_id == null) {
        setRight();
        notification.success({
          description: `Đã được hoàn tất thẩm định với thời gian TAT là: ${getZeroOrNumber(claimCase.tatInHourUpToNow)} giờ`,
          duration: 10,
          message: `Chúc mừng ${user.name}`,
          onClose: () => {
            setLeft();
          },
          placement: "bottom",
        });
      }
    });
  });

  useHotkeys("alt+1", toggle);
  setPageTitle(claim?.code);

  const contractFile = claim?.insured_certificate.policy.policy_documents
    .find((doc) => doc.type === "CONTRACT")
    ?.file?.alternative_format_files.find((f) => f.mime_type === "application/pdf");

  const errorMessage = "Nội dung chưa được set up, vui lòng liên hệ OPS để được hỗ trợ.";

  const claimDocuments = (() => {
    if (claim == null) return null;
    return readFragment(ClaimDocumentsFragment, claim) as ResultOf<typeof ClaimDocumentsFragment>;
  })();

  return (
    <Layout className="items-stretch">
      <If condition={showConfetti}>
        <Confetti />
      </If>
      <Content>
        <Tabs
          activeKey={activeKey}
          className={styles.topBar}
          items={TabConfig.map((tb) => ({
            key: tb.key,
            label: (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  const path = `${claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "")}${tb.subPath}`;
                  if (e.ctrlKey || e.metaKey) {
                    window.open(path, "_blank");
                  } else {
                    navigate(path);
                  }
                }}
                to={`${claimCaseRoutePath?.replace(":claimCaseId", claimCaseId ?? "")}${tb.subPath}`}
              >
                {tb.title}
              </Link>
            ),
            disabled: tb.isLock,
          }))}
        />
        <Outlet />
      </Content>
      <Sider
        style={{
          height: "100%",
          backgroundColor: "#fff",
          border: "1px solid #d9d9d9",
          color: "#fff",
          lineHeight: "120px",
          minHeight: "94.5vh",
          position: "sticky",
          right: 0,
          textAlign: "center",
          top: 0,
        }}
        trigger={null}
        width={50}
      >
        <Space direction="vertical" size={15} styles={{ item: { lineHeight: 0 } }}>
          <Tooltip title="Bảng quyền lợi">
            <Button
              icon={<Boxes size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_benefits,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_benefits == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản thời gian chờ">
            <Button
              icon={<Hourglass size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_waiting_period,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_waiting_period == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản đồng chi trả (copay)">
            <Button
              icon={<BadgeDollarSign size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_copay,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_copay == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản bổ sung">
            <Button
              icon={<ReceiptText size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_additional_clauses,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_additional_clauses == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản thỏa thuận đặc biệt">
            <Button
              icon={<Gem size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_special_agreement,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_special_agreement == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản mở rộng">
            <Button
              icon={<Blocks size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_extended_clauses,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_extended_clauses == null ? undefined : "link"}
            />
          </Tooltip>
          <Tooltip title="Điều khoản loại trừ">
            <Button
              icon={<Ban size={30} />}
              onClick={() => {
                openPdfWithSearchModal({
                  content: claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_exclusion_clauses,
                  errorMessage,
                  url: contractFile?.url,
                });
              }}
              type={claim?.insured_certificate.policy.policy_setting?.contract_phrase_reference_exclusion_clauses == null ? undefined : "link"}
            />
          </Tooltip>
          <Divider className="border-[#7cb305]" />
          <Tooltip title="Hình ảnh/chứng từ (Alt + 1)">
            <Button icon={<Images size={30} />} onClick={toggle} type="link" />
          </Tooltip>
        </Space>
      </Sider>
      <Drawer
        extra={
          <Space>
            <Button onClick={toggle} type="primary">
              Đóng
            </Button>
          </Space>
        }
        onClose={toggle}
        open={open}
        placement="right"
        title="Hình ảnh/chứng từ"
        width="55%"
      >
        <List
          dataSource={claimDocuments?.claim_documents}
          itemLayout="vertical"
          renderItem={(doc) => {
            if (doc.file == null) return null;
            const { fullImg, image, isPdf } = buildFileThumbnail({ fullSize: 960, mime_type: doc.file.mime_type, size: 960, url: doc.file.url });
            return (
              <List.Item key={doc.id} style={{ width: 1008, textAlign: "center" }}>
                <RibbonedClaimDocument claimDocument={doc}>
                  <PapayaIf condition={isPdf} else={<img src={fullImg} />}>
                    <Link target="_blank" to={doc.file.url ?? ""}>
                      <img src={image} />
                    </Link>
                  </PapayaIf>
                </RibbonedClaimDocument>
              </List.Item>
            );
          }}
          size="large"
        />
      </Drawer>
    </Layout>
  );
};

export default ClaimCaseScreen;
