import type { ParagraphProps } from "antd/lib/typography/Paragraph";

import { useHover } from "ahooks";
import Typography from "antd/lib/typography";
import { type FC, useRef } from "react";

const { Paragraph } = Typography;

interface ICopyable extends ParagraphProps {
  childClassName?: string;
  /**
   * @deprecated use `text` instead
   */
  copyableText?: string;
  show?: boolean;
}

const Copyable: FC<ICopyable> = ({ children, childClassName, copyable, copyableText = false, show = true, ...rest }) => {
  const ref = useRef<HTMLElement | null>(null);
  const isHovering = useHover(ref);

  const copyableProps = show || isHovering ? { ...copyable, tooltips: copyable?.tooltips ?? false } : false;

  if (children == null && copyable?.text == null) {
    return "--";
  }

  if (childClassName != null) {
    return (
      <Paragraph copyable={copyableProps} ref={ref} {...rest}>
        <span className={childClassName}>{children}</span>
      </Paragraph>
    );
  }

  return (
    <Paragraph copyable={copyableProps} ref={ref} {...rest}>
      {copyableText === false ? children : copyableText}
    </Paragraph>
  );
};

export default Copyable;
