import type { CSSProperties, FC } from "react";

import { Badge, Button, Divider, Empty, Flex, Space, Typography } from "antd";
import { PORTAL_PATH } from "app/portal/config/paths";
import { useNotificationContext } from "contexts/NotificationContext";
import { UPDATE_NOTIFICATION_AS_SEEN_MUTATION } from "contexts/graphql/queries";
import usePMutation from "contexts/usePMutation";
import utils from "libs/utils";
import { isEmpty } from "lodash";
import { Link, generatePath } from "react-router-dom";

import PapayaIf from "../PapayaIf";

const { Text } = Typography;

const failedHistoryItemStyles: CSSProperties = {
  borderRadius: 4,
  padding: 8,
};

const FailedCorrespondences: FC = () => {
  const { failedCorrespondenceHistoriesAggregate, notifications, refetchNotifications } = useNotificationContext();
  const [updateNotification] = usePMutation(UPDATE_NOTIFICATION_AS_SEEN_MUTATION);

  return (
    <PapayaIf condition={isEmpty(notifications) === false} else={<Empty />}>
      <Space direction="vertical" split={<Divider className="m-0" />}>
        {failedCorrespondenceHistoriesAggregate?.nodes.map(({ correspondence_history, created_at, id, is_read }) => {
          const templateName = correspondence_history?.policy_correspondence?.template.name ?? correspondence_history?.company_correspondence?.template.name ?? "--";
          const claimCase = correspondence_history?.claim_case;
          if (claimCase?.claim_case_id == null) return null;
          return (
            <Flex align="center" justify="space-between" key={id} style={failedHistoryItemStyles}>
              <Link
                onClick={() => {
                  updateNotification({
                    onCompleted: refetchNotifications,
                    variables: {
                      notificationId: id,
                    },
                  });
                }}
                to={`${generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: claimCase.claim_case_id })}/correspondence/history/${correspondence_history?.id}`}
              >
                <Space direction="vertical" size={4}>
                  <Space>
                    <PapayaIf condition={is_read === false}>
                      <Badge status="error" />
                    </PapayaIf>
                    <Text type={is_read === true ? "secondary" : undefined}>Lỗi gửi thư: </Text>
                    <Text style={{ color: is_read === true ? "#1677ff" : "#1890ff" }} type={is_read === true ? "secondary" : undefined}>
                      {claimCase.code}
                    </Text>
                  </Space>
                  <Space align="start">
                    <Text type={is_read === true ? "secondary" : undefined}>{templateName}</Text>
                  </Space>
                  <Text type="secondary">{utils.formatDate(created_at)}</Text>
                </Space>
              </Link>
              <PapayaIf condition={is_read === false}>
                <Button
                  danger
                  onClick={() => {
                    updateNotification({
                      onCompleted: refetchNotifications,
                      variables: {
                        notificationId: id,
                      },
                    });
                  }}
                  size="small"
                  type="text"
                >
                  Bỏ qua
                </Button>
              </PapayaIf>
            </Flex>
          );
        })}
      </Space>
    </PapayaIf>
  );
};

export default FailedCorrespondences;
