import { graphql } from "sdk/v2/graphql";

const CLAIM_ICON_FRAGMENT = graphql(`
  fragment ClaimIcons on claim_cases {
    id
    code
    genesis_claim_id
    is_accessible
    is_direct_billing
    is_jet
    source
    stp_status
    tatDayHourMin @client
    tatInHourUpToNow @client
    claim_source {
      id: value
      comment
      value
    }
    insured_certificate {
      id
      insured_person {
        id
        labels {
          id
          label {
            id
            name
            color
            description
          }
        }
      }
      policy {
        insurer_company {
          id
          company_id
        }
        policy_setting {
          id
          tat_max
          turn_around_time_commitment
        }
      }
    }
    labels {
      label {
        id
        name
        color
        description
      }
    }
    source_emails(where: { email: { delivery_type: { _neq: ASSESSMENT_CORRESPONDENCE } } }) {
      id
      email_id
    }
  }
`);

const MASKED_CLAIM_ICON_FRAGMENT = graphql(`
  fragment MaskedClaimIcons on claim_cases {
    id
    code
    is_accessible
    is_direct_billing
    is_jet
    stp_status
    tatDayHourMin @client
    tatInHourUpToNow @client
    claim_source {
      id: value
      comment
      value
    }
    insured_certificate {
      id
      insured_person {
        id
        labels {
          id
          label {
            id
            name
            color
            description
          }
        }
      }
      policy {
        insurer_company {
          id
          company_id
        }
        policy_setting {
          id
          turn_around_time_commitment
        }
      }
    }
    labels {
      label {
        id
        name
        color
        description
      }
    }
    source_emails(where: { email: { delivery_type: { _neq: ASSESSMENT_CORRESPONDENCE } } }) {
      id
      email_id
    }
  }
`);

export { CLAIM_ICON_FRAGMENT, MASKED_CLAIM_ICON_FRAGMENT };
