import localforage from "localforage";
// import { groupBy } from "lodash";

export const saveTraceLog = async ({ data, topic }) => {
  if (import.meta.env.DEV) {
    //
  } else {
    localforage.setItem(`${topic}-${Date.now()}`, { data, topic });
  }
};

export const sendTraceLog = async () => {
  const keys = await localforage.keys();

  if (keys.length === 0) return;

  if (keys.length < 100) {
    return;
  }

  // const topics = groupBy(await Promise.all(keys.slice(0, 100).map((key) => localforage.getItem(key))), "topic");

  // Object.entries(topics).forEach(([topic, data]) => {
  //   if (topic === "undefined") return;
  //   fetch("https://pub-sub-logs-sw4ckqn23a-as.a.run.app", {
  //     body: JSON.stringify({
  //       message: JSON.stringify(data.map((d) => d?.data).filter(Boolean)),
  //       topic,
  //     }),
  //     headers: {
  //       Authorization: "RW1Md1pKRUJjMlJLeEMtVmFZS1k6c1FVNHhPZXJTN2l6WTZPazFLNjZ4dw==",
  //       "Content-Type": "application/json",
  //     },
  //     method: "POST",
  //   }).catch();
  // });

  keys.forEach((key) => localforage.removeItem(key));
};
