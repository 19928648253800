import { graphql } from "sdk/v2/graphql";

export const CLAIM_CASES_FOR_SLV_TPA_REPORT_QUERY = graphql(`
  query ClaimCasesForSlvTpaReportQuery($where: claim_cases_bool_exp!, $withAggregate: Boolean!, $offset: Int, $limit: Int, $insurerId: uuid!) {
    claim_cases(where: $where, order_by: { created_at: asc }, limit: $limit, offset: $offset) {
      id
      admission_date
      claim_case_id
      code
      diagnosis
      discharge_date
      start_date
      status
      created_at
      action_logs(order_by: { created_at: desc }) {
        id
        new_value
        old_value
        created_at
        user {
          id
          name
        }
      }
      claim_case_assessed_diagnoses {
        id
        icd {
          id
          value
        }
      }
      claim_case_beneficiary {
        id
        beneficiary_name
        payment_method
      }
      claim_case_decline_histories(order_by: { created_at: desc }, limit: 1) {
        id
        decline_code_record {
          insurer_decline_code(limit: 1, where: { insurer_id: { _eq: $insurerId } }) {
            id
            description
            insurer_decline_code
          }
        }
      }
      claim_case_details {
        id
        paid_time
        request_time
        plan_insured_benefit {
          id
          insured_benefit {
            id
            code
          }
        }
      }
      claim_case_payment {
        id
        actual_paid_amount
        requested_amount
      }
      claim_case_same_event_groups_claim_case {
        id
        claim_case_same_event_group {
          id
          code
          slv_claim_case_same_event_group {
            id
            case_reason_investigator
            case_reason_re_rw
            claimant_name
            claimant_phone
            status
          }
        }
      }
      claim_case_status {
        comment
      }
      claim_documents(limit: 1) {
        id
        created_at
      }
      claim_notes(where: { share_to_insurer: { _eq: true } }) {
        id
        content
      }
      correspondence_histories(where: { files: {} }) {
        id
        created_at
      }
      insured_certificate {
        id
        certificate_code
        dued_at
        issued_at
        insured_certificate_underwriting_notes {
          id
          note
        }
        insured_person {
          id
          name
          dob
          gender
          insured_number
          paper_id
        }
        policy {
          id
          policy_number
          sum_assured
          types
          corporate_company {
            id
            name
            companyCode
          }
          insured_person {
            id
            name
          }
        }
        policy_plan {
          id
          plan_code
          plan_name
        }
      }
      medical_provider {
        id
        name
      }
      meta_v2 {
        assessor {
          id
          name
        }
      }
    }

    claim_cases_aggregate(where: $where) @include(if: $withAggregate) {
      aggregate {
        count
      }
    }
  }
`);

export const CLAIM_CASES_FOR_SLV_TPA_REPORT_AGGREGATE_QUERY = graphql(`
  query ClaimCasesForSlvTpaReportAggregate($where: claim_cases_bool_exp) {
    claim_cases_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

export const SLV_PLAN_INSURED_BENEFITS_QUERY = graphql(`
  query SlvPlanInsuredBenefits($where: plan_insured_benefits_bool_exp!) {
    plan_insured_benefits(where: $where) {
      id
      insured_benefit {
        id
        code
      }
    }
  }
`);

export const SAME_INSURED_CERTIFICATE_CLAIM_CASES_QUERY = graphql(`
  query SameInsuredCertificateClaimCases($where: claim_cases_bool_exp!) {
    claim_cases(where: $where) {
      id
      code
      claim_case_payment {
        id
        actual_paid_amount
      }
    }
  }
`);

export const ACTION_LOGS_QUERY = graphql(`
  query ActionLogsQuery($where: audit_logs_record_status_changes_bool_exp!) {
    audit_logs_record_status_changes(where: $where) {
      id
      new_value
      old_value
      created_at
    }
  }
`);
