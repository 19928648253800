import { graphql } from "sdk/v2/graphql";

export const CONTINUE_INITIALIZE_STP_FLOW_MUTATION = graphql(`
  mutation ContinueInitializeStpFlow($claimCaseId: UUID!, $tokenType: ContinueStpFlowTokenType) {
    continueInitializeStpFlow(claimCaseId: $claimCaseId, tokenType: $tokenType)
  }
`);

export const UpdateWillSuspendMutation = graphql(`
  mutation UpdateWillSuspendMutation($claimCaseId: uuid!, $willSuspend: Boolean!) {
    update_claim_cases_by_pk(pk_columns: { claim_case_id: $claimCaseId }, _set: { will_suspend: $willSuspend }) {
      id
    }
  }
`);
