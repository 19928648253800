import { PageHeader } from "@ant-design/pro-layout";
import { Layout } from "antd";
import ErrorBoundary from "app/common/components/ErrorBoundary";
import PapayaIf from "app/common/components/PapayaIf";
import Spin from "app/common/components/Spin";
import NoPermissionScreen from "app/common/screens/403NoPermissionScreen";
import { PORTAL_PATH } from "app/portal/config/paths";
import ClaimProvider from "app/portal/screens/ClaimPortal/ClaimContext/ClaimProvider";
import { ROUTES_PATH } from "app/routes";
import { useAuth } from "contexts/AuthContext";
import MainLayout from "layouts/MainLayout";
import { isArray } from "lodash";
import { Fragment, Suspense, useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import type { IRoute } from "./config/routes";

import { routes as portalRoutes } from "./config/routes";
import styles from "./Portal.module.css";
import ClaimCaseHeader from "./screens/ClaimPortal/ClaimCaseScreen/ClaimCaseTopBar";
import { ClaimAssistantProvider } from "./screens/ClaimPortal/Contexts/ClaimAssistantContext/ClaimAssistantContext";

const WrappedComponent = ({ route }: { route: IRoute }) => {
  const { setRequestPermissions } = useAuth();
  if (route.permissions) setRequestPermissions(route.permissions);
  document.title = route.title ?? "";
  return (
    <route.comp
      setPageTitle={(text) => {
        document.title = text != null && text !== "" ? `${text} - ${route.title}` : (route.title ?? "");
      }}
      {...route.extendProps}
    />
  );
};
const isNotClaimCaseScreen = (route: IRoute) =>
  route.path !== PORTAL_PATH.CLAIM_CASE &&
  route.path !== PORTAL_PATH.HC_CLAIM_CASE &&
  route.path !== PORTAL_PATH.FWD_MR_CLAIM_CASE &&
  route.path !== PORTAL_PATH.MBAL_HS_CLAIM_CASE &&
  route.path !== PORTAL_PATH.SLV_HS_CLAIM_CASE;
const isOneOfClaimCaseScreen = (route: IRoute) =>
  route.path === PORTAL_PATH.CLAIM_CASE ||
  route.path === PORTAL_PATH.HC_CLAIM_CASE ||
  route.path === PORTAL_PATH.FWD_MR_CLAIM_CASE ||
  route.path === PORTAL_PATH.MBAL_HS_CLAIM_CASE ||
  route.path === PORTAL_PATH.SLV_HS_CLAIM_CASE;

const Portal = () => {
  const navigate = useNavigate();
  const { hasAccessPermissions } = useAuth();

  const routes = useMemo(
    () =>
      portalRoutes.map((route) => {
        if (!hasAccessPermissions(route.permissions)) {
          return <Route element={<NoPermissionScreen />} key={route.path} path={route.path} />;
        }

        let routeTitle;
        let hasPageTitleHeight = false;
        if (route.title !== "" && route.title != null && isNotClaimCaseScreen(route)) {
          routeTitle = <PageHeader className={styles.pageHeader} onBack={() => navigate(-1)} subTitle={route.subTitle} title={route.title} />;
          hasPageTitleHeight = true;
        }

        const hasSubRoutes = isArray(route.routes) && route.routes.length > 0;
        const CLAIM_LIST_ROUTES = new Set([
          PORTAL_PATH.CLAIM_LIST,
          PORTAL_PATH.FWD_MR_CLAIM_LIST,
          PORTAL_PATH.HC_CLAIM_LIST,
          PORTAL_PATH.MBAL_CLAIM_LIST,
          PORTAL_PATH.SLV_HS_CLAIM_LIST,
        ]);
        const CLAIM_DETAIL_ROUTES: string[] = [
          PORTAL_PATH.CLAIM_CASE,
          PORTAL_PATH.HC_CLAIM_CASE,
          PORTAL_PATH.FWD_MR_CLAIM_CASE,
          PORTAL_PATH.MBAL_HS_CLAIM_CASE,
          PORTAL_PATH.FWD_MR_OCR_QC_DETAIL,
          PORTAL_PATH.SLV_HS_CLAIM_CASE,
        ];
        const needClaimProvider = [PORTAL_PATH.CLAIM_CASE_ASSESSOR, PORTAL_PATH.CLAIM_CASE_ASSESSORS, PORTAL_PATH.TEST_CLAIM_CASE, ...CLAIM_DETAIL_ROUTES].includes(route.path);
        return (
          <Route
            element={
              <PapayaIf
                condition={needClaimProvider}
                else={
                  <div className={styles.belowTopBar}>
                    {routeTitle}
                    <div
                      style={{
                        height: hasPageTitleHeight ? "calc(100% - 56px)" : "100%",
                        padding: route.extendProps?.overrideStyles?.padding ?? (hasSubRoutes || CLAIM_LIST_ROUTES.has(route.path) ? 0 : 16),
                        // overflow: "auto",
                      }}
                    >
                      <WrappedComponent route={route} />
                    </div>
                  </div>
                }
              >
                <ClaimProvider name="1">
                  <ClaimAssistantProvider>
                    <div
                      style={{
                        height: hasPageTitleHeight ? "calc(100% - 56px)" : "100%",
                        overflow: "auto",
                        padding: hasSubRoutes || CLAIM_LIST_ROUTES.has(route.path) ? 0 : 16,
                      }}
                    >
                      <WrappedComponent route={route} />
                    </div>
                  </ClaimAssistantProvider>
                </ClaimProvider>
              </PapayaIf>
            }
            errorElement={<ErrorBoundary />}
            key={route.path}
            path={route.path.replace(ROUTES_PATH.PORTAL.replace("*", ""), "")}
          >
            {route.routes?.map((subRoute) => {
              const hasScreenHeader = subRoute.title != null && subRoute.title !== "" && isNotClaimCaseScreen(route);
              let contentContainerHeight = "calc(100% - 129px)";

              if (hasScreenHeader) contentContainerHeight = "calc(100% - 62px)";

              const subRouteOverrideStyles = subRoute.extendProps?.overrideStyles;
              return (
                <Route
                  element={
                    <Fragment key={subRoute.path}>
                      {isOneOfClaimCaseScreen(route) ? (
                        // <ClaimProvider name="2">
                        //   <ClaimAssistantProvider>
                        <Layout>
                          <Layout.Header
                            style={{
                              height: 64,
                              backdropFilter: "blur(6px)",
                              backgroundColor: "transparent",
                              borderBottom: "1px solid #d9d9d9",
                              lineHeight: "64px",
                              padding: 0,
                              position: "sticky",
                              textAlign: "center",
                              top: 62,
                              zIndex: 5,
                            }}
                          >
                            <ClaimCaseHeader title={`${subRoute.title}`} />
                          </Layout.Header>
                          <Layout className="mt-[10px]">
                            <subRoute.comp {...subRoute.extendProps} />
                          </Layout>
                        </Layout>
                      ) : (
                        //   </ClaimAssistantProvider>
                        // </ClaimProvider>
                        <>
                          {hasScreenHeader ? <PageHeader className={styles.pageHeader} title={subRoute.title} /> : null}
                          <div
                            style={{
                              height: contentContainerHeight,
                              padding: "8px",
                              ...subRouteOverrideStyles,
                            }}
                          >
                            <subRoute.comp {...subRoute.extendProps} />
                          </div>
                        </>
                      )}
                    </Fragment>
                  }
                  errorElement={<ErrorBoundary />}
                  key={`${route.path}${subRoute.path}`}
                  path={subRoute.path}
                />
              );
            })}
          </Route>
        );
      }),
    [hasAccessPermissions, navigate],
  );

  return (
    <MainLayout>
      <Suspense fallback={<Spin style={{ marginTop: 100, minWidth: "100%" }} />}>
        <Routes>{routes}</Routes>
      </Suspense>
    </MainLayout>
  );
};

export default Portal;
