import type { ItemType } from "antd/lib/menu/hooks/useItems";
import type { FC } from "react";

import { Badge, Button, Image, Input, Layout, Menu, Popover, Space, Typography } from "antd";
import PapayaIf from "app/common/components/PapayaIf";
import useMedicalProviderForHospital from "app/common/hooks/useMedicalProviderForHospital";
import { PORTAL_PATH } from "app/portal/config/paths";
import DebuggerScreen from "app/portal/screens/DebuggerScreen";
import FolderUnread from "assets/images/FolderUnread.png";
import PapayaCareIcon from "assets/images/PapayaCareIcon.png";
import PapayaLogo from "assets/images/PapayaLogo.png";
import ScanIcon from "assets/images/ScanIcon.png";
import { ASSIGNED_CLAIM_CASE_STATUSES, ASSIGNED_CLAIM_CASE_STATUSES_FOR_FWD, INSURER_COMPANY_IDS, NON_HEALTHCARE_COMPANY_IDS } from "config/constants";
import { useAuth } from "contexts/AuthContext";
import { useFirebaseRemoteConfig } from "contexts/FirebaseRemoteConfigContext";
import usePQuery from "contexts/usePQuery";
import useClaimNotification from "hooks/useClaimNotification";
import usePollInterval from "hooks/usePollInterval";
import { useLayout } from "layouts/LayoutContext";
import styles from "layouts/PortalLayout.module.less";
import { utils } from "libs/utils";
import { isEmpty, isString, uniq } from "lodash";
import { CircleArrowLeft, CircleArrowRight } from "lucide-react";
import { QRCodeCanvas } from "qrcode.react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";

import MenuConfig from "./MenuConfig";

type MenuItem = ItemType;
const { Sider } = Layout;
const { Text } = Typography;

const viteEnvConfigs = import.meta.env;

export const FigClaimCountersForSideMenuDocument = graphql(`
  query FigClaimCountersForSideMenu($where: fig_claim_cases_bool_exp!) {
    meNeedToWork: fig_claim_cases_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`);

const SideMenu: FC = () => {
  const { dispatchLayoutState, selectedMenuGroups, showMenu } = useLayout();
  const { hasAccessPermissions, hasRole, user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const firebaseRemoteConfig = useFirebaseRemoteConfig();
  const enableDirectBillingQRCodeMedicalProviderIds = firebaseRemoteConfig?.enableDirectBillingQRCodeMedicalProviders.map((item) => item.id);

  const { medicalProviderCompanyId, medicalProviderData } = useMedicalProviderForHospital();

  const { data: figClaimCounters } = usePQuery(FigClaimCountersForSideMenuDocument, {
    pollInterval: usePollInterval(30_000),
    skip: hasRole(["MedicalProviderEmployee"]),
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        assignee_user_id: { _eq: user.id },
        claim_case: {
          insured_certificate: {
            policy: {
              insurer_id: { _nin: NON_HEALTHCARE_COMPANY_IDS },
            },
          },
          status: { _in: ASSIGNED_CLAIM_CASE_STATUSES },
        },
      },
    },
  });

  const { data: figFwdMrClaimCounters } = usePQuery(FigClaimCountersForSideMenuDocument, {
    pollInterval: usePollInterval(30_000),
    skip: hasRole(["MedicalProviderEmployee"]) || !hasAccessPermissions(["fwd_mr_view"]),
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        assignee_user_id: { _eq: user.id },
        claim_case: {
          insured_certificate: {
            policy: {
              insurer_id: { _eq: INSURER_COMPANY_IDS.FWD },
            },
          },
          status: { _in: ASSIGNED_CLAIM_CASE_STATUSES_FOR_FWD },
        },
      },
    },
  });

  const { data: figMbHsClaimCounters } = usePQuery(FigClaimCountersForSideMenuDocument, {
    pollInterval: usePollInterval(30_000),
    skip: hasRole(["MedicalProviderEmployee"]) || !hasAccessPermissions(["mb_view"]),
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        assignee_user_id: { _eq: user.id },
        claim_case: {
          insured_certificate: {
            policy: {
              insurer_id: { _eq: INSURER_COMPANY_IDS.MBAges },
            },
          },
          status: { _in: ASSIGNED_CLAIM_CASE_STATUSES },
        },
      },
    },
  });

  const { data: figSlvHsClaimCounters } = usePQuery(FigClaimCountersForSideMenuDocument, {
    pollInterval: usePollInterval(30_000),
    skip: hasRole(["MedicalProviderEmployee"]) || !hasAccessPermissions(["slv_view"]),
    skipPollAttempt: () => document.hidden,
    variables: {
      where: {
        assignee_user_id: { _eq: user.id },
        claim_case: {
          insured_certificate: {
            policy: {
              insurer_id: { _eq: INSURER_COMPANY_IDS.SLV },
            },
          },
          status: { _in: ASSIGNED_CLAIM_CASE_STATUSES },
        },
      },
    },
  });

  const { total: hadResultAndUnreadClaimsTotal } = useClaimNotification({
    companyId: medicalProviderCompanyId,
    status: ["Approved", "Declined", "Paid"],
  });
  const { total: unprocessedAndUnreadClaimsTotal } = useClaimNotification({
    companyId: medicalProviderCompanyId,
    status: ["Initialize"],
  });
  const { total: hadPendingAndUnreadClaimsTotal } = useClaimNotification({
    companyId: medicalProviderCompanyId,
    status: ["Pending"],
  });

  const isDisplayQRCodeSection =
    showMenu === true &&
    medicalProviderCompanyId != null &&
    hasAccessPermissions(["direct_billing_edit"]) &&
    medicalProviderData?.medical_provider_id != null &&
    enableDirectBillingQRCodeMedicalProviderIds?.includes(medicalProviderData.medical_provider_id) === true;

  const menuItems: MenuItem[] = MenuConfig.map(({ children, icon, key, label, path, permissions }) => {
    if (!hasAccessPermissions(permissions)) {
      return null;
    }
    return {
      children: children?.map((subMenuItemConfig) => {
        const { features, icon: subMenuIcon, key: subMenuItemKey, label: subMenuLabel, path: subPath, permissions: subMenuPermissions } = subMenuItemConfig;
        const isValidFeatureScreen = isEmpty(features) || (!isEmpty(features) && features?.every((feature) => viteEnvConfigs[feature] === "true") === true);

        if (!hasAccessPermissions(subMenuPermissions) || !isValidFeatureScreen) {
          return null;
        }
        let subKey = subMenuItemKey ?? (isString(subPath) ? subPath.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, "") : subMenuLabel);
        if (subPath === `${PORTAL_PATH.HC_CLAIM_LIST}/test-claims-only`) {
          subKey = `${PORTAL_PATH.HC_CLAIM_LIST}/test-claims-only`;
        }
        const renderCountUnread = () => {
          if (subPath === PORTAL_PATH.HOSPITAL_CLAIM_RESULTED && hadResultAndUnreadClaimsTotal != null && hadResultAndUnreadClaimsTotal > 0) {
            return {
              icon: <Image rootClassName={styles.iconFolder} src={FolderUnread} width={14} />,
              label: `(${hadResultAndUnreadClaimsTotal})`,
            };
          }
          if (subPath === PORTAL_PATH.HOSPITAL_CLAIM_UNPROCESSED && unprocessedAndUnreadClaimsTotal != null && unprocessedAndUnreadClaimsTotal > 0) {
            return {
              icon: <Image rootClassName={styles.iconFolder} src={FolderUnread} width={14} />,
              label: `(${unprocessedAndUnreadClaimsTotal})`,
            };
          }
          if (subPath === PORTAL_PATH.HOSPITAL_CLAIM_PENDING && hadPendingAndUnreadClaimsTotal != null && hadPendingAndUnreadClaimsTotal > 0) {
            return {
              icon: <Image rootClassName={styles.iconFolder} src={FolderUnread} width={14} />,
              label: `(${hadPendingAndUnreadClaimsTotal})`,
            };
          }
          return {
            icon: subMenuIcon,
            label: "",
          };
        };

        const isClaimListPath = [PORTAL_PATH.FWD_MR_CLAIM_LIST, PORTAL_PATH.HC_CLAIM_LIST, PORTAL_PATH.MBAL_CLAIM_LIST, PORTAL_PATH.SLV_HS_CLAIM_LIST].includes(subPath ?? "");

        if (isClaimListPath) {
          return {
            icon: renderCountUnread().icon,
            key: subKey,
            label: isString(subPath) ? (
              <Popover
                content={<Input.Search allowClear onSearch={(keyword) => navigate(`${subPath}/?keyword=${keyword}&go_to_detail=true`)} placeholder="Search nhanh" />}
                mouseEnterDelay={0.5}
                placement="right"
              >
                <Link to={subPath}>{subMenuLabel}</Link>{" "}
              </Popover>
            ) : (
              subMenuLabel
            ),
            path,
          };
        }

        return {
          icon: renderCountUnread().icon,
          key: subKey,
          label: isString(subPath) ? (
            <Link className={styles.menuItem} to={subPath}>
              <PapayaIf
                condition={[PORTAL_PATH.HOSPITAL_CLAIM_PENDING, PORTAL_PATH.HOSPITAL_CLAIM_RESULTED, PORTAL_PATH.HOSPITAL_CLAIM_UNPROCESSED].includes(subPath)}
                else={
                  <>
                    {subMenuLabel}
                    <span style={{ marginLeft: 4 }}>
                      <PapayaIf condition={subPath === PORTAL_PATH.HC_CLAIM_CASE_ASSIGNMENT}>
                        <Badge count={figClaimCounters?.meNeedToWork.aggregate?.count} />
                      </PapayaIf>
                      <PapayaIf condition={subPath === PORTAL_PATH.FWD_MR_CLAIM_CASE_ASSIGNMENT}>
                        <Badge count={figFwdMrClaimCounters?.meNeedToWork.aggregate?.count} />
                      </PapayaIf>
                      <PapayaIf condition={subPath === PORTAL_PATH.MBAL_HS_CLAIM_CASE_ASSIGNMENT}>
                        <Badge count={figMbHsClaimCounters?.meNeedToWork.aggregate?.count} />
                      </PapayaIf>
                      <PapayaIf condition={subPath === PORTAL_PATH.SLV_HS_CLAIM_CASE_ASSIGNMENT}>
                        <Badge count={figSlvHsClaimCounters?.meNeedToWork.aggregate?.count} />
                      </PapayaIf>
                    </span>
                  </>
                }
              >
                <span>{subMenuLabel}</span> <span>{renderCountUnread().label}</span>
              </PapayaIf>
            </Link>
          ) : (
            subMenuLabel
          ),
          path,
        };
      }),
      icon,
      key: key ?? (isString(path) ? path.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, "") : label),
      label: isString(path) ? <Link to={path}>{label}</Link> : label,
    };
  });

  let activeRoute = uniq(
    MenuConfig.flatMap((menuConfig) => {
      if (isEmpty(menuConfig.children)) {
        return menuConfig.key ?? menuConfig.path;
      }
      return menuConfig.children?.map((subMenuConfig) => subMenuConfig.key ?? subMenuConfig.path);
    }).filter(Boolean),
  ).find((routePath) => location.pathname.includes(routePath.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, "")));

  // TODO: Define general config for paths
  if (location.pathname.includes(PORTAL_PATH.CLAIM_CASE.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, ""))) {
    activeRoute = PORTAL_PATH.HC_CLAIM_LIST;
  }
  if (location.pathname.includes(PORTAL_PATH.FWD_MR_CLAIM_CASE.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, ""))) {
    activeRoute = PORTAL_PATH.FWD_MR_CLAIM_LIST;
  }
  if (location.pathname.includes(PORTAL_PATH.MBAL_CLAIM_LIST.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, ""))) {
    activeRoute = PORTAL_PATH.MBAL_CLAIM_LIST;
  }
  if (location.pathname.includes(PORTAL_PATH.SLV_HS_CLAIM_LIST.replace(/\/:(?<=\/:)[\d/:a-z]+/gi, ""))) {
    activeRoute = PORTAL_PATH.SLV_HS_CLAIM_LIST;
  }
  if (location.pathname.includes("test-claims-only")) {
    activeRoute = `${PORTAL_PATH.HC_CLAIM_LIST}/test-claims-only`;
  }
  if (location.pathname.includes(PORTAL_PATH.MAILBOX.BASE_PATH.replace("/*", ""))) {
    activeRoute = PORTAL_PATH.MAILBOX.BASE_PATH;
  }

  return (
    <Sider className={styles.sideMenu} collapsed={showMenu === false} collapsible theme="dark" trigger={null}>
      <div className={styles.logo}>
        <Image preview={false} src={PapayaLogo} width={86} />
      </div>

      <div className={styles.menuContainer}>
        <Menu
          defaultOpenKeys={selectedMenuGroups}
          items={menuItems}
          mode="inline"
          onOpenChange={(openMenuItems) => {
            dispatchLayoutState?.({
              payload: {
                selectedMenuGroups: openMenuItems,
              },
              type: "SELECT_MENU_GROUPS",
            });
          }}
          selectedKeys={activeRoute == null ? [PORTAL_PATH.DASHBOARD, PORTAL_PATH.HOSPITAL_CERTIFICATES_SEARCH] : [activeRoute]}
          theme="dark"
        />
      </div>
      <PapayaIf condition={isDisplayQRCodeSection === true}>
        <div className={styles.qrContainer}>
          <div className={styles.qrContent}>
            <div className={styles.title}>
              <Image preview={false} src={ScanIcon} />
              <h3>Mã QR cơ sở y tế</h3>
            </div>
            <p className={styles.desc}>Khách hàng dùng App Papaya Care quét mã này để chủ động tạo yêu cầu cho mục Chưa xử lý</p>
            <QRCodeCanvas
              id="qrcode"
              imageSettings={{
                excavate: true,
                height: 15,
                src: PapayaLogo,
                width: 50,
              }}
              level="L"
              size={140}
              style={{ display: "none" }}
              value={JSON.stringify({
                medicalProviderId: medicalProviderData?.medical_provider_id,
              })}
            />
            <Button ghost onClick={() => utils.downloadQR("qrcode")}>
              Tải xuống
            </Button>
          </div>
        </div>
      </PapayaIf>
      <div className={styles.copyright}>
        <DebuggerScreen />
        {showMenu ? (
          <Space>
            <Text className={styles.brandName}>© {new Date().getFullYear()}</Text>
            <Text className={styles.brandName}>Papaya Insurtech</Text>
          </Space>
        ) : (
          <Image preview={false} src={PapayaCareIcon} width={115} />
        )}
      </div>

      <div style={{ bottom: showMenu ? 4 : 22, position: "absolute", right: -6 }}>
        <Button
          icon={showMenu ? <CircleArrowLeft size={18} /> : <CircleArrowRight size={18} />}
          onClick={() => {
            dispatchLayoutState?.({
              payload: {
                showMenu: !showMenu,
              },
              type: "TOGGLE_MENU",
            });
          }}
          style={{ color: "#fff" }}
          type="link"
        />
      </div>
    </Sider>
  );
};

export default SideMenu;
