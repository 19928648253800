import { graphql } from "sdk/v2/graphql";

const EntityDiseaseGroupFragment = graphql(`
  fragment EntityDiseaseGroup on entity_disease_groups {
    id
    time_in_days
    disease_group {
      id
      name
      disease_group_icds(where: { icd_metadata_id: { _in: $icdMetadataId } }) {
        id: disease_group_id
        icd_metadata {
          id
          description
          title
          value
        }
      }
    }
    entity_disease_group_type {
      id: value
      comment
    }
  }
`);

const MedicalProviderGroupMedicalProviderFragment = graphql(`
  fragment MedicalProviderGroupMedicalProvider on mp_medical_provider_group_medical_providers {
    id
    medical_provider_group_history {
      id
      medical_provider_group {
        id
        type
        medical_provider_group_type {
          id: value
          comment
        }
      }
    }
  }
`);

export const CLAIM_ALERT_QUERY = graphql(
  `
    query ClaimAlert(
      $insurerId: uuid!
      $planId: uuid!
      $certificateId: uuid!
      $icdMetadataId: [uuid!]!
      $medicalProviderId: uuid!
      $claimToEffectiveDate: numeric!
      $claimStartDate: timestamptz!
      $policyId: uuid!
    ) {
      exclusionEntityDiseaseGroups: entity_disease_groups(
        where: {
          disease_group: {
            insurer_id: { _eq: $insurerId }
            _or: [
              { disease_group_icds: { icd_metadata_id: { _in: $icdMetadataId } }, is_disease_excluded: { _eq: false } }
              { _not: { disease_group_icds: { icd_metadata_id: { _nin: $icdMetadataId } } }, is_disease_excluded: { _eq: true } }
            ]
          }
          _or: [
            { entity_id: { _eq: $planId }, entity_type_value: { _eq: PLAN }, disease_group_type_value: { _eq: EXCLUSION } }
            { entity_id: { _eq: $certificateId }, entity_type_value: { _eq: INSURED_CERTIFICATE }, disease_group_type_value: { _eq: EXCLUSION } }
          ]
        }
      ) {
        id
        ...EntityDiseaseGroup
      }
      medicalProviderWithGroups: mp_medical_providers_by_pk(medical_provider_id: $medicalProviderId) {
        id
        medical_provider_group_medical_providers(
          where: {
            medical_provider_group_history: {
              medical_provider_group: { type: { _eq: REJECTION }, company_id: { _eq: $insurerId } }
              start_date: { _lte: $claimStartDate }
              _and: { _or: [{ end_date: { _is_null: true } }, { end_date: { _gt: $claimStartDate } }] }
              _not: { medical_provider_group_applications: {} }
            }
          }
        ) {
          id

          ...MedicalProviderGroupMedicalProvider
        }
      }
      medicalProviderWithRejectionApplication: mp_medical_providers_by_pk(medical_provider_id: $medicalProviderId) {
        id
        medical_provider_id
        medical_provider_group_medical_providers(
          where: {
            medical_provider_group_history: {
              medical_provider_group_applications: { entity_id: { _eq: $policyId } }
              medical_provider_group: { type: { _eq: REJECTION } }
              start_date: { _lte: $claimStartDate }
              _and: { _or: [{ end_date: { _is_null: true } }, { end_date: { _gt: $claimStartDate } }] }
            }
          }
        ) {
          id
          ...MedicalProviderGroupMedicalProvider
        }
      }
      pendingCodeDiseaseGroups: entity_disease_groups(
        where: {
          disease_group: {
            insurer_id: { _eq: $insurerId }
            _or: [
              { disease_group_icds: { icd_metadata_id: { _in: $icdMetadataId } }, is_disease_excluded: { _eq: false } }
              { _not: { disease_group_icds: { icd_metadata_id: { _nin: $icdMetadataId } } }, is_disease_excluded: { _eq: true } }
            ]
          }
          entity_id: { _eq: $planId }
          entity_type_value: { _eq: PLAN }
          disease_group_type_value: { _eq: PENDING }
          _or: [{ time_in_days: { _gte: $claimToEffectiveDate } }, { time_in_days: { _is_null: true } }]
        }
      ) {
        id
        ...EntityDiseaseGroup
      }
      waitingTimeEntityDiseaseGroups: entity_disease_groups(
        where: {
          disease_group: {
            insurer_id: { _eq: $insurerId }
            _or: [
              { disease_group_icds: { icd_metadata_id: { _in: $icdMetadataId } }, is_disease_excluded: { _eq: false } }
              { _not: { disease_group_icds: { icd_metadata_id: { _nin: $icdMetadataId } } }, is_disease_excluded: { _eq: true } }
              { disease_group_icds: { _not: { icd_metadata_id: { _in: $icdMetadataId } } }, is_disease_excluded: { _eq: true } }
            ]
          }
          entity_id: { _eq: $planId }
          entity_type_value: { _eq: PLAN }
          disease_group_type_value: { _eq: WAITING_TIMES }
          time_in_days: { _gte: $claimToEffectiveDate }
        }
      ) {
        id
        ...EntityDiseaseGroup
      }
    }
  `,
  [MedicalProviderGroupMedicalProviderFragment, EntityDiseaseGroupFragment, EntityDiseaseGroupFragment],
);
