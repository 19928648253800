import type { FC } from "react";

import usePQuery from "contexts/usePQuery";
import useMedicalProviders from "hooks/useMedicalProviders";
import useMetadata from "hooks/useMetadata";
import utils from "libs/utils";
import { uniq } from "lodash";
import { createContext, useMemo } from "react";
import { graphql } from "sdk/v2/graphql";

import useClaim from "../../ClaimContext/useClaim";

export const ClaimAssistantContext = createContext<ClaimAssistantContextType>({ loading: false } as ClaimAssistantContextType);
ClaimAssistantContext.displayName = "ClaimContext";

const ClaimDocumentsDocument = graphql(`
  query ClaimDocumentsForAssistant($claimId: uuid!) {
    claim_cases_by_pk(claim_case_id: $claimId) {
      id
      claim_documents(where: { type: { _neq: CLAIM_PENDING_SAMPLE_FILE } }, order_by: { created_at: desc, display_order: asc_nulls_last }) {
        id
        type
        file {
          id
          url
        }
        newest_ocr_paper {
          id
          invoice {
            id
            invoice_number
            seller_name
            seller_tax_code
            serial_number
            signed_at
            total_amount
            issued_at
          }
          prescription {
            id
            diagnoses
            diagnosis
            icd_codes
            medical_provider_name
            raw_data
          }
        }
      }
    }
  }
`);

type Suggestions = {
  matchIds?: (null | string)[] | null | string;
  matchObject?: any;
  matchObjects?: any;
  originalDocument?: NonNullable<ResultOf<typeof ClaimDocumentsDocument>["claim_cases_by_pk"]>["claim_documents"][number];
  originalDocumentId?: string;
  type: "diagnosis" | "icd" | "medical_provider";
  value?: null | string | string[];
}[];

export type ClaimAssistantContextType = {
  loading: boolean;
  suggestions?: Suggestions;
};

const ClaimAssistantProvider: FC<Record<string, unknown>> = (props) => {
  const { claim } = useClaim();
  const { data } = usePQuery(ClaimDocumentsDocument, claim?.id == null ? { skip: true } : { variables: { claimId: claim.id } });

  const prescriptionPaper = data?.claim_cases_by_pk?.claim_documents.find(
    (doc) =>
      doc.type === "PrescriptionPaper" &&
      ((doc.newest_ocr_paper?.prescription?.diagnosis?.length ?? 0) > 0 ||
        doc.newest_ocr_paper?.prescription?.icd_codes?.length > 0 ||
        doc.newest_ocr_paper?.prescription?.medical_provider_name != null),
  );
  const prescriptionPapers = data?.claim_cases_by_pk?.claim_documents.filter((x) => x.type === "PrescriptionPaper") ?? [];

  const icdCodes: string[] = uniq(prescriptionPaper?.newest_ocr_paper?.prescription?.icd_codes);
  const diagnosis = prescriptionPaper?.newest_ocr_paper?.prescription?.diagnosis;

  const { data: diseaseData, loading: diseasesLoading } = useMetadata({
    limit: 20,
    skip: icdCodes.length === 0,
    types: ["diseases"],
    values: icdCodes,
  });

  const addresses = uniq(prescriptionPapers.map((x) => x.newest_ocr_paper?.prescription?.raw_data?.medical_provider?.address?.street).filter(Boolean));

  const { data: medicalProviders, loading: loadingMedicalProviders } = useMedicalProviders({
    limit: 1,
    skip: prescriptionPapers.length === 0,
    where: {
      _or: [
        ...uniq(prescriptionPapers.map((x) => utils.normalizeText(x.newest_ocr_paper?.prescription?.medical_provider_name).toLowerCase()).filter(Boolean)).map((name) => ({
          names: {
            _contains: [name],
          },
        })),
        ...uniq(prescriptionPapers.map((x) => x.newest_ocr_paper?.prescription?.medical_provider_name).filter(Boolean)).map((name) => ({
          names: {
            _contains: [name],
          },
        })),
        addresses.length > 0
          ? {
              address: {
                _in: addresses,
              },
            }
          : undefined,
      ].filter(Boolean),
    },
  });

  const matchIcdCodes = icdCodes.map((code) => diseaseData?.metadata.find((x) => x.value === code)).filter(Boolean);

  const originalDocumentId = prescriptionPaper?.id;
  const originalDocument = data?.claim_cases_by_pk?.claim_documents.find(({ id }) => id === prescriptionPaper?.id);

  const suggestions: Suggestions = useMemo(
    () => [
      {
        matchIds: matchIcdCodes.map((d) => d.id),
        matchObjects: matchIcdCodes,
        originalDocument,
        originalDocumentId,
        type: "icd",
        value: icdCodes,
      },
      {
        matchIds: medicalProviders?.mp_medical_providers.map((m) => m.id),
        matchObject: medicalProviders?.mp_medical_providers[0],
        matchObjects: medicalProviders?.mp_medical_providers,
        type: "medical_provider",
        value: medicalProviders?.mp_medical_providers[0]?.name,
      },
      {
        originalDocument,
        originalDocumentId,
        type: "diagnosis",
        value: diagnosis,
      },
    ],
    [diagnosis, icdCodes, matchIcdCodes, medicalProviders?.mp_medical_providers, originalDocument, originalDocumentId],
  );

  const value: ClaimAssistantContextType = useMemo(
    () => ({
      loading: diseasesLoading || loadingMedicalProviders,
      suggestions,
    }),
    [diseasesLoading, loadingMedicalProviders, suggestions],
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ClaimAssistantContext.Provider value={value} {...props} />;
};

export { ClaimAssistantProvider };
