import { useMount } from "ahooks";
import { Alert, Button, Flex, Modal, Popover, Space, Switch, Tooltip, Typography } from "antd";
import Content from "app/common/components/Content";
import ExternalLink from "app/common/components/ExternalLink";
import IcdTag from "app/common/components/IcdTag";
import PapayaIf from "app/common/components/PapayaIf";
import { CLAIM_CASE_PATH, PORTAL_PATH } from "app/portal/config/paths";
import {
  CONTINUE_INITIALIZE_STP_FLOW_MUTATION,
  UpdateWillSuspendMutation,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/ClaimAlert/graphql/mutations";
import ClaimCasesByDateRange from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/DetailForm/components/ClaimCasesByDateRange";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useAlert from "app/portal/screens/ClaimPortal/ClaimContext/useAlert";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import { NON_HEALTHCARE_COMPANY_IDS } from "config/constants";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { isEqual } from "date-fns";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import { getNullableNumber, isEqualZero, isGreaterThanZero } from "libs/hidash";
import utils from "libs/utils";
import { isEmpty, uniq } from "lodash";
import { CircleCheck, Info, Pill, RefreshCcw } from "lucide-react";
import { generatePath, Link } from "react-router-dom";
import { graphql as codegenThisGraphqlTag, graphql, readFragment } from "sdk/v2/graphql";

import warningLAs from "./warningMBAL.json";

const InsuredCertificateDocument = graphql(`
  query ParentInsuredCertificate($insuredCertificateId: uuid!) {
    insured_certificates_by_pk(insured_certificate_id: $insuredCertificateId) {
      id
      policy_id
      status
      insured_person {
        id
        name
      }
      parent_insured_certificate {
        id
        policy_id
        status
        insured_person {
          id
          name
        }
      }
    }
  }
`);

const MedicalProviderGroupMedicalProviderFragment = graphql(`
  fragment MedicalProviderGroupMedicalProvider on mp_medical_provider_group_medical_providers {
    id
    medical_provider_group_history {
      id
      medical_provider_group {
        id
        type
        medical_provider_group_type {
          id: value
          comment
        }
      }
    }
  }
`);

const MedicalProviderWithDbRejectionDocument = graphql(
  `
    query MedicalProviderWithDbRejection($medicalProviderId: uuid!, $policyId: uuid!, $claimStartDate: timestamptz!) {
      medicalProviderWithDbRejectionApplication: mp_medical_providers_by_pk(medical_provider_id: $medicalProviderId) {
        id
        medical_provider_id
        medical_provider_group_medical_providers(
          where: {
            medical_provider_group_history: {
              medical_provider_group_applications: { entity_id: { _eq: $policyId } }
              medical_provider_group: { type: { _eq: DB_REJECTION } }
              start_date: { _lte: $claimStartDate }
              _and: { _or: [{ end_date: { _is_null: true } }, { end_date: { _gt: $claimStartDate } }] }
            }
          }
        ) {
          id
          ...MedicalProviderGroupMedicalProvider
        }
      }
    }
  `,
  [MedicalProviderGroupMedicalProviderFragment],
);

const ClaimDocumentsInvoiceAlertDocument = graphql(`
  query ClaimDocumentsInvoiceAlert($claimId: uuid!) {
    claim_cases_by_pk(claim_case_id: $claimId) {
      id
      claim_documents(where: { type: { _eq: InvoicePaper } }) {
        id
        type
        file {
          id
          url
        }
        newest_ocr_paper {
          id
          invoice {
            id
            invoice_number
            seller_name
            seller_tax_code
            serial_number
            signed_at
            total_amount
            issued_at
          }
        }
      }
    }
  }
`);

const EntityDiseaseGroupDocument = graphql(`
  query EntityDiseaseGroup($where: entity_disease_groups_bool_exp!, $whereIcds: disease_group_icds_bool_exp!) {
    entity_disease_groups(where: $where) {
      id
      disease_group_type_value
      entity_type_value
      time_in_days
      waiting_time_in_days
      disease_group {
        id
        name
        disease_group_icds(where: $whereIcds, distinct_on: icd_metadata_id) {
          icd_metadata {
            id
            title
            value
          }
        }
        entity_disease_group {
          id
          time_in_days
          waiting_time_in_days
        }
      }
      disease_group_type {
        comment
        value
      }
      entity_disease_group_type {
        comment
        value
      }
    }
  }
`);

const SameInvoicesAlertDocument = graphql(`
  query SameInvoicesAlert($where: ocr_invoices_bool_exp!) {
    ocr_invoices(where: $where, limit: 100) {
      id
      invoice_number
      seller_name
      seller_tax_code
      serial_number
      signed_at
      total_amount
      issued_at
      paper {
        id
        claim_document {
          id
          claim_case {
            id
            code
          }
          file {
            id
            url
          }
        }
      }
    }
  }
`);

const mbalCheckHasOtpClaimForm = graphql(`
  query MbalCheckHasOtpClaimForm($where: claim_documents_bool_exp!) {
    claim_documents(where: $where) {
      id
    }
  }
`);

const mbalCreateClaimForm = graphql(`
  mutation MbalCreateClaimForm($claimId: UUID!) {
    mbal {
      createMbClaimForm(claimId: $claimId)
    }
  }
`);

const DependedClaimsDocument = graphql(`
  query DependedClaims($where: claim_cases_bool_exp!) {
    claim_cases(where: $where) {
      id
      code
      meta_v2 {
        assessor {
          id
          slack_member_id
        }
      }
    }
  }
`);

export const ClaimAlertFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimAlert on claim_cases {
    id
    code
    grace_period_end_date
    grace_period_start_date
    insured_certificate_id
    is_accessible
    is_direct_billing
    medical_provider_id
    source
    start_date
    status
    will_suspend
    created_at
    alert_claim_case_assessed_diagnoses: claim_case_assessed_diagnoses {
      id
      icd {
        value
      }
    }
    alert_stp_executions: stp_executions {
      execution_arn
      task_token
    }
    claim_case_beneficiary {
      id
      payment_method
      warning_code
    }
    claim_case_group_claim_cases {
      id
      type
    }
    claim_case_payment {
      id
      initial_premium_debt_amount
    }
    claim_case_same_event_groups_claim_case {
      id
      claim_case_same_event_group {
        id
        handed_over_to_insurer_at
        slv_claim_case_same_event_group {
          id
          client_black_list
        }
      }
    }
    insured_certificate {
      id
      status
      insured_person {
        id
        insured_number
      }
      policy {
        id
        policy_number
        types
        insured_person {
          id
          insured_number
        }
        insurer {
          id
          company_id
        }
        insurer_company {
          id
          company_id
        }
        slv_policy {
          id
          pol_status
        }
      }
    }
    mbal_claim_case {
      id
      is_fatca_us_citizen
      is_fatca_us_related
      is_fatca_us_tax_payer
    }
    medical_provider {
      id
      name
      medical_provider_mappings {
        id
        company_id
      }
    }
  }
`);

const StpMrAlert = () => {
  const { claim } = useClaim();
  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimAlertFragment, claim) as ResultOf<typeof ClaimAlertFragment>;
  })();
  const nextWorkingHour = utils.getNextWorkingHour(claimFragmentData?.created_at);
  const isClaimInWorkingHour = nextWorkingHour == null || claimFragmentData?.created_at == null ? false : isEqual(nextWorkingHour, new Date(claimFragmentData.created_at));
  const isStpExecutionStarted = claimFragmentData?.alert_stp_executions[0]?.execution_arn != null;
  const continueStartStpMrToken = claimFragmentData?.alert_stp_executions[0]?.task_token?.manualStartStpMrToken;
  const [continueInitializeStpFlow, { loading: continueInitializeStpFlowLoading }] = usePMutation(CONTINUE_INITIALIZE_STP_FLOW_MUTATION);

  if (claimFragmentData == null || claimFragmentData.is_accessible === true) {
    return null;
  }

  if (isStpExecutionStarted === true) {
    return <Alert description="STP đang chạy,không thể thẩm định lúc này." showIcon type="error" />;
  }

  if (isClaimInWorkingHour === true || continueStartStpMrToken == null) {
    return null;
  }

  return (
    <Alert
      action={
        <Tooltip title="Bạn đã sàng xử lý claim này. Bấm tiếp tục để STP tiếp tục chạy.">
          <Button
            loading={continueInitializeStpFlowLoading}
            onClick={() => {
              continueInitializeStpFlow({
                variables: {
                  claimCaseId: claimFragmentData.id,
                  tokenType: "MANUAL_START_STP_MR",
                },
              });
            }}
            size="small"
            type="primary"
          >
            Tiếp tục
          </Button>
        </Tooltip>
      }
      description="Claim tạo ngoài thời gian làm việc và đang chờ STP xử lý."
      message="Không thể thẩm định lúc này."
      type="warning"
    />
  );
};

const SlvNeedPresentCasePolicyAlert = () => {
  const { claim, claimType } = useClaim();

  if (claimType.slvHs === false) {
    return null;
  }

  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimAlertFragment, claim) as ResultOf<typeof ClaimAlertFragment>;
  })();

  const NEED_PRESENT_CASE_SLV_POLICY_STATUSES = new Set(["R1", "R2", "R3"]);

  if (claimFragmentData == null || NEED_PRESENT_CASE_SLV_POLICY_STATUSES.has(claimFragmentData.insured_certificate.policy.slv_policy?.pol_status ?? "") === false) {
    return null;
  }

  return (
    <Alert
      action={
        <Link to={generatePath(`${PORTAL_PATH.SLV_HS_CLAIM_CASE}/${CLAIM_CASE_PATH.CHECK_CASE}`, { claimCaseId: claimFragmentData.id })}>
          <Button type="primary">Trình case</Button>
        </Link>
      }
      description={
        <div>
          Hợp đồng{" "}
          <ExternalLink
            to={generatePath(PORTAL_PATH.POLICY_INFO, {
              id: claimFragmentData.insured_certificate.policy.id,
            })}
          >
            {claimFragmentData.insured_certificate.policy.policy_number}
          </ExternalLink>{" "}
          có status {claimFragmentData.insured_certificate.policy.slv_policy?.pol_status}, PHẢI trình case đến SLV kiểm tra
        </div>
      }
      type="error"
    />
  );
};

const SlvNeedPresentCaseClientAlert = () => {
  const { claim, claimType } = useClaim();

  if (claimType.slvHs === false) {
    return null;
  }

  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimAlertFragment, claim) as ResultOf<typeof ClaimAlertFragment>;
  })();

  if (claimFragmentData?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.client_black_list !== true) {
    return null;
  }

  return (
    <Alert
      action={
        <Link to={generatePath(`${PORTAL_PATH.SLV_HS_CLAIM_CASE}/${CLAIM_CASE_PATH.CHECK_CASE}`, { claimCaseId: claimFragmentData.id })}>
          <Button type="primary">Trình case</Button>
        </Link>
      }
      description="Khách hàng đang bị theo dõi: Cần trình case đến SLV"
      type="error"
    />
  );
};
const WillSuspendAlert = ({ claimFragmentData }: { claimFragmentData: FragmentOf<typeof ClaimAlertFragment> }) => {
  const [updateWillSuspend, { loading: updateWillSuspendLoading }] = usePMutation(UpdateWillSuspendMutation, {
    awaitRefetchQueries: true,
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });
  return (
    <Content title="Tự động chuyển trạng thái Quá hạn bổ sung hồ sơ">
      <Typography.Text>Tự động chuyển sang trạng thái Quá hạn bổ sung hồ sơ</Typography.Text>
      <Switch
        checkedChildren="Bật"
        loading={updateWillSuspendLoading}
        onChange={(value) => {
          updateWillSuspend({ variables: { claimCaseId: claimFragmentData.id, willSuspend: value } });
        }}
        unCheckedChildren="Tắt"
        value={claimFragmentData.will_suspend}
      />
    </Content>
  );
};

const ClaimAlert = () => {
  const [modal, contextHolder] = Modal.useModal();
  const { claim, claimToEffectiveDays, claimType, notification, registerSaveStatusCallback } = useClaim();
  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimAlertFragment, claim) as ResultOf<typeof ClaimAlertFragment>;
  })();
  const { alertClaimData, sameStartDateClaimCasesByInsuredPerson } = useAlert();

  const { data: InsuredCertificateData } = usePQuery(
    InsuredCertificateDocument,
    claimFragmentData == null
      ? { skip: true }
      : {
          variables: {
            insuredCertificateId: claimFragmentData.insured_certificate_id,
          },
        },
  );

  const { data: medicalProviderWithDbRejectionData } = usePQuery(
    MedicalProviderWithDbRejectionDocument,
    claimFragmentData?.medical_provider_id == null || claimFragmentData.start_date == null || claimFragmentData.is_direct_billing === false
      ? { skip: true }
      : {
          variables: {
            claimStartDate: claimFragmentData.start_date,
            medicalProviderId: claimFragmentData.medical_provider_id,
            policyId: claimFragmentData.insured_certificate.policy.id,
          },
        },
  );

  const { data: mbalCheckHasOtpClaimFormData } = usePQuery(
    mbalCheckHasOtpClaimForm,
    (claimType.mbalHs === false && claimFragmentData?.source !== "MBAL_APP") || claimFragmentData?.id == null
      ? { skip: true }
      : {
          variables: {
            where: {
              claim_case_id: {
                _eq: claimFragmentData.id,
              },
              type: {
                _eq: "ClaimForm",
              },
            },
          },
        },
  );

  const [createMbClaimForm] = usePMutation(mbalCreateClaimForm, {
    onCompleted: (data) => {
      if (data.mbal?.createMbClaimForm === true) {
        notification.api.open({ key: "createMbClaimForm", duration: 2, message: "Đã tạo claim form", type: "success" });
      } else {
        notification.api.open({ key: "createMbClaimForm", duration: 2, message: "Tạo claim form thất bại", type: "error" });
      }
    },
    refetchQueries: getRefetchOperationNames([mbalCheckHasOtpClaimForm]),
  });

  const { data: claimDocumentsInvoiceAlertData } = usePQuery(
    ClaimDocumentsInvoiceAlertDocument,
    claimFragmentData?.id == null
      ? { skip: true }
      : {
          variables: {
            claimId: claimFragmentData.id,
          },
        },
  );

  const invoiceWhere: VariablesOf<typeof SameInvoicesAlertDocument>["where"]["_or"] =
    claimDocumentsInvoiceAlertData?.claim_cases_by_pk?.claim_documents
      .map((i) => ({
        invoice_number: { _eq: i.newest_ocr_paper?.invoice?.invoice_number },
        paper: {
          claim_document: {
            claim_case: {
              id: { _neq: claimFragmentData?.id },
            },
          },
          claim_document_id: { _neq: i.id },
        },
        seller_tax_code: { _eq: i.newest_ocr_paper?.invoice?.seller_tax_code },
        serial_number: { _eq: i.newest_ocr_paper?.invoice?.serial_number },
        total_amount: { _is_null: false },
      }))
      .filter((i) => !isEmpty(i.invoice_number._eq) && !isEmpty(i.seller_tax_code._eq) && !isEmpty(i.serial_number._eq)) ?? [];

  const { data: sameInvoicesData } = usePQuery(
    SameInvoicesAlertDocument,
    isEqualZero(invoiceWhere)
      ? { skip: true }
      : {
          variables: {
            where: {
              _or: invoiceWhere,
            },
          },
        },
  );

  const { data: entityDiseaseGroupData } = usePQuery(
    EntityDiseaseGroupDocument,
    claimFragmentData?.insured_certificate.policy.insurer_company?.company_id == null || claimFragmentData.alert_claim_case_assessed_diagnoses.length === 0
      ? { skip: true }
      : {
          variables: {
            where: {
              disease_group: {
                disease_group_icds: {
                  icd_metadata: {
                    value: {
                      _in: claimFragmentData.alert_claim_case_assessed_diagnoses.map((i) => i.icd.value),
                    },
                  },
                },
              },
              insurer_company: {
                company_id: { _eq: claimFragmentData.insured_certificate.policy.insurer_company.company_id },
              },
            },
            whereIcds: {
              icd_metadata: {
                value: {
                  _in: claimFragmentData.alert_claim_case_assessed_diagnoses.map((i) => i.icd.value),
                },
              },
            },
          },
        },
  );

  const { data: dependedClaimsData } = usePQuery(
    DependedClaimsDocument,
    claimFragmentData?.code == null
      ? { skip: true }
      : {
          variables: {
            where: {
              claim_pending_codes: {
                _or: [
                  {
                    fwd_pending_code: {
                      code: { _eq: "P05" }, // P05: Chờ quyết định trên claim liên quan
                    },
                  },
                ],
                extended_text: {
                  _ilike: `%${claimFragmentData.code}%`,
                },
              },
              status: {
                _eq: "Pending",
              },
            },
          },
        },
  );

  const isMpInExclusionList = isGreaterThanZero(alertClaimData?.medicalProviderWithRejectionApplication?.medical_provider_group_medical_providers.length);

  const icdInExclusionList = (() => {
    if (entityDiseaseGroupData == null) return [];
    return entityDiseaseGroupData.entity_disease_groups.filter((item) => item.disease_group_type_value === "EXCLUSION");
  })();

  const icdInWaitingList = (() => {
    if (entityDiseaseGroupData == null) return null;
    const entityDiseaseGroup = entityDiseaseGroupData.entity_disease_groups.find((item) => item.disease_group_type_value === "WAITING_TIMES");
    if (entityDiseaseGroup == null) return null;
    if (getNullableNumber(entityDiseaseGroup.time_in_days) <= getNullableNumber(claimToEffectiveDays)) return null;
    return {
      list: entityDiseaseGroup.disease_group.disease_group_icds,
      time_in_days: entityDiseaseGroup.time_in_days,
    };
  })();

  const icdInLongTermTreatmentList = (() => {
    if (entityDiseaseGroupData == null) return null;
    const entityDiseaseGroup = entityDiseaseGroupData.entity_disease_groups.find((item) => item.disease_group_type_value === "LONG_TERM_TREATMENT");
    if (entityDiseaseGroup == null) return null;
    return {
      list: entityDiseaseGroup.disease_group.disease_group_icds,
    };
  })();

  const isWarningMBAL = warningLAs.some((la) => la.laNumber === claimFragmentData?.insured_certificate.insured_person.insured_number);

  const conflictClaimGroup = claimFragmentData?.claim_case_group_claim_cases.filter((item) => item.type === "CONFLICT");

  const insurerId = claimFragmentData?.insured_certificate.policy.insurer.company_id;
  const medicalProviderHasMappingWithInsurer =
    insurerId == null || NON_HEALTHCARE_COMPANY_IDS.includes(insurerId) === false || claimFragmentData?.medical_provider == null
      ? true
      : claimFragmentData.medical_provider.medical_provider_mappings.some((mapping) => mapping.company_id === insurerId);

  useMount(() => {
    if (claimFragmentData == null) return;
    registerSaveStatusCallback((newStatus) => {
      dependedClaimsData?.claim_cases.forEach((dependedClaimCase) => {
        utils.sendMessageToSlack({
          channel: dependedClaimCase.meta_v2?.assessor?.slack_member_id,
          message: `Claim <${window.location.origin}${generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: claimFragmentData.id })}|${claimFragmentData.code}> (ràng buộc claim <${window.location.origin}${generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: dependedClaimCase.id })}|${dependedClaimCase.code}>) đã chuyển sang trạng thái ${newStatus}`,
        });
      });
    });
  });

  if (claimFragmentData == null) return null;

  return (
    <Flex className="py-[5px]" gap={5} vertical>
      <StpMrAlert />
      <SlvNeedPresentCasePolicyAlert />
      <SlvNeedPresentCaseClientAlert />
      <PapayaIf condition={claimFragmentData.status === "Pending" && (claimType.fwdMr || claimType.slvHs)}>
        <WillSuspendAlert claimFragmentData={claimFragmentData} />
      </PapayaIf>
      <PapayaIf condition={isGreaterThanZero(dependedClaimsData?.claim_cases)}>
        <Alert
          description={
            <div>
              Claim{" "}
              {dependedClaimsData?.claim_cases.map((i) => (
                <ExternalLink key={i.id} to={generatePath(PORTAL_PATH.CLAIM_CASE, { claimCaseId: i.id })}>
                  {i.code}
                </ExternalLink>
              ))}{" "}
              đang chờ kết quả từ claim này
            </div>
          }
          type="info"
        />
      </PapayaIf>
      <PapayaIf
        condition={
          claimType.healthCare && InsuredCertificateData?.insured_certificates_by_pk?.parent_insured_certificate?.status === "EXPIRED"
            ? InsuredCertificateData.insured_certificates_by_pk.status !== "EXPIRED"
            : false
        }
      >
        <Alert
          description={
            <div>
              Thẻ của{" "}
              <ExternalLink
                to={generatePath(PORTAL_PATH.CERTIFICATE_DETAIL, {
                  certificateId: String(InsuredCertificateData?.insured_certificates_by_pk?.parent_insured_certificate?.id),
                  policyId: String(InsuredCertificateData?.insured_certificates_by_pk?.parent_insured_certificate?.policy_id),
                })}
              >
                {InsuredCertificateData?.insured_certificates_by_pk?.parent_insured_certificate?.insured_person.name}
              </ExternalLink>{" "}
              (nhân viên) đã hết hạn nhưng thẻ của
              <ExternalLink
                to={generatePath(PORTAL_PATH.CERTIFICATE_DETAIL, {
                  certificateId: String(InsuredCertificateData?.insured_certificates_by_pk?.id),
                  policyId: String(InsuredCertificateData?.insured_certificates_by_pk?.policy_id),
                })}
              >
                {InsuredCertificateData?.insured_certificates_by_pk?.insured_person.name}
              </ExternalLink>{" "}
              (người được bảo hiểm) vẫn còn hiệu lực.
            </div>
          }
          showIcon
          type="warning"
        />
      </PapayaIf>
      <PapayaIf condition={isGreaterThanZero(mbalCheckHasOtpClaimFormData?.claim_documents) === false && claimType.mbalHs ? claimFragmentData.source === "MBAL_APP" : false}>
        <Alert
          action={
            <Button
              onClick={() => {
                createMbClaimForm({ variables: { claimId: claimFragmentData.id } });
                notification.api.open({
                  key: "createMbClaimForm",
                  duration: 0,
                  icon: <RefreshCcw className="animate-spin" size={16} />,
                  message: "Đang tạo claim form",
                  type: "info",
                });
              }}
              type="dashed"
            >
              Tạo claim form
            </Button>
          }
          description="Claim chưa có OTP Claim Form"
          showIcon
          type="error"
        />
      </PapayaIf>
      <PapayaIf condition={isGreaterThanZero(sameInvoicesData?.ocr_invoices)}>
        <Space direction="vertical">
          {claimDocumentsInvoiceAlertData?.claim_cases_by_pk?.claim_documents.map((d) => {
            const sameInvoice = sameInvoicesData?.ocr_invoices.find(
              (i) =>
                i.invoice_number === d.newest_ocr_paper?.invoice?.invoice_number &&
                i.seller_tax_code === d.newest_ocr_paper.invoice.seller_tax_code &&
                i.serial_number === d.newest_ocr_paper.invoice.serial_number,
            );
            if (sameInvoice != null) {
              return (
                <Alert
                  action={
                    <Button
                      danger
                      onClick={() =>
                        modal.error({
                          title: "Hóa đơn trùng",
                          width: 1200,
                          content: (
                            <Space direction="vertical">
                              <Typography.Text strong>Hệ thống kiểm tra theo 3 tiêu chí: Mã số thuế của bên bán, số ký hiệu, số hóa đơn</Typography.Text>
                              <Space align="baseline">
                                <Space direction="vertical">
                                  <Typography.Text strong>
                                    Claim{" "}
                                    <ExternalLink
                                      to={generatePath(`${PORTAL_PATH.CLAIM_CASE}`, {
                                        claimCaseId: claimFragmentData.id,
                                      })}
                                    >
                                      {claimFragmentData.code}
                                    </ExternalLink>
                                  </Typography.Text>
                                  <Typography.Text strong>
                                    Hóa đơn:{" "}
                                    <ExternalLink
                                      to={generatePath(`${PORTAL_PATH.CLAIM_CASE}/${CLAIM_CASE_PATH.GALLERY}?document_id=${d.id}`, {
                                        claimCaseId: claimFragmentData.id,
                                      })}
                                    >
                                      {d.newest_ocr_paper?.invoice?.invoice_number}
                                    </ExternalLink>
                                  </Typography.Text>
                                  <img src={`${d.file?.url}&width=500`} style={{ width: 500, border: "solid 1px #1677ff" }} />
                                </Space>
                                <Space direction="vertical">
                                  <Typography.Text strong>
                                    Claim:{" "}
                                    {sameInvoice.paper.claim_document.claim_case?.id != null && (
                                      <ExternalLink
                                        to={generatePath(`${PORTAL_PATH.CLAIM_CASE}`, {
                                          claimCaseId: sameInvoice.paper.claim_document.claim_case.id,
                                        })}
                                      >
                                        {sameInvoice.paper.claim_document.claim_case.code}
                                      </ExternalLink>
                                    )}
                                  </Typography.Text>
                                  <Typography.Text strong>
                                    Hóa đơn:{" "}
                                    {sameInvoice.paper.claim_document.claim_case?.id != null && (
                                      <ExternalLink
                                        to={generatePath(`${PORTAL_PATH.CLAIM_CASE}/${CLAIM_CASE_PATH.GALLERY}?document_id=${sameInvoice.paper.claim_document.id}`, {
                                          claimCaseId: sameInvoice.paper.claim_document.claim_case.id,
                                        })}
                                      >
                                        {sameInvoice.invoice_number}
                                      </ExternalLink>
                                    )}
                                  </Typography.Text>
                                  <img src={`${sameInvoice.paper.claim_document.file?.url}&width=500`} style={{ width: 500, border: "solid 1px red" }} />
                                </Space>
                              </Space>
                            </Space>
                          ),
                        })
                      }
                      size="small"
                    >
                      Chi tiết
                    </Button>
                  }
                  description={
                    <div>
                      Hóa đơn{" "}
                      <ExternalLink
                        to={generatePath(`${PORTAL_PATH.CLAIM_CASE}/${CLAIM_CASE_PATH.GALLERY}?document_id=${d.id}`, {
                          claimCaseId: claimFragmentData.id,
                        })}
                      >
                        {d.newest_ocr_paper?.invoice?.invoice_number}
                      </ExternalLink>{" "}
                      trùng với hóa đơn thuộc claim{" "}
                      {sameInvoice.paper.claim_document.claim_case?.id != null && (
                        <ExternalLink
                          to={generatePath(`${PORTAL_PATH.CLAIM_CASE}/${CLAIM_CASE_PATH.GALLERY}?document_id=${sameInvoice.paper.claim_document.id}`, {
                            claimCaseId: sameInvoice.paper.claim_document.claim_case.id,
                          })}
                        >
                          {sameInvoice.paper.claim_document.claim_case.code}
                        </ExternalLink>
                      )}
                    </div>
                  }
                  key={d.id}
                  showIcon
                  type="error"
                />
              );
            }
            return null;
          })}
        </Space>
      </PapayaIf>
      <PapayaIf condition={sameStartDateClaimCasesByInsuredPerson.length > 0}>
        <Alert
          action={
            <Button
              onClick={() => {
                modal.info({
                  title: "",
                  width: 800,
                  content: <ClaimCasesByDateRange claimCases={sameStartDateClaimCasesByInsuredPerson} />,
                  okText: "Đóng",
                });
              }}
            >
              Xem chi tiết
            </Button>
          }
          description={<div>Có {sameStartDateClaimCasesByInsuredPerson.length} claim case trùng ngày</div>}
          icon={sameStartDateClaimCasesByInsuredPerson.length > 0 ? <Info color="#DA8300" size={16} /> : <CircleCheck color="#20CC69" size={16} />}
          showIcon
          type="error"
        />
      </PapayaIf>
      <PapayaIf condition={icdInExclusionList.length > 0}>
        <Alert
          description={
            <div>
              ICD <b>{uniq(icdInExclusionList.flatMap((i) => i.disease_group.disease_group_icds.map((j) => j.icd_metadata.value))).join(", ")}</b> nằm trong danh sách loại trừ
            </div>
          }
          icon={<Pill size={16} />}
          showIcon
          type="error"
        />
      </PapayaIf>
      <PapayaIf condition={isGreaterThanZero(icdInWaitingList?.list.length)}>
        <Alert
          description={
            <div>
              ICD <b>{uniq(icdInWaitingList?.list.flatMap((i) => i.icd_metadata.value)).join(", ")}</b> nằm trong danh sách thời gian chờ {icdInWaitingList?.time_in_days} ngày
            </div>
          }
          icon={<Pill size={16} />}
          showIcon
          type="error"
        />
      </PapayaIf>
      <PapayaIf condition={isGreaterThanZero(icdInLongTermTreatmentList?.list.length)}>
        <Alert
          description={
            <div>
              ICD <b>{uniq(icdInLongTermTreatmentList?.list.flatMap((i) => i.icd_metadata.value)).join(", ")}</b> nằm trong danh sách bệnh cần chữa trị dài ngày
            </div>
          }
          icon={<Pill size={16} />}
          showIcon
          type="error"
        />
      </PapayaIf>
      {/* Temporary show warning until MBAL fix get policy detail API and can import all MBAL claims */}
      {isWarningMBAL ? <Alert description="Claim này thuộc LA có claim history import lỗi" showIcon type="warning" /> : null}

      {(claimFragmentData.mbal_claim_case?.is_fatca_us_citizen === true ||
        claimFragmentData.mbal_claim_case?.is_fatca_us_related === true ||
        claimFragmentData.mbal_claim_case?.is_fatca_us_tax_payer === true) && <Alert description="Có FATCA" showIcon type="error" />}
      {alertClaimData?.medicalProviderWithGroups?.medical_provider_group_medical_providers.map((item) => (
        <Alert
          description={`Cơ sở y tế nằm trong nhóm ${item.medical_provider_group_history.medical_provider_group.medical_provider_group_type.comment}`}
          key={item.id}
          showIcon
          type={item.medical_provider_group_history.medical_provider_group.type === "REJECTION" ? "error" : "warning"}
        />
      ))}
      {isMpInExclusionList === true ? (
        <Alert
          description={
            <span>
              Cơ sở y tế <b>[{claimFragmentData.medical_provider?.name}]</b> nằm trong nhóm từ chối chi trả
            </span>
          }
          key="mp_rejection_application"
          showIcon
          type="error"
        />
      ) : null}
      {isGreaterThanZero(medicalProviderWithDbRejectionData?.medicalProviderWithDbRejectionApplication?.medical_provider_group_medical_providers.length) ? (
        <Alert
          description={
            <span>
              Cơ sở y tế <b>[{claimFragmentData.medical_provider?.name}]</b> nằm trong nhóm từ chối bảo lãnh viện phí
            </span>
          }
          key="mp_db_rejection_application"
          showIcon
          type="error"
        />
      ) : null}
      {alertClaimData?.exclusionEntityDiseaseGroups.map(
        (item) =>
          item.disease_group.disease_group_icds.length > 0 && (
            <Alert
              description={
                <Space>
                  {item.disease_group.disease_group_icds.map((icd) => (
                    <IcdTag key={icd.id} value={icd.icd_metadata} />
                  ))}
                  <span>trong nhóm loại trừ của {item.entity_disease_group_type.comment} này.</span>
                </Space>
              }
              key={item.id}
              showIcon
              type="error"
            />
          ),
      )}
      {alertClaimData?.waitingTimeEntityDiseaseGroups.map((item) => (
        <Alert
          description={
            <Space>
              {item.disease_group.disease_group_icds.map((icd) => (
                <IcdTag key={icd.id} value={icd.icd_metadata} />
              ))}
              <span>Trong thời gian chờ {item.entity_disease_group_type.comment} này.</span>
              <span>
                Thời gian chờ của NĐBH: <b>{claimToEffectiveDays}</b>.
              </span>
              <span>
                Thời gian chờ yêu cầu: <b>{item.time_in_days}</b> ngày.
              </span>
            </Space>
          }
          key={item.id}
          showIcon
          type="error"
        />
      ))}
      {(alertClaimData?.pendingCodeDiseaseGroups.length ?? 0) > 0 && (
        <Popover
          content={
            <div>
              {alertClaimData?.pendingCodeDiseaseGroups.map((item) => (
                <Alert
                  description={`Trong ${item.time_in_days == null ? "luôn luôn" : `${item.time_in_days} ngày`} kiểm tra yêu cầu bổ sung chứng từ của nhóm bệnh
                  ${item.disease_group.name} thuộc ${item.entity_disease_group_type.comment} này`}
                  key={item.id}
                  type="error"
                />
              ))}
            </div>
          }
        >
          <div>
            <Alert description="Yêu cầu bổ sung Tóm tắt bệnh án" showIcon type="warning" />
          </div>
        </Popover>
      )}
      {(conflictClaimGroup?.length ?? 0) > 0 && <Alert description="Xung đột với nhóm nằm viện khác" showIcon type="error" />}
      <PapayaIf condition={claimFragmentData.claim_case_payment?.initial_premium_debt_amount != null}>
        <Alert description={`Số tiền nợ phí ban đầu là ${utils.formatCurrency(claimFragmentData.claim_case_payment?.initial_premium_debt_amount)}`} showIcon type="warning" />
      </PapayaIf>
      <PapayaIf condition={claimFragmentData.claim_case_beneficiary?.payment_method === "DPCHD"}>
        <Alert description="Không áp dụng nợ phí cho phương thức thanh toán phí định kỳ." showIcon />
      </PapayaIf>
      {claimFragmentData.grace_period_start_date != null && claimFragmentData.grace_period_end_date != null && claimFragmentData.insured_certificate.status === "REVERSED" && (
        <Alert description="Claim này có rider đang trong trạng thái reverse" showIcon type="warning" />
      )}
      <PapayaIf condition={claimFragmentData.claim_case_beneficiary?.warning_code === "ACCOUNT_NAME_NOT_MATCH_PO_LA_NAME"}>
        <Alert
          description={
            claimFragmentData.insured_certificate.policy.types === "Individual"
              ? "Chủ Tài khoản ngân hàng không cùng tên với Chủ Hợp đồng"
              : "Chủ Tài khoản ngân hàng không cùng tên với Người được bảo hiểm"
          }
          showIcon
          type="warning"
        />
      </PapayaIf>
      <PapayaIf condition={claimFragmentData.claim_case_beneficiary?.warning_code === "ACCOUNT_NUMBER_INCORRECT"}>
        <Alert description="Số tài khoản ngân hàng không chính xác" showIcon type="warning" />
      </PapayaIf>
      <PapayaIf condition={medicalProviderHasMappingWithInsurer === false}>
        <Alert description="Cơ sở y tế chưa có mapping với CTBH" showIcon type="warning" />
      </PapayaIf>
      <PapayaIf condition={claimFragmentData.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.handed_over_to_insurer_at != null}>
        <Alert description="Đã bàn giao cho CTBH xử lý" showIcon type="info" />
      </PapayaIf>
      {contextHolder}
    </Flex>
  );
};

export default ClaimAlert;
