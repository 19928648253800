import type { FC, ReactNode } from "react";

import { GET_FAILED_CORRESPONDENCE_QUERY } from "contexts/graphql/queries";
import SkipToken from "contexts/SkipToken";
import usePollInterval from "hooks/usePollInterval";
import { createContext, useContext, useMemo } from "react";

import { useAuth } from "./AuthContext";
import usePQuery from "./usePQuery";

interface NotificationContextInterface {
  failedCorrespondenceHistoriesAggregate?: NonNullable<ResultOf<typeof GET_FAILED_CORRESPONDENCE_QUERY>>["notification_notifications_aggregate"];
  notifications: NonNullable<ResultOf<typeof GET_FAILED_CORRESPONDENCE_QUERY>>["notification_notifications"];
  refetchNotifications?: () => void;
  totalFailedCorrespondenceHistories: number;
}

type Props = { children: ReactNode } & Record<string, unknown>;

export const NotificationContext = createContext<NotificationContextInterface>({
  notifications: [],
  refetchNotifications: () => {},
  totalFailedCorrespondenceHistories: 0,
});

export const NotificationProvider: FC<Props> = ({ children }) => {
  const { selectedRole, userId } = useAuth();

  const pollInterval = usePollInterval(10_000);
  const { data: notificationData, refetch } = usePQuery(
    GET_FAILED_CORRESPONDENCE_QUERY,
    userId == null || selectedRole !== "TenantAdmin"
      ? SkipToken
      : {
          pollInterval,
          skipPollAttempt: () => document.hidden,
          variables: {
            limit: 100,
            offset: 0,
            userId,
          },
        },
  );

  const value: NotificationContextInterface = useMemo(
    () => ({
      failedCorrespondenceHistoriesAggregate: notificationData?.notification_notifications_aggregate,
      notifications: notificationData?.notification_notifications ?? [],
      refetchNotifications: refetch,
      totalFailedCorrespondenceHistories: notificationData?.notification_notifications_aggregate.aggregate?.count ?? 0,
    }),
    [notificationData?.notification_notifications, notificationData?.notification_notifications_aggregate, refetch],
  );

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => useContext(NotificationContext);
