import ErrorBoundary from "app/common/components/ErrorBoundary";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./app";

const router = createBrowserRouter([{ Component: Root, ErrorBoundary, path: "*" }]);

const App = () => <RouterProvider router={router} />;

const root = createRoot(document.querySelector("#root") as HTMLElement);
// root.render(<App />);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
