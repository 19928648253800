import type { InsuredBenefitTypesEnum } from "sdk/gql/graphql";

import PapayaIf from "app/common/components/PapayaIf";
import { FORMATTER } from "config/constants";
import { utils } from "libs/utils";

type TreatmentDaysProps = {
  admission_date?: null | string;
  benefit_type: InsuredBenefitTypesEnum;
  discharge_date?: null | string;
  event_date?: null | string;
  physical_examination_date?: null | string;
};

const treatmentDays = (value: TreatmentDaysProps) => {
  const { admission_date, benefit_type, discharge_date, event_date, physical_examination_date } = value;
  switch (benefit_type) {
    case "Maternity":
    case "InPatient": {
      return `${utils.formatDate(admission_date, FORMATTER.DATE_FORMAT)} - ${utils.formatDate(discharge_date, FORMATTER.DATE_FORMAT)}`;
    }
    case "OutPatient":
    case "Dental": {
      return utils.formatDate(physical_examination_date, FORMATTER.DATE_FORMAT);
    }
    case "Accident": {
      return (
        <PapayaIf condition={admission_date != null && discharge_date != null} else={utils.formatDate(physical_examination_date, FORMATTER.DATE_FORMAT)}>
          {utils.formatDate(admission_date, FORMATTER.DATE_FORMAT)} -{utils.formatDate(discharge_date, FORMATTER.DATE_FORMAT)}
        </PapayaIf>
      );
    }
    case "Life": {
      return utils.formatDate(event_date, FORMATTER.DATE_FORMAT);
    }
    default: {
      return utils.formatDate(event_date, FORMATTER.DATE_FORMAT);
    }
  }
};
export default treatmentDays;
