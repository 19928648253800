import type { DeepPartial } from "@apollo/client/utilities";
import type { MedicalProviderSelectOptionFragment } from "app/common/components/MedicalProviderSelectOption";
import type { ClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { Day } from "date-fns";
import type ExcelJS from "exceljs";
import type { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import type { FC } from "react";
import type { PreviewEmailAddress } from "sdk/gql/graphql";

import MedicalProviderSelectOption from "app/common/components/MedicalProviderSelectOption";
import { capitalCase } from "change-case";
import { HOLIDAYS_TO_EXCLUDE_FROM_TAT, SLACK } from "config";
import {
  addDays,
  format as dateFormat,
  differenceInBusinessDays,
  differenceInSeconds,
  getDay,
  getHours,
  isFuture,
  isSameDay,
  isSameWeek,
  isWeekend,
  nextDay,
  nextMonday,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfDay,
} from "date-fns";
import { fromZonedTime, toZonedTime } from "date-fns-tz";
import saveAs from "file-saver";
import { getNullableArrayLength } from "libs/hidash";
import { clone, compact, isEmpty, orderBy } from "lodash";
import normalizeText from "normalize-text";

import { FORMATTER, MIMES, RANDOM_WAITING_TEXT } from "../config/constants";

type Result<T> = {
  [Key in keyof T]: T[Key];
};

// type Head<T> = T extends [] ? never : T extends [infer Head] ? Head : T extends [infer Head, ...infer _] ? Head : never;

const vnLocale = "vi-VN";
const isObject = (obj) => obj === Object(obj) && !isArray(obj) && typeof obj !== "function";

const DAY_HOUR_MINUTE_TEXT = "day-hour-minute";

export const renderDayHourMinute = (tatInHour?: number) => {
  if (tatInHour == null) return "";
  const days = Math.floor(tatInHour / 24);
  const hours = Math.floor(tatInHour % 24);
  const minutes = Math.floor(((tatInHour % 24) - hours) * 60);
  if (days === 0 && hours === 0) return `${minutes}m`;
  if (days === 0) return `${hours}h ${minutes}m`;
  return `${days}d ${hours}h ${minutes}m`;
};

const toCamel = (s) => s.replaceAll(/([_-][a-z])/gi, ($1) => $1.toUpperCase().replace("-", "").replace("_", ""));
const isArray = (a) => Array.isArray(a);

const parseToStringLocalNaiveDateTimeWithDateFns = (date?: Date | null | string, format?: ValueOf<typeof FORMATTER>, tz: string = "UTC") => {
  if (date == null) return "";
  const utcDateWithTimezone = fromZonedTime(date, tz);
  return parseToStringLocalDateTimeWithDateFns(utcDateWithTimezone, format);
};

const parseToStringLocalDateTimeWithDateFns = (date?: Date | null | string, format: ({} & string) | ValueOf<typeof FORMATTER> = FORMATTER.DATE_TIME_FORMAT) => {
  if (date == null) return "";
  if (date instanceof Date) return dateFormat(date, format);
  if (typeof date === "number") return date;
  if (date.includes("&")) {
    const from = utils.parseLocalTime(date.split("&")[0]);
    const to = utils.parseLocalTime(date.split("&")[1]);
    return `${dateFormat(new Date(from), format)} - ${dateFormat(new Date(to), format)}`;
  }
  return dateFormat(new Date(date), format);
};

export const utils = {
  b64toBlob: (b64Data: string, contentType: string, sliceSize: number = 512) => {
    const newContentType = contentType;
    const byteCharacters = window.atob(b64Data);
    const byteArrays: Uint8Array[] = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = Array.from({ length: slice.length });
      for (let i = 0; i < slice.length; i += 1) {
        byteNumbers[i] = slice.codePointAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: newContentType });
  },
  base64ToFile: async (file: File, fileName?: string): Promise<FormData> => {
    const formFile = new FormData();
    const base64 = (await utils.toBase64(file)) as string;
    const block = base64.split(";");
    const contentType = block[0]?.split(":")[1]; // In this case "image/gif"
    const realData = block[1]?.split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
    if (realData == null || contentType == null) throw new Error("Invalid file");
    const blob = utils.b64toBlob(realData, contentType);
    formFile.append("file", blob, fileName ?? file.name);
    return formFile;
  },
  buildMpOption: ({
    directBillingOnly,
    med,
    policy_id,
  }: {
    directBillingOnly: boolean;
    med: DeepPartial<FragmentOf<typeof MedicalProviderSelectOptionFragment>>;
    policy_id?: string;
  }) => {
    const dbDisabled = (() => {
      if (directBillingOnly === true && med.is_direct_billing === false) return true;
      if (directBillingOnly === false) return false;
      return (
        med.is_direct_billing === true &&
        med.medical_provider_group_medical_providers?.some((i) =>
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          i?.medical_provider_group_history?.medical_provider_group_applications?.some(
            (j) =>
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              j?.medical_provider_group_version?.medical_provider_group?.medical_provider_group_type?.value === "DB_REJECTION" && j.policy?.policy_id === policy_id,
          ),
        ) === true
      );
    })();
    return {
      label: <MedicalProviderSelectOption directBillingOnly={directBillingOnly} key={med.id} mp={med} policy_id={policy_id} />,
      disabled: dbDisabled,
      selectedLabel: <MedicalProviderSelectOption directBillingOnly={directBillingOnly} displayAddress={false} key={med.id} mp={med} policy_id={policy_id} />,
      value: med.id,
    };
  },
  calculateClaimReceivedAt: ({
    claim,
  }: {
    claim: {
      action_logs: {
        created_at: string;
        new_value: HasuraTypes<"claim_case_statuses_enum"> | null | string;
        old_value: HasuraTypes<"claim_case_statuses_enum"> | null | string;
      }[];
      created_at: string;
      is_direct_billing: boolean;
    };
  }) => {
    if (claim.is_direct_billing) return claim.created_at;

    return utils
      .getNextWorkingHour(
        orderBy(claim.action_logs, ["created_at"], ["asc"]).find(
          (item) =>
            ["Initialize"].includes(item.old_value as HasuraTypes<"claim_case_statuses_enum">) &&
            ["InProgress"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">),
        )?.created_at ?? claim.created_at,
      )
      ?.toISOString();
  },
  calculateTat: ({
    claim,
    claimType,
    unit = "day",
    upToNow = false,
  }: {
    claim?: FragmentOf<typeof CLAIM_DETAIL_FOR_TAT_FRAGMENT> | null;
    claimType?: ClaimType;
    unit?: "day" | "day-hour-minute" | "hour";
    upToNow?: boolean;
  }) => {
    if (claim == null) return null;
    if (claim.tat_in_seconds != null) {
      const cachedTatInHour = claim.tat_in_seconds / 3600;
      if (unit === DAY_HOUR_MINUTE_TEXT) return renderDayHourMinute(cachedTatInHour);
      return unit === "day" ? (cachedTatInHour / 24).toPrecision(2) : cachedTatInHour.toFixed(2);
    }
    if (["Cancelled", "Pending"].includes(claim.status)) {
      return "0";
    }
    if (!["Approved", "ApprovedReceivedDoc", "ApprovedWaitingDoc", "Declined", "Paid"].includes(claim.status) && !upToNow) {
      return "0";
    }
    const lastDocumentAt = orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) =>
      ["Approved", "ApprovedWaitingDoc", "Declined"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">),
    )?.created_at;
    let tatEndDate = lastDocumentAt == null ? null : new Date(lastDocumentAt);

    if (tatEndDate == null && !upToNow) {
      return "0";
    }
    if (tatEndDate == null) {
      tatEndDate = new Date();
    }
    if (claim.is_direct_billing) {
      const tatInHour = utils.differenceInBusinessHours(tatEndDate, new Date(claim.created_at));
      if (unit === DAY_HOUR_MINUTE_TEXT) return renderDayHourMinute(tatInHour);
      return unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2);
    }

    const claimCreatedAt =
      orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) => item.old_value === "Initialize" && item.new_value === "InProgress")?.created_at ?? claim.created_at;

    // tat start date = last time document uploaded at or last time status changes to updated at or claim created at
    let tatStartDate: Date | undefined;
    if (claimType?.fwdMr === true) {
      const [d] = compact([
        orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) => ["Updated"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">))?.created_at,
        claimCreatedAt,
      ]).sort((time1, time2) => time2.localeCompare(time1));
      if (d != null) tatStartDate = new Date(d);
    } else {
      tatStartDate = utils.getNextWorkingHour(
        compact(
          [
            orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) => ["Updated"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">))?.created_at,
            claimCreatedAt,
          ].filter((t) => t != null && t <= tatEndDate.toISOString()),
        ).sort((time1, time2) => time2.localeCompare(time1))[0],
      );
    }

    if (tatStartDate == null) return "-1";
    // if created at is same day of decision at, skip working hour switches
    if (isSameDay(new Date(tatEndDate), new Date(tatStartDate))) {
      const tatInHour = utils.differenceInBusinessHours(new Date(tatEndDate), new Date(tatStartDate), false);
      if (unit === DAY_HOUR_MINUTE_TEXT) return renderDayHourMinute(tatInHour);
      return unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2);
    }
    let tatInHour = 0;

    tatInHour = utils.differenceInBusinessHours(new Date(tatEndDate), tatStartDate, false);

    if (tatInHour < 0) return unit === "day" ? (1 / 24).toPrecision(2) : "error";
    if (unit === DAY_HOUR_MINUTE_TEXT) return renderDayHourMinute(tatInHour);

    return unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2);
  },
  calculateTatAsync: async ({
    claim,
    claimType,
    forFirstPending = false,
    overwriteCache = false,
    unit = "day",
    upToNow = false,
  }: {
    claim?: null | ResultOf<typeof CLAIM_DETAIL_FOR_TAT_FRAGMENT>;
    claimType?: ClaimType;
    forFirstPending?: boolean;
    overwriteCache?: boolean;
    unit?: "day" | "day-hour-minute" | "hour";
    upToNow?: boolean;
  }) => {
    const res = //
      await utils.calculateTatAsyncV2({
        claim,
        claimType,
        forFirstPending,
        overwriteCache,
        unit,
        upToNow,
      });

    return res?.result ?? "-1";
  },
  calculateTatAsyncV2: async ({
    claim,
    claimType,
    forFirstPending = false,
    overwriteCache = false,
    unit = "day",
    upToNow = false,
  }: {
    claim?: null | ResultOf<typeof CLAIM_DETAIL_FOR_TAT_FRAGMENT>;
    claimType?: ClaimType;
    forFirstPending?: boolean;
    overwriteCache?: boolean;
    unit?: "day" | "day-hour-minute" | "hour";
    upToNow?: boolean;
  }): Promise<{
    end?: Date;
    result: string;
    start?: Date;
  } | null> => {
    if (claim == null) return { result: "-1" };
    if (new Set<HasuraTypes<"claim_case_statuses_enum">>(["AWAITING_CONTRACT_COMPLETION"]).has(claim.status)) {
      return { result: "0" };
    }
    if (overwriteCache === false && claim.tat_in_seconds != null) {
      const cachedTatInHour = claim.tat_in_seconds / 3600;
      if (unit === DAY_HOUR_MINUTE_TEXT) return { result: renderDayHourMinute(cachedTatInHour) };
      return { result: unit === "day" ? (cachedTatInHour / 24).toPrecision(2) : cachedTatInHour.toFixed(2) };
    }
    if (new Set<HasuraTypes<"claim_case_statuses_enum">>(["Cancelled", "Pending", "Suspension"]).has(claim.status) && !forFirstPending) {
      return { result: "0" };
    }
    if (
      new Set<HasuraTypes<"claim_case_statuses_enum">>(["Approved", "ApprovedReceivedDoc", "ApprovedWaitingDoc", "Declined", "Paid"]).has(claim.status) === false &&
      !upToNow &&
      !forFirstPending
    ) {
      return { result: "0" };
    }
    const approvedAt = orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) =>
      ["Approved", "ApprovedWaitingDoc", "Declined"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">),
    )?.created_at;
    let tatEndDate = approvedAt == null ? null : new Date(approvedAt);

    const firstPendingAt = (() => {
      const log = orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) => item.new_value === "Pending")?.created_at;
      if (log != null) return new Date(log);
      return null;
    })();

    if (firstPendingAt != null && forFirstPending) {
      tatEndDate = firstPendingAt;
    }
    if (tatEndDate == null && !upToNow) {
      return { result: "0" };
    }
    if (tatEndDate == null) {
      tatEndDate = new Date();
    }
    if (claim.is_direct_billing === true) {
      const tatInHour = await utils.differenceInBusinessHoursAsync(tatEndDate, new Date(claim.created_at));
      if (unit === DAY_HOUR_MINUTE_TEXT) return { result: renderDayHourMinute(tatInHour) };
      return { result: unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2) };
    }

    const claimCreatedAt = (() => {
      if (claimType?.slvHs === true) {
        return orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) => item.old_value === null && item.new_value === "Initialize")?.created_at ?? claim.created_at;
      }
      return orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) => item.old_value === "Initialize" && item.new_value === "InProgress")?.created_at ?? claim.created_at;
    })();
    let tatStartDate: Date | undefined;
    if (claimType?.fwdMr === true) {
      const [d] = compact([
        orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) =>
          ["AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING", "INVESTIGATING", "Updated"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">),
        )?.created_at,
        claimCreatedAt,
      ]).sort((time1, time2) => time2.localeCompare(time1));
      if (d != null) {
        tatStartDate = forFirstPending ? new Date(claimCreatedAt) : new Date(d);
      }
    } else {
      tatStartDate = forFirstPending
        ? new Date(claimCreatedAt)
        : utils.getNextWorkingHour(
            compact(
              [
                orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) =>
                  new Set<HasuraTypes<"claim_case_statuses_enum">>([
                    "AWAITING_CLAIM_ASSESSOR_REVIEW_PENDING",
                    "NEW_DOCUMENT_ON_SUSPENDED",
                    "RECEIVED_DOCS_ON_SUSPENDED",
                    "Updated",
                  ]).has(item.new_value as HasuraTypes<"claim_case_statuses_enum">),
                )?.created_at,
                claimCreatedAt,
              ].filter((t) => t != null && t <= tatEndDate.toISOString()),
            ).sort((time1, time2) => time2.localeCompare(time1))[0],
          );
    }

    if (tatStartDate == null) return { result: "error2" };
    // if created at is same day of decision at, skip working hour switches
    if (isSameDay(tatEndDate, tatStartDate)) {
      const tatInHour = await utils.differenceInBusinessHoursAsync(tatEndDate, tatStartDate, false);

      if (unit === DAY_HOUR_MINUTE_TEXT) return { result: renderDayHourMinute(tatInHour) };
      return { result: unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2) };
    }
    let tatInHour = 0;

    tatInHour = await utils.differenceInBusinessHoursAsync(tatEndDate, tatStartDate, false);

    if (tatInHour < 0) {
      return { end: tatEndDate, result: unit === "day" ? (1 / 24).toPrecision(2) : "error", start: tatStartDate };
    }
    if (unit === DAY_HOUR_MINUTE_TEXT) return { result: renderDayHourMinute(tatInHour) };

    return { result: unit === "day" ? (tatInHour / 24).toPrecision(2) : tatInHour.toFixed(2) };
  },
  calculateTimeBetweenPendingAndCreated: ({
    claim,
    claimType,
  }: {
    claim?: null | ResultOf<typeof CLAIM_DETAIL_FOR_TAT_FRAGMENT>;
    claimType?: ClaimType;
    unit?: "day" | "day-hour-minute" | "hour";
    upToNow?: boolean;
  }) => {
    if (claim == null) return null;
    const claimCreatedAt =
      orderBy(claim.action_logs, ["created_at"], ["asc"]).find((item) => item.old_value === "Initialize" && item.new_value === "InProgress")?.created_at ?? claim.created_at;
    const tatEndDate = (() => {
      const log = claim.action_logs.find((item) => item.new_value === "Pending")?.created_at;
      if (log != null) return new Date(log);
      return null;
    })();

    if (tatEndDate == null) {
      return null;
    }
    const tatStartDate = (() => {
      if (claimType?.fwdMr === true) {
        const [d] = compact([
          orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) => ["Updated"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">))?.created_at,
          claimCreatedAt,
        ]).sort((time1, time2) => time2.localeCompare(time1));
        if (d != null) return new Date(d);
      } else {
        return utils.getNextWorkingHour(
          compact(
            [
              orderBy(claim.action_logs, ["created_at"], ["desc"]).find((item) => ["Updated"].includes(item.new_value as HasuraTypes<"claim_case_statuses_enum">))?.created_at,
              claimCreatedAt,
            ].filter((t) => t != null && t <= tatEndDate.toISOString()),
          ).sort((time1, time2) => time2.localeCompare(time1))[0],
        );
      }
      return null;
    })();

    if (tatStartDate == null) return null;

    const tatInHour = utils.differenceInBusinessHours(tatEndDate, tatStartDate, false);
    return (tatInHour / 24).toPrecision(2);
  },
  calculateTimePassedTillNow: (date: Date): string => {
    const timeUnits = [
      { threshold: 365 * 24 * 60 * 60 * 1000, unit: "year" },
      { threshold: 30 * 24 * 60 * 60 * 1000, unit: "month" },
      { threshold: 7 * 24 * 60 * 60 * 1000, unit: "week" },
      { threshold: 24 * 60 * 60 * 1000, unit: "day" },
      { threshold: 60 * 60 * 1000, unit: "hour" },
      { threshold: 60 * 1000, unit: "minute" },
      { threshold: 1000, unit: "second" },
    ];

    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - date.getTime();

    const timeUnit = timeUnits.find(({ threshold }) => timeDifference >= threshold);

    if (timeUnit) {
      const value = Math.floor(timeDifference / timeUnit.threshold);
      return `${value} ${timeUnit.unit}${value > 1 ? "s" : ""} ago`;
    }

    return "Just now";
  },
  capitalCase: (text?: null | string) => (text == null ? text : capitalCase(text, { split: (str) => str.split(/[  ]/) })),
  checkFileType: (base64: string) => {
    const type = base64.match(/[^:]\w+\/[\w-+\d.]+(?=,)/);
    return !!(type && type[0] === "application/pdf");
  },
  differenceInBusinessHours: (dateLeft: Date, dateRight: Date, includeWeekends: boolean = false) => {
    if (isSameDay(dateLeft, dateRight)) return Math.abs(differenceInSeconds(dateLeft, dateRight) / 3600);
    function workingHoursBetweenDates(startDate: Date, endDate: Date) {
      // Store minutes worked
      let minutesWorked = 0;

      // Validate input
      if (endDate < startDate) {
        return 0;
      }

      // Loop from your Start to End dates (by hour)
      const current = clone(startDate);
      const jumpMin = 1;

      // Loop while currentDate is less than end Date (by minutes)
      while (current < endDate) {
        // console.log(current);
        // Is the current time within a work day (and if it
        // occurs on a weekend or not)
        if (includeWeekends ? true : current.getDay() !== 0 && current.getDay() !== 6) {
          minutesWorked += jumpMin;
        }

        // Increment current time
        current.setTime(current.getTime() + 1000 * 60 * jumpMin * 1);
      }

      // Return the number of hours
      return minutesWorked / 60 - differenceInSeconds(current, endDate) / 60 / 60;
    }
    return differenceInSeconds(dateLeft, dateRight) < 0 ? workingHoursBetweenDates(dateLeft, dateRight) : workingHoursBetweenDates(dateRight, dateLeft);
  },
  differenceInBusinessHoursAsync: async (startDate: Date, endDate: Date, includeWeekends: boolean = false) => {
    if (isSameDay(startDate, endDate)) {
      if (isFuture(endDate)) return 0;
      return Math.abs(differenceInSeconds(startDate, endDate) / 3600);
    }
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports, unicorn/relative-url-style
    const instance = new ComlinkWorker<typeof import("./workers/worker")>(new URL("./workers/worker", import.meta.url), {
      type: "module",
    });
    return differenceInSeconds(startDate, endDate) < 0
      ? instance.workingHoursBetweenDates({ endDate, startDate, includeWeekends })
      : instance.workingHoursBetweenDates({ endDate: startDate, startDate: endDate, includeWeekends });
  },
  differenceInHours: (dateLeft: Date, dateRight: Date, withWeekend = true) => {
    const NUMBER_OF_SECS_PER_HOUR = 3600;
    const isDateLeftGreaterThanDateRight = dateLeft > dateRight;
    const dateBefore = isDateLeftGreaterThanDateRight ? dateRight : dateLeft;
    const dateAfter = isDateLeftGreaterThanDateRight ? dateLeft : dateRight;
    const dateBeforeAfterRemovingCalculatedStartPart = addDays(startOfDay(dateBefore), 1);
    const dateAfterAfterRemovingCalculatedEndPart = startOfDay(dateAfter);
    if (isWeekend(dateBefore) && isWeekend(dateAfter) && isSameWeek(dateBefore, dateAfter, { weekStartsOn: 1 })) {
      return withWeekend ? differenceInSeconds(dateAfter, dateBefore) / NUMBER_OF_SECS_PER_HOUR : 0;
    }
    if (isSameDay(dateBefore, dateAfter)) return differenceInSeconds(dateAfter, dateBefore) / NUMBER_OF_SECS_PER_HOUR;
    const dateBeforeToEndOfWeek = isWeekend(dateBefore) ? 0 : differenceInSeconds(dateBeforeAfterRemovingCalculatedStartPart, dateBefore);
    const dateAfterToStartOfWeek = isWeekend(dateAfter) ? 0 : differenceInSeconds(dateAfter, startOfDay(dateAfter));
    const result =
      (dateBeforeToEndOfWeek + dateAfterToStartOfWeek) / NUMBER_OF_SECS_PER_HOUR +
      (withWeekend
        ? differenceInSeconds(dateAfterAfterRemovingCalculatedEndPart, dateBeforeAfterRemovingCalculatedStartPart) / NUMBER_OF_SECS_PER_HOUR
        : differenceInBusinessDays(dateAfterAfterRemovingCalculatedEndPart, dateBeforeAfterRemovingCalculatedStartPart) * 24);
    return isDateLeftGreaterThanDateRight ? result : -result;
  },
  download: (downloadableLink?: null | string, filename?: string) => {
    if (downloadableLink == null) return;
    fetch(downloadableLink)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = filename ?? new URL(downloadableLink).pathname.split("/").pop() ?? "file";
        document.body.append(link);
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch(() => {
        window.open(downloadableLink);
      });
  },
  downloadQR: (qrcodeElementId: string, medicalProviderName?: string) => {
    const canvas = document.querySelector(`#${qrcodeElementId}`) as HTMLCanvasElement;
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = medicalProviderName != null && medicalProviderName !== "" ? `${medicalProviderName}.png` : "qr-code.png";
    document.body.append(downloadLink);
    downloadLink.click();
    downloadLink.remove();
  },
  downloadWorkbook: async ({ fileName, workbook }: { fileName?: string; workbook: ExcelJS.Workbook }) => {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileExtension = ".xlsx";
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, `${fileName}${fileExtension}`);
  },
  escapeRegExp: (string) => string.replaceAll(/[$()*+.?[\\\]^{|}]/g, String.raw`\$&`), // $& means the whole matched string
  fileToBuffer: (file: File) => file.arrayBuffer(),
  formatCurrency: (value: null | number | string | undefined = 0, currency = "VND") =>
    Intl.NumberFormat(vnLocale, { currency, maximumFractionDigits: 2, style: "currency" }).format(Number(value ?? 0)),
  formatDate: (date?: Date | null | string, format?: ({} & string) | ValueOf<typeof FORMATTER>) => {
    if (date == null) return "";
    return parseToStringLocalDateTimeWithDateFns(date, format);
  },
  formatNaiveDate: (date?: Date | null | string, format?: ValueOf<typeof FORMATTER>, tz: string = "UTC") => parseToStringLocalNaiveDateTimeWithDateFns(date, format, tz),
  formatNumber: (value: null | number | string | undefined, showFractionDigits: false | number = 0) =>
    Intl.NumberFormat(vnLocale, {
      ...(showFractionDigits === false ? { maximumFractionDigits: 0 } : { maximumFractionDigits: showFractionDigits }),
    }).format(Number(value ?? 0)),
  generateClaimCode: ({ counter, directBilling }: { counter: number; directBilling: boolean }) =>
    `${directBilling === true ? "DB" : "RE"}-${String(new Date().getFullYear()).slice(2)}-${String(counter).padStart(6, "0")}`,
  getAge: (dob: Date | null | undefined) => {
    if (dob == null) return -1;
    const currentDate = new Date();
    let years = currentDate.getFullYear() - dob.getFullYear();
    if (dob.getMonth() > currentDate.getMonth() || (dob.getMonth() === currentDate.getMonth() && dob.getDate() > currentDate.getDate())) {
      years -= 1;
    }
    return years;
  },
  getFullEmailWithName: (email?: null | PreviewEmailAddress) => {
    if (email == null) return "";
    const haveName = !isEmpty(email.name);
    return `${email.name}${haveName ? "<" : ""}${email.email}${haveName ? ">" : ""}`;
  },
  getJsDateFromExcel: (excelDate: number) => new Date(Math.round((excelDate - (25_567 + 2)) * 86_400) * 1000),
  getNextWorkingHour: (date?: Date | string, startHour: number = 8, endHour: number = 18): Date | undefined => {
    if (date == null) {
      return date;
    }
    let dateObj = typeof date === "string" ? new Date(date) : date;
    const dayOfWeek = getDay(dateObj);
    if (HOLIDAYS_TO_EXCLUDE_FROM_TAT.some((d) => isSameDay(d, dateObj))) {
      return utils.getNextWorkingHour(setHours(setMinutes(setSeconds(setMilliseconds(nextDay(dateObj, (dayOfWeek + 1) as Day), 0), 0), 0), startHour));
    }
    const hour = getHours(dateObj);
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      dateObj = setHours(setMinutes(setSeconds(setMilliseconds(nextMonday(dateObj), 0), 0), 0), startHour);
    } else if (hour >= 0 && hour < startHour) {
      dateObj = setHours(setMinutes(setSeconds(setMilliseconds(dateObj, 0), 0), 0), startHour);
    } else if (hour >= endHour && hour <= 23) {
      dateObj = utils.getNextWorkingHour(setHours(setMinutes(setSeconds(setMilliseconds(nextDay(dateObj, (dayOfWeek + 1) as Day), 0), 0), 0), startHour)) ?? new Date();
    }
    if (isFuture(dateObj)) return new Date();

    return dateObj;
  },
  getNullableArrayLength,
  getPositiveNumber: (num?: null | number) => {
    if (num == null) {
      return null;
    }
    return num < 0 ? 0 : num;
  },
  getRandomText: () => {
    const { length } = Object.keys(RANDOM_WAITING_TEXT);
    return RANDOM_WAITING_TEXT[Math.floor(Math.random() * length)];
  },
  isHoliday: (date: Date) => HOLIDAYS_TO_EXCLUDE_FROM_TAT.some((holiday) => isSameDay(date, holiday)),
  keysToCamel: (o) => {
    if (isObject(o)) {
      const n = {};

      Object.keys(o).forEach((k) => {
        n[toCamel(k)] = utils.keysToCamel(o[k]);
      });

      return n;
    }
    if (isArray(o)) {
      return o.map((i) => utils.keysToCamel(i));
    }
    return o;
  },
  loadErrorHandler: (e): { default: FC<any> } => {
    console.error(e);
    if (import.meta.env.DEV === false) {
      window.location.reload();
    }
    return {
      default: () => <div />,
    };
  },
  normalizeText: (text?: null | string) => (text == null ? "" : normalizeText(text).toUpperCase().replaceAll("Đ", "D")),
  parseLocalTime: (date: Date | number | string) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return toZonedTime(new Date(date), timeZone).toISOString();
  },
  parseNumber: (param): 0 => {
    let val = param;
    try {
      // for when the input gets clears
      if (typeof val === "string" && val.length === 0) {
        val = "0.0";
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat(vnLocale).format(1111).replaceAll("1", "");
      const decimal = new Intl.NumberFormat(vnLocale).format(1.1).replaceAll("1", "");
      let reversedVal = val.replaceAll(new RegExp(`\\${group}`, "g"), "");
      reversedVal = reversedVal.replaceAll(new RegExp(`\\${decimal}`, "g"), ".");
      //  => 1232.21 €

      // removing everything except the digits and dot
      reversedVal = reversedVal.replaceAll(/[^\d.]/g, "");
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split(".")[1] ?? []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
    return 0;
  },
  parseToStringLocalDateTime: (date?: null | string, format?: ValueOf<typeof FORMATTER>) => parseToStringLocalDateTimeWithDateFns(date, format),
  parseToStringLocalDateTimeWithDateFns: (date?: null | string, format?: ValueOf<typeof FORMATTER>) => parseToStringLocalDateTimeWithDateFns(date, format),
  pascalToSentence: (text: string) =>
    text
      .replaceAll(/([a-z])([A-Z])/g, "$1 $2")
      .replaceAll(/([A-Z])([a-z])/g, " $1$2")
      .replace("_", "")
      .replaceAll(/ +/g, " ")
      .trim(),
  pick: <T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> => {
    const copy = {} as Pick<T, K>;
    keys.forEach((key) => {
      copy[key] = obj[key];
    });
    return copy;
  },
  readAsDataURL: (file: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        if (!reader.result) {
          return reject(new Error("Error while reading a file."));
        }

        resolve(reader.result as string);
      });

      reader.onerror = (event) => {
        if (!event.target) {
          return reject(new Error("Error while reading a file."));
        }

        const { error } = event.target;

        if (!error) {
          return reject(new Error("Error while reading a file."));
        }

        switch (error.code) {
          case error.NOT_FOUND_ERR: {
            return reject(new Error("Error while reading a file: File not found."));
          }
          case error.SECURITY_ERR: {
            return reject(new Error("Error while reading a file: Security error."));
          }
          case error.ABORT_ERR: {
            return reject(new Error("Error while reading a file: Aborted."));
          }
          default: {
            return reject(new Error("Error while reading a file."));
          }
        }
      };

      reader.readAsDataURL(file);
    }),
  removeNestedObjects: <T extends object>(obj: T): Result<Head<T>> => {
    const newObj = { ...obj };
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(newObj)) {
      if (typeof newObj[key] === "object") {
        delete newObj[key];
      }
    }
    delete newObj.__typename;
    return newObj as Result<Head<T>>;
  },
  removeUnicode: (text: string) =>
    text
      .normalize("NFD")
      .replaceAll(/[\u0300-\u036F]/g, "")
      .replaceAll("đ", "d")
      .replaceAll("Đ", "D"),
  saveToClipboard: (e?: React.MouseEvent<HTMLDivElement>, text?: null | string, id?: null | string) => {
    if (text == null || id == null) return;
    if (e?.metaKey === true) {
      if (e.shiftKey === true) {
        navigator.clipboard.write([
          new ClipboardItem({
            [MIMES.TEXT_PLAIN]: new Blob([`https://prod.cassava.care:${import.meta.env.VITE_PORT ?? 3000}${window.location.pathname}`], {
              type: MIMES.TEXT_PLAIN,
            }),
          }),
        ]);
      } else {
        navigator.clipboard.write([
          new ClipboardItem({
            [MIMES.TEXT_PLAIN]: new Blob([id], {
              type: MIMES.TEXT_PLAIN,
            }),
          }),
        ]);
      }
    } else {
      navigator.clipboard.write([
        new ClipboardItem({
          [MIMES.TEXT_PLAIN]: new Blob([text], {
            type: MIMES.TEXT_PLAIN,
          }),
          "text/html": new Blob([`<a href="${window.location.href}">${text}</a>`], {
            type: "text/html",
          }),
        }),
      ]);
    }
  },
  sendMessageToSlack: async ({ channel, message }) => {
    const { SlackAPIClient } = await import("slack-web-api-client");
    const slackClient = new SlackAPIClient();

    slackClient.chat.postMessage({
      channel,
      text: message,
      token: SLACK.TOKEN,
    });
  },
  sendSlackMessage: async ({ blocks, channel = SLACK.HC_BLVP_CHANNEL, message }: { blocks?: Record<any, any>; channel?: null | string; message?: string }) => {
    if (channel === "" || channel == null) {
      console.info("No channel endpoint provided, skipping sending slack message");
      return;
    }

    try {
      await fetch(channel, {
        body: JSON.stringify({
          blocks,
          text: message,
        }),
        method: "POST",
      });
    } catch (error) {
      console.error(error);
    }
  },
  setArraySearchParams: (key: string, params: URLSearchParams, values: any | Date[] | null | string[]) => {
    params.delete(key);
    if (values == null) return params;
    values.forEach((value) => {
      if (typeof value === "number") params.append(key, value.toString());

      if (typeof value === "string") params.append(key, value);

      if (value instanceof Date) params.append(key, value.toISOString());
    });

    return params;
  },
  sleep: (milliseconds) =>
    new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    }),
  // From https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem.
  stringToBase64: (texts) => {
    const byteArray = new TextEncoder().encode(texts);

    let binString = "";
    byteArray.forEach((byte) => {
      binString += String.fromCodePoint(byte);
    });

    return btoa(binString);
  },
  toBase64: (file: File): Promise<ArrayBuffer | null | string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => resolve(reader.result));
      // eslint-disable-next-line unicorn/prefer-add-event-listener
      reader.onerror = (error) => reject(error);
    }),
  trimValueForExcelCell: (value: any) => (typeof value === "string" && value.length > 32_767 ? value.slice(0, 32_767) : value),
};

export default utils;
