import type { TableColumnsType } from "antd";
import type { ITableListProps } from "app/common/components/TableListV2";
import type { useState } from "react";

import { Form, Input } from "antd";
import Content from "app/common/components/Content";
import DatePicker from "app/common/components/CustomAntdTimePickers/DatePicker";
import InsurerSelection from "app/common/components/InsurerSelect";
import TableList from "app/common/components/TableListV2";
import { FORMATTER } from "config/constants";
import usePQuery from "contexts/usePQuery";
import { endOfDay, isAfter } from "date-fns";
import utils from "libs/utils";
import { debounce, get, orderBy } from "lodash";
import { useSearchParams } from "react-router-dom";
import { graphql } from "sdk/v2/graphql";
import { writeFile, utils as xlsxUtils } from "xlsx";

const PendingDocument = graphql(`
  query Pending($where: claim_cases_bool_exp!) {
    claim_cases(where: $where) {
      id
      code
      created_at
      approved_at: action_logs(where: { new_value: { _in: ["Approved", "ApprovedWaitingDocs", "Declined"] } }, order_by: { created_at: asc }) {
        id
        created_at
      }
      claim_case_status {
        id: value
        comment
        value
      }
      insured_certificate {
        id
        policy {
          id
          policy_number
        }
      }
      pending_logs: action_logs(where: { new_value: { _eq: "Pending" } }, order_by: { created_at: asc }) {
        id
        created_at
      }
    }
  }
`);

const writeReportFile = async ({
  columnsToExport,
  listExportedData,
  schema,
  setSkipExport,
  title,
}: {
  columnsToExport: string[];
  listExportedData?: ResultOf<typeof PendingDocument>["claim_cases"];
  schema: ITableListProps<ResultOf<typeof PendingDocument>["claim_cases"][number]>["schema"];
  setSkipExport: ReturnTypeByParams<typeof useState<boolean>, [(() => boolean) | boolean]>[1];
  title: string;
}) => {
  if (listExportedData == null || schema == null) {
    return;
  }
  const handleMapDataToExcel = (dataItem: (typeof listExportedData)[number], dataItemIdx: number) =>
    schema
      .filter((column) => columnsToExport.includes(String(column.key)))
      .map((schemaItem) => {
        const dataIndex = "dataIndex" in schemaItem ? schemaItem.dataIndex : undefined;
        const { render } = schemaItem;
        const value = dataIndex == null ? null : get(dataItem, dataIndex);

        if (value != null && render != null) {
          return utils.trimValueForExcelCell(render(value, dataItem, dataItemIdx));
        }
        if (value != null) {
          return utils.trimValueForExcelCell(value);
        }
        if (render !== undefined) {
          return utils.trimValueForExcelCell(render(dataIndex === undefined ? dataItem : value, dataItem, dataItemIdx));
        }
        return "";
      })
      .map((item) => (typeof item === "string" ? item.trim() : item));

  const worksheet = xlsxUtils.json_to_sheet([{ name: "Report" }]);
  const workbook = xlsxUtils.book_new();

  worksheet["!merges"] = [
    {
      e: {
        c: 23,
        r: 0,
      },
      s: {
        c: 0,
        r: 0,
      },
    },
  ];

  xlsxUtils.sheet_add_aoa(
    worksheet,
    [
      [title],
      [`Số lượng: ${listExportedData.length}`],
      [],
      schema.filter((column) => columnsToExport.includes(String(column.key))).map((item) => item.title),
      ...listExportedData.map((i, idx) => handleMapDataToExcel(i, idx)),
    ],
    { origin: "A1" },
  );

  xlsxUtils.book_append_sheet(workbook, worksheet, "BCBT");

  writeFile(workbook, `${title}.xlsx`, {
    Props: {
      Title: title,
    },
  });
  setSkipExport(true);
};

const Pending = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const policyNumber = searchParams.get("policyNumber");
  const selectedInsurerIds = searchParams.getAll("insurer");
  const dateRange = searchParams.getAll("dateRange");

  const { data, loading } = usePQuery(
    PendingDocument,
    policyNumber == null && selectedInsurerIds.length === 0
      ? { skip: true }
      : {
          variables: {
            where: {
              action_logs_aggregate: {
                count: {
                  filter: {
                    new_value: { _eq: "Pending" },
                  },
                  predicate: {
                    _gte: 1,
                  },
                },
              },
              created_at: {
                _gte: dateRange.length === 2 ? dateRange[0] : undefined,
                _lte: dateRange.length === 2 ? dateRange[1] : undefined,
              },
              insured_certificate: {
                policy: {
                  _and: [
                    policyNumber == null
                      ? undefined
                      : {
                          policy_number: {
                            _ilike: policyNumber,
                          },
                        },
                    selectedInsurerIds.length === 0
                      ? undefined
                      : {
                          insurer_company: {
                            company_id: {
                              _in: selectedInsurerIds,
                            },
                          },
                        },
                  ].filter(Boolean),
                },
              },
              is_direct_billing: { _eq: false },
            },
          },
        },
  );
  const numOfPendingColumns = orderBy(data?.claim_cases, (c) => c.pending_logs.length, "desc")[0]?.pending_logs.length ?? 0;

  const columns: TableColumnsType<ResultOf<typeof PendingDocument>["claim_cases"][number]> = [
    {
      dataIndex: "code",
      key: "code",
      title: "Mã Claim",
    },
    {
      dataIndex: ["claim_case_status", "comment"],
      key: "status",
      title: "Trạng thái",
    },
    {
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => utils.formatNaiveDate(value, FORMATTER.DATE_TIME_FORMAT),
      title: "Ngày nộp",
    },
    {
      dataIndex: "approved_at",
      key: "approved_at",
      render: (value) => (value?.[0]?.created_at == null ? "" : utils.formatNaiveDate(value?.[0]?.created_at, FORMATTER.DATE_TIME_FORMAT)),
      title: "Ngày duyệt",
    },
    ...Array.from({ length: numOfPendingColumns }, (_, i) => ({
      dataIndex: ["pending_logs", i, "created_at"],
      key: `pending_${i}`,
      render: (value) => (value == null ? "" : utils.formatNaiveDate(value, FORMATTER.DATE_TIME_FORMAT)),
      title: `YCBS lần ${i + 1}`,
    })),
  ];

  return (
    <Form
      initialValues={{
        dateRange: dateRange.map((d) => new Date(d)),
        insurer: selectedInsurerIds,
        policyNumber,
      }}
    >
      <Content>
        <Form.Item label="Số hợp đồng" name="policyNumber">
          <Input
            allowClear
            onChange={debounce((value) => {
              setSearchParams((params) => {
                params.set("policyNumber", value.target.value);
                return params;
              });
            }, 1500)}
            placeholder="số hợp đồng"
          />
        </Form.Item>
        <InsurerSelection />
        <Form.Item label="Ngày tạo claim" name="dateRange">
          <DatePicker.RangePicker
            disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            format={FORMATTER.DATE_FORMAT}
            onChange={(values) => {
              if (values != null) {
                setSearchParams((params) => {
                  params.delete("dateRange");
                  values.forEach((v) => {
                    if (v != null) params.append("dateRange", v.toISOString());
                  });
                  return params;
                });
              }
            }}
          />
        </Form.Item>
        <TableList
          columns={columns}
          dataSource={data?.claim_cases}
          loading={loading}
          onExport={() =>
            writeReportFile({
              columnsToExport: ["code", "status", "created_at", "approved_at", ...Array.from({ length: numOfPendingColumns }, (_, i) => `pending_${i}`)],
              listExportedData: data?.claim_cases,
              schema: columns,
              setSkipExport: () => {},
              title: `Report Pending ${utils.formatDate(new Date())}`,
            })
          }
          title="Pending"
        />
      </Content>
    </Form>
  );
};

export default Pending;
