import { getOperationName } from "@apollo/client/utilities";
import { notification } from "antd";
import { UPDATE_CLAIM_MUTATION } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/mutations";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import { getClaimType } from "app/portal/screens/ClaimPortal/ClaimContext/utils";
import { SLACK } from "config";
import { CLAIM_DECISION_STATUSES } from "config/constants";
import usePLazyQuery from "contexts/usePLazyQuery";
import usePMutation from "contexts/usePMutation";
import { hoursToSeconds } from "date-fns";
import utils from "libs/utils";
import { CLAIM_DETAIL_FOR_TAT_FRAGMENT } from "libs/utilsQueries";
import { graphql } from "sdk/v2/graphql";

const ClaimForTatDocument = graphql(
  `
    query ClaimDetailForTat($id: uuid!) {
      claim_cases_by_pk(claim_case_id: $id) {
        id
        ...ClaimDetailForTat
        tatInHourUpToNow @client
      }
    }
  `,
  [CLAIM_DETAIL_FOR_TAT_FRAGMENT],
);

const useUpdateClaimTat = () => {
  const [updateClaimTatMutation] = usePMutation(UPDATE_CLAIM_MUTATION);
  const [claimForTatQuery] = usePLazyQuery(ClaimForTatDocument);

  return async ({
    claimId,
    debug = false,
    forFirstPending = false,
    overwriteCache = false,
    showNotification = false,
    willRefetch = false,
  }: {
    claimId?: null | string;
    debug?: boolean;
    forFirstPending?: boolean;
    overwriteCache?: boolean;
    showNotification?: boolean;
    willRefetch?: boolean;
  }) => {
    if (claimId == null) return;
    const { data: claimForTatData } = await claimForTatQuery({ variables: { id: claimId } });
    if (debug) {
      utils.sendMessageToSlack({
        channel: SLACK.CHANNEL_IDS.LOC,
        message: JSON.stringify(claimForTatData),
      });
    }

    if (forFirstPending && claimForTatData?.claim_cases_by_pk?.action_logs.some((log) => log.new_value === "Pending") === false) return;

    const tat = overwriteCache
      ? Number(
          await utils.calculateTatAsync({
            claim: claimForTatData?.claim_cases_by_pk,
            claimType: getClaimType(claimForTatData?.claim_cases_by_pk),
            forFirstPending,
            overwriteCache,
            unit: "hour",
            upToNow: true,
          }),
        )
      : claimForTatData?.claim_cases_by_pk?.tatInHourUpToNow;
    if (debug) {
      utils.sendMessageToSlack({
        channel: SLACK.CHANNEL_IDS.LOC,
        message: JSON.stringify({
          forFirstPending,
          overwriteCache,
          tat,
        }),
      });
    }
    if (
      (overwriteCache === true || claimForTatData?.claim_cases_by_pk?.tat_in_seconds === null) &&
      (CLAIM_DECISION_STATUSES.includes(claimForTatData?.claim_cases_by_pk?.status ?? "") || forFirstPending)
    ) {
      if (debug) {
        utils.sendMessageToSlack({
          channel: SLACK.CHANNEL_IDS.LOC,
          message: `Cập nhật TAT cho claim ${claimId}, ${JSON.stringify({
            refetchQueries: willRefetch ? [getOperationName(CLAIM_DETAIL_QUERY)].filter(Boolean) : [],
            variables: {
              claimId,
              input: {
                first_pending_tat_in_seconds: forFirstPending ? hoursToSeconds(Number(tat)) : undefined,
                tat_in_seconds: forFirstPending ? undefined : hoursToSeconds(Number(tat)),
              },
            },
          })}`,
        });
      }
      await updateClaimTatMutation({
        onCompleted: () => {
          if (showNotification) {
            notification.info({ message: "Cập nhật TAT thành công" });
          }
        },
        refetchQueries: willRefetch ? [getOperationName(CLAIM_DETAIL_QUERY)].filter(Boolean) : [],
        variables: {
          claimId,
          input: {
            first_pending_tat_in_seconds: forFirstPending ? hoursToSeconds(Number(tat)) : undefined,
            tat_in_seconds: forFirstPending ? undefined : hoursToSeconds(Number(tat)),
          },
        },
      });
    }
  };
};

export default useUpdateClaimTat;
