import { Button, Form, Input, notification, Space, Tooltip } from "antd";
import Content from "app/common/components/Content";
import PapayaIf from "app/common/components/PapayaIf";
import {
  INSERT_CLAIM_NOTE_MUTATION,
  UPDATE_SIGN_OFF_MUTATION,
} from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseInfoScreen/components/SignOff/graphql/mutations";
import { GET_SLV_SYNC_CLAIM_HISTORY_QUERY } from "app/portal/screens/ClaimPortal/ClaimCaseScreen/screens/ClaimCaseLogScreen/grapqhl/queries";
import { CLAIM_DETAIL_QUERY } from "app/portal/screens/ClaimPortal/ClaimContext/graphql/queries";
import useClaim from "app/portal/screens/ClaimPortal/ClaimContext/useClaim";
import usePMutation from "contexts/usePMutation";
import usePQuery from "contexts/usePQuery";
import { isAfter } from "date-fns";
import getRefetchOperationNames from "libs/getRefetchOperationNames";
import utils from "libs/utils";
import { sumBy } from "lodash";
import { Trash } from "lucide-react";
import { graphql as codegenThisGraphqlTag, readFragment } from "sdk/v2/graphql";

export const ClaimInsurerSignOffFragment = codegenThisGraphqlTag(/* GraphQL */ `
  fragment ClaimInsurerSignOff on claim_cases {
    id
    claim_case_same_event_groups_claim_case {
      id
      claim_case_same_event_group {
        id
        slv_claim_case_same_event_group {
          id
          last_synced_at
        }
      }
    }

    share_to_insurer_claim_notes: claim_notes(where: { share_to_insurer: { _eq: true }, type: { _eq: SignOff } }, order_by: { created_at: asc }) {
      id
      content
      share_to_insurer
      type
      created_at
      user {
        id
        name
        avatar_url
        roles {
          id
          role
        }
      }
    }
  }
`);

const SLV_SIGN_OFF_CHARACTER_LIMIT = 5000;

const InsurerSignOff = () => {
  const { claim, claimType } = useClaim();
  const claimFragmentData = (() => {
    if (claim == null) return null;
    return readFragment(ClaimInsurerSignOffFragment, claim);
  })();

  const { data: slvSyncHistory } = usePQuery(
    GET_SLV_SYNC_CLAIM_HISTORY_QUERY,
    claimType.slvHs === false ||
      claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.id == null ||
      claim.claim_case_same_event_groups_claim_case.claim_case_same_event_group.slv_claim_case_same_event_group.last_synced_at != null
      ? {
          skip: true,
        }
      : {
          variables: {
            groupId: claim.claim_case_same_event_groups_claim_case.claim_case_same_event_group.slv_claim_case_same_event_group.id,
          },
        },
  );

  const lastSyncedAt =
    claim?.claim_case_same_event_groups_claim_case?.claim_case_same_event_group.slv_claim_case_same_event_group?.last_synced_at ?? slvSyncHistory?.slv_sync_logs[0]?.created_at;
  const insurerSignOffs = claimFragmentData?.share_to_insurer_claim_notes ?? [];
  const editableInsurerSignOffs = insurerSignOffs.filter((note) => (lastSyncedAt == null ? false : isAfter(new Date(note.created_at), new Date(lastSyncedAt)) === true));
  const [form] = Form.useForm();
  const [insertSignOffMutation, { loading: insertSignOffMutationLoading }] = usePMutation(INSERT_CLAIM_NOTE_MUTATION, {
    awaitRefetchQueries: true,
    onCompleted: () => {
      notification.success({ message: "Gửi sign off thành công" });
      form.resetFields();
    },
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });
  const [deleteSignOffMutation] = usePMutation(UPDATE_SIGN_OFF_MUTATION, {
    refetchQueries: getRefetchOperationNames([CLAIM_DETAIL_QUERY]),
  });

  const onFinish = (values: { content?: string; id?: string }) => {
    insertSignOffMutation({
      variables: {
        input: {
          claim_case_id: claimFragmentData?.id,
          content: values.content,
          id: values.id,
          share_to_insurer: true,
          type: "SignOff",
        },
      },
    });
  };

  const remainingCharacterCount = claimType.slvHs === true ? SLV_SIGN_OFF_CHARACTER_LIMIT - sumBy(editableInsurerSignOffs, (note) => note.content.length) : false;

  if (claimFragmentData == null) {
    return null;
  }

  return (
    <Content title="Sign Off gửi cho công ty bảo hiểm">
      {insurerSignOffs.map((note) => (
        <Tooltip key={note.id} placement="topRight" title={`${note.user?.name} -  Tạo lúc ${utils.formatDate(note.created_at)}`}>
          <p className="white-space-pre-wrap relative" key={note.id}>
            {note.content}
            <PapayaIf condition={editableInsurerSignOffs.map((i) => i.id).includes(note.id) === true}>
              <Tooltip title="Sign off chưa được sync qua CTBH và có thể xoá.">
                <Button
                  icon={<Trash size={12} />}
                  onClick={() => {
                    deleteSignOffMutation({
                      onCompleted: () => {
                        notification.success({ message: "Xóa Sign Off message thành công" });
                      },
                      variables: {
                        id: note.id,
                        object: {
                          deleted_at: "now()",
                        },
                      },
                    });
                  }}
                  size="small"
                  style={{ bottom: 0, position: "absolute", right: 0 }}
                />
              </Tooltip>
            </PapayaIf>
          </p>
        </Tooltip>
      ))}
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          name="content"
          rules={[
            remainingCharacterCount === false
              ? null
              : {
                  max: remainingCharacterCount,
                  message: `Tổng ký tự sign off không được vượt quá ${SLV_SIGN_OFF_CHARACTER_LIMIT}.`,
                },
            {
              message: "Vui lòng nhập Sign off gửi cho công ty bảo hiểm",
              required: true,
            },
          ].filter(Boolean)}
        >
          <Input.TextArea
            autoSize={{
              minRows: 5,
            }}
            count={
              remainingCharacterCount === false
                ? undefined
                : {
                    max: remainingCharacterCount,
                    show: true,
                  }
            }
            placeholder="Nhập Sign off gửi cho công ty bảo hiểm"
          />
        </Form.Item>
        <Space align="end" direction="vertical">
          <Form.Item>
            <Button htmlType="submit" loading={insertSignOffMutationLoading} size="large" type="primary">
              Tạo Sign Off
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Content>
  );
};
export default InsurerSignOff;
