import type { CSSProperties } from "react";

import { Badge, Button, Divider, Empty, Flex, Space, Typography } from "antd";
import { CLAIM_CASE_PATH, PORTAL_PATH } from "app/portal/config/paths";
import { useNotificationContext } from "contexts/NotificationContext";
import { UPDATE_NOTIFICATION_AS_SEEN_MUTATION } from "contexts/graphql/queries";
import usePMutation from "contexts/usePMutation";
import utils from "libs/utils";
import { isEmpty } from "lodash";
import { Link, generatePath } from "react-router-dom";
import { NotificationReasonsEnum } from "sdk/gql/types";

import PapayaIf from "../PapayaIf";

const { Text } = Typography;

const failedHistoryItemStyles: CSSProperties = {
  borderRadius: 4,
  padding: 8,
};

const REASON_TO_PATH_MAPPING = {
  [NotificationReasonsEnum.ClaimAssigned]: PORTAL_PATH.CLAIM_CASE,
  [NotificationReasonsEnum.InsurerAssessed]: `${PORTAL_PATH.CLAIM_CASE}/${CLAIM_CASE_PATH.CHECK_CASE}`,
  null: PORTAL_PATH.CLAIM_CASE,
} as const;

const All = () => {
  const { notifications, refetchNotifications } = useNotificationContext();
  const [updateNotification] = usePMutation(UPDATE_NOTIFICATION_AS_SEEN_MUTATION);

  return (
    <PapayaIf condition={isEmpty(notifications) === false} else={<Empty />}>
      <Space direction="vertical" split={<Divider className="m-0" />}>
        {notifications
          .filter((n) => n.reason !== null)
          .map(
            ({ created_at, id, is_read, origin_claim_case: claimCase, reason, reason_type }) =>
              claimCase != null && (
                <Flex align="center" justify="space-between" key={id} style={failedHistoryItemStyles}>
                  <Link
                    onClick={() => {
                      updateNotification({
                        onCompleted: refetchNotifications,
                        variables: {
                          notificationId: id,
                        },
                      });
                    }}
                    to={`${generatePath(REASON_TO_PATH_MAPPING[String(reason)], { claimCaseId: claimCase.claim_case_id })}`}
                  >
                    <Space direction="vertical" size={4}>
                      <Space>
                        <PapayaIf condition={is_read === false}>
                          <Badge status="error" />
                        </PapayaIf>
                        <Text type={is_read === true ? "secondary" : undefined}>{reason_type?.comment}: </Text>
                      </Space>
                      <Space align="start">
                        <Text style={{ color: is_read === true ? "#1677ff" : "#1890ff" }}>{claimCase.code}</Text>
                      </Space>
                      <Text type="secondary">{utils.parseToStringLocalDateTimeWithDateFns(created_at)}</Text>
                    </Space>
                  </Link>
                  <PapayaIf condition={is_read === false}>
                    <Button
                      danger
                      onClick={() => {
                        updateNotification({
                          onCompleted: refetchNotifications,
                          variables: {
                            notificationId: id,
                          },
                        });
                      }}
                      size="small"
                      type="text"
                    >
                      Bỏ qua
                    </Button>
                  </PapayaIf>
                </Flex>
              ),
          )}
      </Space>
    </PapayaIf>
  );
};

export default All;
