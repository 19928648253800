import type { ClaimContextType } from "app/portal/screens/ClaimPortal/ClaimContext/types";
import type { ReactNode } from "react";

import ExternalLink from "app/common/components/ExternalLink";
import { getClaimDetailPathByClaimCode } from "app/portal/config/paths";
import usePQuery from "contexts/usePQuery";
import findClaimCodesInText from "libs/findClaimCodeInText";
import { uniq } from "lodash";
import { generatePath, Link } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import { graphql } from "sdk/v2/graphql";

const ClaimObjectsFromClaimCodesDocument = graphql(`
  query ClaimObjectsFromClaimCodes($where: claim_cases_bool_exp!) {
    claim_cases(where: $where) {
      id
      code
    }
  }
`);

const useBuildClaimLinksFromText = ({
  claimCaseRoutePath,
  newTabLink = true,
  texts,
}: {
  claimCaseRoutePath?: ClaimContextType["claimCaseRoutePath"];
  newTabLink?: boolean;
  texts?: (null | string | undefined)[];
}) => {
  const claimCodes = uniq(texts?.filter(Boolean).flatMap((text) => findClaimCodesInText(text)));
  const { data: claimObjectsFromCodesData } = usePQuery(ClaimObjectsFromClaimCodesDocument, {
    variables: {
      where: {
        code: { _in: claimCodes },
      },
    },
  });

  return (text?: null | ReactNode[] | string) => {
    if (text == null) return "";
    const path = (() => {
      if (claimCaseRoutePath != null) return claimCaseRoutePath;
      const oneCode = claimCodes[0];
      return getClaimDetailPathByClaimCode(oneCode);
    })();

    let cases = reactStringReplace(text, undefined);

    claimObjectsFromCodesData?.claim_cases.forEach((claimCase) => {
      cases = reactStringReplace(cases, claimCase.code, (_match, i, offset) =>
        newTabLink ? (
          <ExternalLink key={_match + i + offset} to={generatePath(path, { claimCaseId: claimCase.id })}>
            {claimCase.code}
          </ExternalLink>
        ) : (
          <Link key={_match + i + offset} to={generatePath(path, { claimCaseId: claimCase.id })}>
            {claimCase.code}
          </Link>
        ),
      );
    });

    return cases;
  };
};

export default useBuildClaimLinksFromText;
