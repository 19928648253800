import { graphql as codegenThisGraphqlTag } from "sdk/v2/graphql";

export default codegenThisGraphqlTag(/* GraphQL */ `
  fragment Policy on insured_certificates {
    policy {
      id
      due_date
      dued_at
      effective_date
      email
      expiry_date
      issued_at
      kind
      lapsed_date
      phone
      policy_id
      policy_number
      reinstated_date
      remark_copay
      status
      sum_assured
      surrender_date
      tpa_id
      types
      corporate_company {
        id
        name
        company_id
        email
        phone
        tax_code
      }
      insured_person {
        id
        name
        died_at
        dob
        email
        gender
        insured_person_id
        marital_status
        paper_id
        phone
        mbal_insured_person {
          id
          address
          job
          occupation_class
        }
      }
      insurer {
        id
        name
        company_id
      }
      insurer_company {
        id
        name
        company_id
        company_setting {
          id
          bank_accounts
          correspondence_api_type
          correspondence_from_email
          use_claim_benefit_v2
        }
        parent_company {
          id
        }
      }
      policy_documents {
        id
        type
        file {
          id
          name
          mime_type
          url
          alternative_format_files {
            id
            mime_type
            url
          }
        }
        policy_document_type {
          id: value
          comment
        }
      }
      policy_kind {
        id: value
        comment
      }
      policy_setting {
        id
        apply_static_claim_case_payment
        assessment_remarks
        blacklist_drug_store_tax_codes
        claim_form_type
        co_payment_type
        contract_phrase_reference_additional_clauses
        contract_phrase_reference_benefits
        contract_phrase_reference_copay
        contract_phrase_reference_exclusion_clauses
        contract_phrase_reference_extended_clauses
        contract_phrase_reference_special_agreement
        contract_phrase_reference_waiting_period
        correspondence_from_email
        original_docs_provision_max_tat
        tat_max
        turn_around_time_commitment
        use_sms
        bcc_emails(where: { object_key: { _is_null: true } }) {
          id
          string_value
          meta_reference {
            id
            string_value
          }
        }
        blacklist_medial_provider_group_histories {
          id
          medical_provider_group {
            id
            type
          }
          medical_provider_group_medical_providers(where: {}) {
            id
            medical_provider {
              id
            }
          }
        }
        broker_emails(where: { object_key: { _is_null: true } }) {
          id
          string_value
          meta_reference {
            id
            string_value
          }
        }
        hr_emails(where: { object_key: { _is_null: true } }) {
          id
          string_value
          meta_reference {
            id
            string_value
          }
        }
        insurer_emails(where: { object_key: { _is_null: true } }) {
          id
          string_value
          meta_reference {
            id
            string_value
          }
        }
        medical_provider_group_applications {
          id
          medical_provider_group_version {
            id
            medical_provider_group {
              id
              type
              medical_provider_group_type {
                id: value
                comment
              }
            }
            medical_provider_group_medical_providers {
              id
              medical_provider {
                id
                name
              }
            }
          }
        }
        policy_co_payment_type {
          id: value
          comment
        }
        present_case_emails(where: { object_key: { _is_null: true } }) {
          id
          string_value
          meta_reference {
            id
            string_value
          }
        }
      }
      policy_terms_aggregate {
        aggregate {
          count
        }
      }
      policy_waiting_times {
        id
        description
        insured_benefit_types
        value
      }
      watch_by {
        id
        insurer_id
      }
    }
  }
`);
